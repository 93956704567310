/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { AmplitudeML } from './amplitude-ml';
// May contain unused imports in some cases
// @ts-ignore
import { BelindaMetadata } from './belinda-metadata';
// May contain unused imports in some cases
// @ts-ignore
import { Binary } from './binary';
// May contain unused imports in some cases
// @ts-ignore
import { ClassificationML } from './classification-ml';
// May contain unused imports in some cases
// @ts-ignore
import { Coordinates } from './coordinates';
// May contain unused imports in some cases
// @ts-ignore
import { MetadataML } from './metadata-ml';
// May contain unused imports in some cases
// @ts-ignore
import { Recordist } from './recordist';

/**
 *
 * @export
 * @interface Recording
 */
export interface Recording {
    /**
     *
     * @type {number}
     * @memberof Recording
     */
    version?: number;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    createdAt?: string;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    modifiedAt?: string;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    publishedAt?: string;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    detectionAt?: string;
    /**
     *
     * @type {Recordist}
     * @memberof Recording
     */
    recordist?: Recordist;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    catalogueNumber?: string;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    scientificName?: string;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    commonName?: string;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    order?: string;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    family?: string;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    genus?: string;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    species?: string;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    country?: string;
    /**
     *
     * @type {Coordinates}
     * @memberof Recording
     */
    location?: Coordinates;
    /**
     *
     * @type {boolean}
     * @memberof Recording
     */
    confidential?: boolean;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    status?: RecordingStatusEnum;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    recordingDevice?: string;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    microphone?: string;
    /**
     *
     * @type {MetadataML}
     * @memberof Recording
     */
    metadataML?: MetadataML;
    /**
     *
     * @type {AmplitudeML}
     * @memberof Recording
     */
    amplitudeML?: AmplitudeML;
    /**
     *
     * @type {Array<ClassificationML>}
     * @memberof Recording
     */
    classificationML?: Array<ClassificationML>;
    /**
     *
     * @type {Binary}
     * @memberof Recording
     */
    fttJpeg?: Binary;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    fttJwtUrl?: string;
    /**
     *
     * @type {Binary}
     * @memberof Recording
     */
    melspectrogramJpeg?: Binary;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    melspectrogramJwtUrl?: string;
    /**
     *
     * @type {Binary}
     * @memberof Recording
     */
    mfccJpeg?: Binary;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    mfccJwtUrl?: string;
    /**
     *
     * @type {Binary}
     * @memberof Recording
     */
    spectrogramJpeg?: Binary;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    spectrogramJwtUrl?: string;
    /**
     *
     * @type {Binary}
     * @memberof Recording
     */
    waveformJpeg?: Binary;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    waveformJwtUrl?: string;
    /**
     *
     * @type {BelindaMetadata}
     * @memberof Recording
     */
    belindaData?: BelindaMetadata;
    /**
     *
     * @type {string}
     * @memberof Recording
     */
    track?: string;
    /**
     *
     * @type {boolean}
     * @memberof Recording
     */
    training?: boolean;
    /**
     *
     * @type {number}
     * @memberof Recording
     */
    wizardStep?: number;
    /**
     *
     * @type {Array<Binary>}
     * @memberof Recording
     */
    images?: Array<Binary>;
    /**
     *
     * @type {Binary}
     * @memberof Recording
     */
    landscape?: Binary;
}

export const RecordingStatusEnum = {
    SEED: 'SEED',
    UPLOADED: 'UPLOADED',
    ANALYZING: 'ANALYZING',
    ANALYZED: 'ANALYZED',
    CLASSIFYING: 'CLASSIFYING',
    CLASSIFIED: 'CLASSIFIED',
    UNCLASSIFIED: 'UNCLASSIFIED',
    RECOGNIZED: 'RECOGNIZED',
    METADATATION_COMPLETED: 'METADATATION_COMPLETED',
    COMPLETED: 'COMPLETED',
    CLASSIFIER: 'CLASSIFIER',
} as const;

export type RecordingStatusEnum = (typeof RecordingStatusEnum)[keyof typeof RecordingStatusEnum];
