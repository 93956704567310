import '../styles/userWidget.style.scss';
import LogoutButton from './LogoutButton.component';

const UserWidget: React.FC = () => {
    return (
        <>
            <div className='it-header-slim-right-zone'>
                <div className='nav-item dropdown'>
                    <a
                        className='nav-link dropdown-toggle d-flex align-items-center
    '
                        href='#'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'>
                        <div className='user-toggle'>
                            <p>
                                {/*TODO: Use keycloakUtil to get the user info */}
                                {/* {user?.firstName} {user?.lastName} */}
                            </p>
                            <p>
                                {/*TODO: Use keycloakUtil to get the user info */}
                                {/* {user?.role} */}
                            </p>
                        </div>
                        {/* <svg className='icon d-none d-lg-block'>
                            <use href='/bootstrap-italia/dist/svg/sprites.svg#it-expand'></use>
                        </svg> */}
                    </a>
                    <div className='dropdown-menu dropdown-menu-container' role='menu'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='link-list-wrapper'>
                                    <ul className='link-list'>
                                        <li>
                                            <LogoutButton />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserWidget;
