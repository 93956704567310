import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { LiteRecording, ResponseMetricsGet } from 'src/api';

export const homePersistedConfig = {
    key: 'home',
    storage,
    //blacklist: ['loading', 'popup'],
};

export interface HomeState {
    metrics: ResponseMetricsGet;
    latestRecords: Array<LiteRecording>;
}

export const initialHomeState: HomeState = {
    //TODO: update with actual values
    metrics: {
        published_records: 0,
        records: 0,
        species_tracked: 0,
        recordists: 0,
        users: 0,
        genus_tracked: 0,
    },
    latestRecords: [],
};

// export interface IMetrics {
//     records: number;
//     publishedRecords: number;
//     speciesTracked: number;
//     users: number;
//     recorders: number;
//     subSpecies: number;
// }

export const homeSlice: Slice = createSlice({
    name: 'home',
    initialState: initialHomeState,
    reducers: {
        setRecordings(state, action: PayloadAction<Array<LiteRecording>>) {
            state.latestRecords = action.payload;
        },
        setMetrics(state, action: PayloadAction<ResponseMetricsGet>) {
            state.metrics = action.payload;
        },
    },
});

export const { setRecordings, setMetrics } = homeSlice.actions;
export default homeSlice.reducer;
