import { Recording, RequestRecordingCreate, RequestRecordingUpdate, ResponseRecordingCreate } from '@api/entities';
import { resetCreateUpdate, setAnalytics, setSelectedRecord } from '@features/catalogue/state/catalogueSlice';
import { setSelectedItem } from '@features/wizard/state/wizard.slice';
import { store } from '@store/configure';
import KeycloakUtil from '@utils/keycloak.util';
import { AxiosPromise } from 'axios';
import { CatalogueApiService, ICatalogueApiService } from '../services/catalogue/catalogue.api.service';
import { IWizardApiService, WizardApiService } from '../services/wizard/wizard.api.service';

export interface IWizardManagerProps {
    createRecord(requestRecordingCreate: RequestRecordingCreate): Promise<ResponseRecordingCreate>;
    updateRecord(idrecord: string, requestRecordingUpdate: RequestRecordingUpdate): Promise<Recording>;
    getAudioData(idRecording: string): Promise<void>;
    nextStep(idRecording: string, numberStep: number): Promise<void>;
    uploadImage(idRecording: string, file: File): Promise<string>;
    uploadLandscape(idRecording: string, file: File): Promise<string>;
    classifyRecording(recordId: string, classifierId: string): Promise<void>;
}

export class WizardManager implements IWizardManagerProps {
    private static instance: WizardManager;
    private wizardService: IWizardApiService;
    private catalogueService: ICatalogueApiService;

    private constructor() {
        this.wizardService = WizardApiService.getInstance(process.env.REACT_APP_MICROSERVICE_API_BASEPATH!);
        this.catalogueService = CatalogueApiService.getInstance(process.env.REACT_APP_MICROSERVICE_API_BASEPATH!);
    }

    static getInstance() {
        if (WizardManager.instance == null) {
            WizardManager.instance = new WizardManager();
        }
        return WizardManager.instance;
    }

    async updateRecord(idrecord: string, requestRecordingUpdate: RequestRecordingUpdate): Promise<Recording> {
        const response = await this.wizardService.updateRecord(idrecord, requestRecordingUpdate);
        store.dispatch(setSelectedRecord(response));
        store.dispatch(resetCreateUpdate({}));
        return response;
    }

    async createRecord(requestRecordingCreate: RequestRecordingCreate): Promise<ResponseRecordingCreate> {
        const response = await this.wizardService.createRecord({
            ...requestRecordingCreate,
            recordist: {
                name: KeycloakUtil.getFirstName(),
                surname: KeycloakUtil.getLastName(),
                email: KeycloakUtil.getEmail(),
            },
            wizardStep: 1,
        });
        store.dispatch(setSelectedRecord(response));
        return response;
    }

    async getDataFromJwtML(
        analytic: 'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM',
        format: 'JPEG' | 'CSV',
        jwtPayload: string,
        jwtSignature: string,
        jwtHeader: string,
    ): Promise<AxiosPromise<File>> {
        return await this.wizardService.getDataFromJwtML(analytic, format, jwtPayload, jwtSignature, jwtHeader);
    }

    async getAudioData(idRecording: string): Promise<void> {
        const response = await this.wizardService.getAudioData(idRecording);
        store.dispatch(setAnalytics(response));
    }

    async nextStep(idRecording: string, numberStep: number): Promise<void> {
        const response = await this.wizardService.nextStep(idRecording, numberStep);
        store.dispatch(setSelectedRecord(response));
    }

    async deleteRecording(idRecording: string): Promise<string> {
        return await this.wizardService.deleteRecording(idRecording);
    }
    async getMyRecording(idRecording: string): Promise<void> {
        const response = await this.catalogueService.getRecording(idRecording);
        store.dispatch(setSelectedItem(response));
    }

    async uploadImage(idRecording: string, file: File): Promise<string> {
        return await this.wizardService.uploadImage(idRecording, file);
    }

    async uploadLandscape(idRecording: string, file: File): Promise<string> {
        return await this.wizardService.uploadLandscape(idRecording, file);
    }

    async classifyRecording(recordId: string, classifierId: string): Promise<void> {
        const response = await this.wizardService.classifyRecording(recordId, classifierId);
        store.dispatch(setSelectedRecord(response));
    }
}
