import { Recording, RecordingStatusEnum } from '@api/entities';
import { Accordion } from '@features/_shared/components/Accordion/accordion.component';
import { MapComponent } from '@features/_shared/components/Map/map.component';
import { Table } from '@features/_shared/components/Table';
import TextField from '@features/_shared/components/TextField/textfield.component';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormManager } from '@managers/form.manager';
import { FormHelperUtil } from '@utils/form.helper.util';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { IntlContext } from 'react-intl';
import { amplitudeDataTableRowBuilder, audioDataTableRowBuilder, soundDataTableRowBuilder } from '../constats/constats';
import { DisplayModeEnum, saveCoordinates, saveValue } from '../state/catalogueSlice';
import { AudioAnalyticsComponent } from './audio.analytics.component';
import './styles/data.section.component.style.scss';
import { Controller } from 'react-hook-form';
import { Calendar } from '@features/_shared/components/DatePicker/datepicker.component';
import { RecordDetailData } from '../beans/recordDetailData';
import { store } from '@store/configure';
import { WizardManager } from '@managers/wizard.manager';
import { useStoreSelector } from '@store/hook';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { LatLng } from 'leaflet';
import KeycloakUtil from '@utils/keycloak.util';

export interface IDataSectionComponentProps {
    record: Recording;
    displayMode?: DisplayModeEnum;
    isAnimalDataEditable?: boolean;
    isEditEnabled?: boolean;
    onNextClick?: () => boolean;
    onFormValid?: (errors: { [x: string]: any }) => void;
}

export const DataSectionComponent: React.FC<IDataSectionComponentProps> = ({ onFormValid, record, displayMode, isAnimalDataEditable, isEditEnabled = false }) => {
    const translator = TranslatorManager.getInstance();
    const wizardManager = WizardManager.getInstance();
    const queueManager = QueueManager.getInstance();
    const [radius, setRadius] = useState<number | undefined>(undefined);
    const updatedRecord: Recording = useStoreSelector(state => state.reducer.catalogue.createUpdate);

    const onSubmit = async (section: string) => {
        const isFormValid =
            section === 'generalData'
                ? await trigger(['country', 'latitude', 'longitude', 'detectionAt', 'recordingDevice', 'microphone'])
                : await trigger(['genus', 'species', 'scientificName', 'commonName', 'order', 'family']);
        if (isFormValid) {
            record?.id && (await queueManager.queue(QueueKeyType.DEFAULT, wizardManager.updateRecord(record?.id, updatedRecord)));
            clearErrors();
        } else {
            return false;
        }
    };
    const { errors, register, trigger, control, getValues, clearErrors, setValue } = FormManager.getInstance().buildFormProxy<RecordDetailData>(
        RecordDetailData,
        onSubmit,
        {
            country: record?.country,
            latitude: record?.location?.latitude ? record?.location?.latitude.toString() : '',
            longitude: record?.location?.longitude ? record?.location?.longitude.toString() : '',
            radius: record?.location?.radius ? record?.location?.radius : 0,
            detectionAt: new Date(record?.detectionAt as string) ?? new Date(),
            microphone: record?.microphone,
            recordingDevice: record?.recordingDevice,

            order: record?.order,
            family: record?.family,
            genus: record?.genus,
            species: record?.species,
            scientificName: record?.scientificName,
            commonName: record?.commonName,
        },
        'onChange',
    );

    const intl = useContext(IntlContext);
    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator('wizard.general.data', intl as any);

    const soundDataTableRows = soundDataTableRowBuilder(record);
    const audioDataTableRows = audioDataTableRowBuilder(record?.metadataML as Partial<Recording>);
    const amplitudeDataTableRows = amplitudeDataTableRowBuilder(record?.amplitudeML as Partial<Recording>);
    const catalogueDetailTableColumns = [translator.get('catalogue.detail.table.header.title.text'), translator.get('catalogue.detail.table.header.value.text')];

    const [isGeneralDataEditMode, setIsGeneralDataEditMode] = useState(false);
    const [isAnimalDataEditMode, setIsSoundDataEditMode] = useState(isEditEnabled);

    const saveEditMode = async (section: string) => {
        if (section === 'generalData') {
            if (isGeneralDataEditMode) {
                const response = await onSubmit(section);
                response !== false && setIsGeneralDataEditMode(type => !type);
            } else {
                setIsGeneralDataEditMode(type => !type);
            }
        } else if (section === 'soundData') {
            if (isAnimalDataEditMode) {
                const response = await onSubmit(section);
                response !== false && setIsSoundDataEditMode(type => !type);
            } else {
                setIsSoundDataEditMode(type => !type);
            }
        }
    };

    const handleOnBlur = (key: string, value: string | number) => {
        if (key === 'latitude' || key === 'longitude' || key === 'radius') {
            !errors[key] && store.dispatch(saveCoordinates({ key, value }));
            onFormValid?.(errors);
        } else {
            store.dispatch(saveValue({ key, value }));
            onFormValid?.(errors);
        }
    };

    const editableGeneralDataTableRow = (record: Recording) => {
        const translator = TranslatorManager.getInstance();
        const mergedRecord = { ...record, ...record?.location };
        const filteredItem: Partial<typeof record> = Object.entries(mergedRecord)
            .filter(([key]) => ['country', 'latitude', 'longitude', 'radius', 'detectionAt', 'recordingDevice', 'microphone'].includes(key))
            .reduce((obj: any, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});

        const orderedKeys = ['country', 'latitude', 'longitude', 'radius', 'detectionAt', 'recordingDevice', 'microphone'];

        const rows = orderedKeys.map(key => {
            const value = filteredItem[key as keyof Recording];

            return {
                id: record?.id,
                values: {
                    title: (
                        <div>
                            <span style={{ fontWeight: 'normal' }}>{translator.get(`wizard.general.data.${key}.field.placeholder`)}</span>
                            <span className='requiredSign'>*</span>
                        </div>
                    ),
                    value: isGeneralDataEditMode ? (
                        <span>
                            {key === 'detectionAt' ? (
                                <Controller
                                    name='detectionAt'
                                    control={control as any}
                                    render={({ field: { onChange } }) => (
                                        <Calendar
                                            {...register('detectionAt')}
                                            value={getValues('detectionAt')}
                                            maxDate={new Date()}
                                            identifier='wizard.general.data.date'
                                            onChange={(e: any) => {
                                                onChange(e);
                                                handleOnBlur('detectionAt', e.toISOString());
                                            }}
                                        />
                                    )}
                                />
                            ) : key === 'radius' ? (
                                <TextField
                                    type='number'
                                    placeholder={translator.get('wizard.general.data.radius.field.placeholder')}
                                    id='radius'
                                    min='0'
                                    {...register('radius')}
                                    onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                                    error={errors.radius}
                                    errorMessage={errors.radius && getMessageByFieldAndError('radius', errors)}
                                    onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
                                        setRadius(Number(e.target.value));
                                        handleOnBlur(e.target.name, e.target.value);
                                    }}
                                />
                            ) : (
                                <TextField
                                    type='text'
                                    placeholder={translator.get(`wizard.general.data.${key}.field.placeholder`)}
                                    id={key}
                                    onKeyDown={evt => (key === 'latitude' || key === 'longitude') && ['e', 'E'].includes(evt.key) && evt.preventDefault()}
                                    {...register(key)}
                                    error={errors[key]}
                                    errorMessage={errors[key] && getMessageByFieldAndError(key, errors)}
                                    readOnly={!isGeneralDataEditMode}
                                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleOnBlur(e.target.name, e.target.value)}
                                />
                            )}
                        </span>
                    ) : (
                        <p className='m-0' style={{ fontWeight: 'bold' }}>
                            {key === 'detectionAt' ? `${moment.utc(record?.detectionAt).local().format('DD/MM/YYYY HH:mm')}` : key === 'radius' ? (value ? value : 0) : value}
                        </p>
                    ),
                },
            };
        });

        return rows;
    };

    const handleOnLocationChange = (coordinates: LatLng) => {
        setValue('latitude', coordinates.lat);
        setValue('longitude', coordinates.lng);
        clearErrors(['latitude', 'longitude']);
    };

    const editableAnimalDataTableRow = (record: Recording) => {
        const translator = TranslatorManager.getInstance();
        const filteredItem: Partial<typeof record> = Object.entries(record)
            .filter(([key]) => ['order', 'family', 'genus', 'species', 'scientificName', 'commonName'].includes(key))
            .reduce((obj: any, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});
        const orderedKeys = ['scientificName', 'commonName', 'order', 'family', 'genus', 'species'];
        const rows = orderedKeys.map(key => {
            const value = filteredItem[key as keyof Recording];
            return {
                id: record?.id,
                values: {
                    title: (
                        <div>
                            <span style={{ fontWeight: 'normal' }}>{translator.get(`catalogue.detail.sound.data.table.${key}.key`)}</span>
                            {(key === 'species' || key === 'genus') && <span className='requiredSign'>*</span>}
                        </div>
                    ),
                    value: isAnimalDataEditMode ? (
                        <span>
                            {
                                <TextField
                                    type='text'
                                    placeholder={translator.get(`catalogue.detail.sound.data.table.${key}.key`)}
                                    id={key}
                                    {...register(key)}
                                    error={errors[key]}
                                    errorMessage={errors[key] && getMessageByFieldAndError(key, errors)}
                                    readOnly={!isAnimalDataEditMode}
                                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleOnBlur(e.target.name, e.target.value)}
                                />
                            }
                        </span>
                    ) : (
                        <p className='m-0' style={{ fontWeight: 'bold' }}>
                            {value ? value : '-'}
                        </p>
                    ),
                },
            };
        });
        return rows;
    };

    const isGeneralDatailValid = () => {
        if (errors.country || errors.latitude || errors.longitude || errors.createdAt || errors.recordingDevice || errors.microphone) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            {/* <div className='container-fluid'> */}
            <div className='row mb-3'>
                <div className='col'>
                    <Accordion identifier='record.detail.generalData' id='generalData' isOpened={true}>
                        <div>
                            <div className='d-flex row'>
                                <div className='col-sm-12 col-lg-6'>
                                    <Table columns={catalogueDetailTableColumns} rows={editableGeneralDataTableRow(record)} keyLabel='soundData' />
                                    <div className='col-12 text-end mb-5 mt-2'>
                                        {record?.recordist?.email === KeycloakUtil.getEmail() && (
                                            <button
                                                className='btn btn-primary text-white text-nowrap width-button-match mt-3'
                                                disabled={isGeneralDatailValid()}
                                                onClick={() => saveEditMode('generalData')}>
                                                {isGeneralDataEditMode ? 'Save' : 'Edit'}
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className='col-sm-12 col-lg-6' style={{ height: 375, zIndex: 1 }}>
                                    <MapComponent
                                        latitude={updatedRecord?.location?.latitude ?? record?.location?.latitude}
                                        longitude={updatedRecord?.location?.longitude ?? record?.location?.longitude}
                                        radius={updatedRecord?.location?.radius ?? record?.location?.radius}
                                        origin='RECORD_DETAIL'
                                        isEditable={isGeneralDataEditMode}
                                        onLocationChange={handleOnLocationChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </Accordion>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col'>
                    <Accordion identifier='record.detail.soundData' id='soundData'>
                        <div className='col-12'>
                            <Table
                                columns={[...catalogueDetailTableColumns]}
                                rows={isAnimalDataEditable ? editableAnimalDataTableRow(record) : soundDataTableRows ?? []}
                                keyLabel='soundData'
                            />
                            <div className='col-12 text-end mb-4 mt-2'>
                                {record?.recordist?.email === KeycloakUtil.getEmail() &&
                                    record?.status !== RecordingStatusEnum.SEED &&
                                    record?.status !== RecordingStatusEnum.ANALYZED &&
                                    record?.status !== RecordingStatusEnum.UPLOADED && (
                                        <button
                                            className='btn btn-primary text-white text-nowrap width-button-match pt-2'
                                            disabled={errors.genus || errors.species || errors.scientificName || errors.commonName || errors.order || errors.family}
                                            onClick={() => saveEditMode('soundData')}>
                                            {isAnimalDataEditMode ? 'Save' : 'Edit'}
                                        </button>
                                    )}
                            </div>
                        </div>
                    </Accordion>
                </div>
            </div>

            {displayMode === DisplayModeEnum.ADVANCED && (
                <div className='row mb-3'>
                    <div className='col'>
                        <Accordion identifier='record.detail.technicalDetails' id='technicalDetails'>
                            <div className='row'>
                                <div className='col-md-6' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <h6 style={{ color: '#009138' }}>Audio Info</h6>
                                    <Table columns={catalogueDetailTableColumns} rows={audioDataTableRows ?? []} keyLabel='soundData' />
                                </div>
                                <div className='col-md-6' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <h6 style={{ color: '#009138' }}>Amplitude</h6>
                                    <Table columns={catalogueDetailTableColumns} rows={amplitudeDataTableRows ?? []} keyLabel='soundData' />
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </div>
            )}

            {displayMode === DisplayModeEnum.ADVANCED && (
                <div className='row mb-3'>
                    <div className='col'>
                        <Accordion identifier='record.detail.audioAnalytics' id='audioAnalytics'>
                            {record?.status !== RecordingStatusEnum.SEED && record?.status !== RecordingStatusEnum.ANALYZING && record?.status !== RecordingStatusEnum.UPLOADED ? (
                                <AudioAnalyticsComponent identifier={'audio.analytics'} record={record} />
                            ) : (
                                <div className='d-flex justify-content-center'>
                                    <h6 style={{ fontWeight: 'normal' }}>No Audio Analytics found</h6>
                                </div>
                            )}
                        </Accordion>
                    </div>
                </div>
            )}

            {record.belindaData?.embed_url && (
                <div className='row mb-3'>
                    <div className='col'>
                        <Accordion identifier='record.detail.metadata' id='metadata'>
                            <div className='accordion-body' style={{ height: 800 }}>
                                <iframe src={record.belindaData?.embed_url} width={'100%'} height='100%' />
                            </div>
                        </Accordion>
                    </div>
                </div>
            )}
            {/* </div> */}
        </>
    );
};
