import { RequestConfigurationGet } from '@api/entities';
import { setConfiguration } from '@features/_shared/store/sharedSlices';
import { store } from '@store/configure';
import { BackofficeApiService, IBackofficeApiService } from '../services/backoffice/backoffice.api.service';

export interface IBackofficeManagerProps {
    getKeys(requestConfigurationGet: RequestConfigurationGet): Promise<void>;
}

export class BackofficeManager implements IBackofficeManagerProps {
    private static instance: BackofficeManager;
    private backofficeService: IBackofficeApiService;
    private constructor() {
        this.backofficeService = BackofficeApiService.getInstance(process.env.REACT_APP_MICROSERVICE_API_BASEPATH!);
    }

    static getInstance() {
        if (BackofficeManager.instance == null) {
            BackofficeManager.instance = new BackofficeManager();
        }
        return BackofficeManager.instance;
    }

    async getKeys(requestConfigurationGet: RequestConfigurationGet): Promise<void> {
        const response = await this.backofficeService.getKeys(requestConfigurationGet);
        response.configuration &&
            Object.keys(response.configuration).forEach(function (item) {
                let newkey = item.replace('ui.homepage.statistics.', '').replace('.visible', '').replace(/\./g, '_');
                response.configuration![newkey] = response.configuration![item];
                delete response.configuration![item];
            });
        store.dispatch(setConfiguration(response.configuration as { [key: string]: string }));
    }
}
