import { ResponseMlAnalyticsResources, ResponseMlClassification, ResponseValidationML } from '@api/entities';
import { IMachineLearningApiClient, MachineLearningApiClients } from './machine-learning.api.client';

export interface IMachineLearningApiService {
    getResourcesML(idrecord: string): Promise<ResponseMlAnalyticsResources>;
    getClassificationML(idRecording: string): Promise<ResponseMlClassification>;
    getValidateML(idRecording: string): Promise<ResponseValidationML>;
}

export class MachineLearningApiService implements IMachineLearningApiService {
    private static instance: MachineLearningApiService;
    private api: IMachineLearningApiClient;

    private constructor(basePath: string) {
        this.api = MachineLearningApiClients.getInstance(basePath);
    }

    static getInstance(basePath: string) {
        if (MachineLearningApiService.instance == null) {
            MachineLearningApiService.instance = new MachineLearningApiService(basePath);
        }
        return MachineLearningApiService.instance;
    }

    async getResourcesML(idrecord: string): Promise<ResponseMlAnalyticsResources> {
        return (await this.api.machineLearning.getResourcesML(idrecord)).data;
    }

    async getClassificationML(idRecording: string): Promise<ResponseMlClassification> {
        return (await this.api.machineLearning.getClassificationML(idRecording)).data;
    }

    async getValidateML(idRecording: string): Promise<ResponseValidationML> {
        return (await this.api.machineLearning.getValidateML(idRecording)).data;
    }
}
