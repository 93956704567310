import { ReactComponent as BurgerMenu } from '@assets/svg/burger.svg';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import LinkBar from '@components/Navbar/components/linkbar.components';
import TabBar from '@components/Navbar/components/tabbar.components';
import UserWidget from '@components/Navbar/components/userWidget.components';
import { TranslatorManager } from '@managers/TranslatorManager';
import { NavigationManager } from '@managers/navigation.manager';
import './styles/navbar.style.scss';
import React from 'react';
import { RouteEnum } from '@routes';

export interface INavBarProps {
    isLogged?: boolean;
    isIDEOpen?: boolean;
    currentRoute?: string;
}

// export interface INavBarState {
//     currentRoute: string;
// }

class NavBar extends React.Component<INavBarProps> {
    private translator: TranslatorManager;
    private navigator: NavigationManager;

    constructor(props: INavBarProps) {
        super(props);
        this.translator = TranslatorManager.getInstance();
        this.navigator = NavigationManager.getInstance();
        this.handleRouteChange = this.handleRouteChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            currentRoute: this.navigator.getCurrentRoute(),
        });
    }

    handleRouteChange(route: string) {
        this.setState({
            currentRoute: route,
        });
    }

    render() {
        return (
            <>
                <nav className='navbar navbar-expand-lg bg-white navbar-container p-0'>
                    <div className='container-fluid'>
                        <a
                            className='navbar-brand logo d-flex justify-content-center'
                            onClick={() => {
                                this.handleRouteChange(RouteEnum.home);
                                NavigationManager.getInstance().navigateTo(RouteEnum.home);
                            }}>
                            <Logo />
                        </a>
                        {<TabBar onRouteChange={this.handleRouteChange} />}
                        <button
                            className='navbar-toggler btn shadow-none'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#navbarNav'
                            aria-controls='navbarNav'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            data-focus-mouse='false'>
                            <BurgerMenu fill='#009102' />
                        </button>
                        <div className='collapse navbar-collapse ' id='navbarNav'>
                            <ul className='navbar-nav '>
                                <li className='nav-item d-flex align-items-center'>
                                    <div className='nav-link'>
                                        <LinkBar isIDEOpen={this.props.isIDEOpen as boolean} route={this.props.currentRoute as string} />
                                    </div>
                                </li>
                                <li className='nav-item d-none d-lg-flex justify-content-center'>
                                    {this.props.isLogged && <div className='vr' />}
                                    <div className='nav-link d-flex align-items-center'>
                                        <UserWidget />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
}

export default NavBar;
