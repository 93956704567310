import { RecordingSearchFilter, RecordingStatusEnum, RequestRecordingSearchSortEnum } from '@api/entities';
import { TextField } from '@components/TextField';
import { Button } from '@features/_shared/components/Button/button.component';
import { CatalogueManager } from '@managers/catalogue.manager';
import { FormManager } from '@managers/form.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import { useStoreSelector } from '@store/hook';
import KeycloakUtil from '@utils/keycloak.util';
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import React from 'react';
import { CatalogueFilterbarData } from '../beans/catalogueFilterbarData';
import { catalogueInitialState } from '../state/catalogueSlice';
import './styles/filterbar.component.style.scss';

const FilterBarProjectView: React.FC = () => {
    const translator = TranslatorManager.getInstance();
    const queueManager = QueueManager.getInstance();
    const catalogueManager = CatalogueManager.getInstance();
    const filters = useStoreSelector(state => state.reducer.catalogue.filters);

    const handleFiltersChange = async (e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name !== 'onlyMyRecords') {
            const updatedFilters = {
                ...filters,
                [e.target.name]: e.target.value,
            };
            if (e.target.value.length >= 3 || filters[e.target.name]) {
                catalogueManager.setFilters(e.target.name as keyof RecordingSearchFilter, e.target.value);
                if (e.target.value === 'ALL') {
                    await catalogueManager.search({
                        size: 10,
                        page: 0,
                        filter: {
                            genus: filters.genus,
                            species: filters.species,
                            recordist: filters.recordist,
                        },
                        sort: {
                            createdAt: RequestRecordingSearchSortEnum.DESC,
                        },
                    });
                } else {
                    await catalogueManager.search({
                        size: 10,
                        page: 0,
                        filter: updatedFilters,
                        sort: {
                            createdAt: RequestRecordingSearchSortEnum.DESC,
                        },
                    });
                }
            }
        } else {
            const updatedFilters = {
                ...filters,
                [e.target.name]: (e.target as HTMLInputElement).checked,
            };
            catalogueManager.setFilters(e.target.name as keyof RecordingSearchFilter, (e.target as HTMLInputElement).checked);
            await catalogueManager.search({
                size: 10,
                page: 0,
                filter: updatedFilters,
                sort: {
                    createdAt: RequestRecordingSearchSortEnum.DESC,
                },
            });
        }
    };

    const handleClearFilters = async () => {
        catalogueManager.resetFilters();
        setValue('status', 'ALL');
        reset({
            species: '',
            genus: '',
            recordist: '',
            status: undefined,
            onlyMyRecords: false,
        });
        catalogueInitialState.filters &&
            (await queueManager.queue(
                QueueKeyType.DEFAULT,
                catalogueManager.search({
                    size: 10,
                    page: 0,
                    filter: catalogueInitialState.filters as RecordingSearchFilter,
                    sort: {
                        createdAt: RequestRecordingSearchSortEnum.DESC,
                    },
                }),
            ));
    };

    const { register, reset, setValue } = FormManager.getInstance().buildFormProxy<CatalogueFilterbarData>(CatalogueFilterbarData, () => {}, {
        species: filters?.species,
        genus: filters?.genus,
        recordist: filters?.recordist,
        onlyMyRecords: filters?.onlyMyRecords,
    });

    return (
        <>
            <div className='container-fluid mb-5'>
                <div className='row row-cols-12'>
                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        <div className='form-group filter-input-container'>
                            <label htmlFor='name' className='active label-color' style={{ lineHeight: 1.5 }}>
                                {translator.get('catalogue.filterbar.genus.label')}
                            </label>
                            <TextField
                                isSearchable={true}
                                {...register('genus')}
                                id={'genus'}
                                className='filter-border'
                                placeholder={translator.get('common.filterbar.search.placeholder')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFiltersChange(e)}
                            />
                        </div>
                    </div>
                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        <div className='form-group filter-input-container'>
                            <label htmlFor='name' className='active label-color' style={{ lineHeight: 1.5 }}>
                                {translator.get('catalogue.filterbar.species.label')}
                            </label>
                            <TextField
                                isSearchable={true}
                                {...register('species')}
                                id={'species'}
                                className='filter-border'
                                placeholder={translator.get('common.filterbar.search.placeholder')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFiltersChange(e)}
                            />
                        </div>
                    </div>
                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        <div id='category'>
                            <div className='form-group filter-input-container'>
                                <label htmlFor='recordist' className='active label-color' style={{ lineHeight: 1.5 }}>
                                    {translator.get('catalogue.filterbar.recordist.label')}
                                </label>
                                <TextField
                                    isSearchable={true}
                                    {...register('recordist')}
                                    className='filter-border'
                                    placeholder={translator.get('common.filterbar.search.placeholder')}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFiltersChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        <div id='status'>
                            <div className='select-wrapper mb-3'>
                                <label htmlFor='status' className='active label-color' style={{ lineHeight: 1.5 }}>
                                    {translator.get('catalogue.table.header.status.text')}
                                </label>
                                <select {...register('status')} className='select-container' onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleFiltersChange(e)}>
                                    <option value={'ALL'} key={'ALL'}>
                                        {'ALL'}
                                    </option>
                                    {Object.keys(RecordingStatusEnum)
                                        .filter(
                                            item =>
                                                item !== RecordingStatusEnum.SEED &&
                                                item !== RecordingStatusEnum.CLASSIFIER &&
                                                item !== RecordingStatusEnum.ANALYZING &&
                                                item !== RecordingStatusEnum.CLASSIFYING,
                                        )
                                        .map((item, index) => {
                                            return (
                                                <option value={item} key={index}>
                                                    {item.replace(/_/g, ' ')}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        {KeycloakUtil.isLoggedIn() && (
                            <div className='form-check' style={{ marginLeft: 0 }}>
                                <input
                                    id='onlyMyRecords'
                                    type='checkbox'
                                    {...register('onlyMyRecords')}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFiltersChange(e)}
                                    className='focus--mouse'
                                />
                                <label className='label-color d-flex flex-row align-items-center my-record-label' htmlFor='onlyMyRecords'>
                                    {translator.get('catalogue.filterbar.my.recordings.label')}
                                </label>
                            </div>
                        )}
                    </div>
                    <div className='col-lg-4 col-sm-12 col-md-4'>
                        <Button variant='primary' identifier='common.clear.all.filters' onClick={handleClearFilters} />
                    </div>
                </div>
            </div>
        </>
    );
};
export default FilterBarProjectView;
