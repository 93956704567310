import { DesignSystemTranslatorManager } from '@managers/FacadeTranslatorManager';
import { LanguageManager } from '@managers/language.manager';
import { store } from '@store/configure';
import KeycloakUtil from '@utils/keycloak.util';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './_shared/theme/custom_bootstrap.scss';
import './_shared/utils/interceptors.util';

(async () => {
    const locales = await LanguageManager.loadLocales();

    DesignSystemTranslatorManager.getInstance(LanguageManager.getInstance(locales));
    const persistor = persistStore(store);

    const renderApp = () =>
        ReactDOM.render(
            <React.StrictMode>
                <IntlProvider locale='en' messages={locales}>
                    <Provider store={store}>
                        <PersistGate persistor={persistor} loading={null}>
                            <App />
                        </PersistGate>
                    </Provider>
                </IntlProvider>
            </React.StrictMode>,
            document.getElementById('root'),
        );

    KeycloakUtil.initKeycloak(renderApp, true);
})();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
