import { ReactComponent as Check } from '@assets/svg/it-check.svg';
import { ReactComponent as Error } from '@assets/svg/it-close.svg';
import { ReactComponent as SoundIcon } from '@assets/svg/sound.svg';
import { Accordion } from '@features/_shared/components/Accordion/accordion.component';
import { Button } from '@features/_shared/components/Button/button.component';
import { Table } from '@features/_shared/components/Table';
import { UploadComponent } from '@features/_shared/components/Upload/upload.component';
import { AudioAnalyticsComponent } from '@features/catalogue/components/audio.analytics.component';
import { amplitudeDataTableRowBuilder, audioDataTableRowBuilder } from '@features/catalogue/constats/constats';
import { ValidationMLEnum, WizardStepsEnum } from '@features/wizard/constants/constants';
import { resetGeneralData, setCurrentStep } from '@features/wizard/state/wizard.slice';
import { TranslatorManager } from '@managers/TranslatorManager';
import { store } from '@store/configure';
import React, { useState } from 'react';
import './wizard.audio.data.view.style.scss';
import { useStoreSelector } from '@store/hook';
import { WizardManager } from '@managers/wizard.manager';
import { Recording, RecordingStatusEnum, ResponseValidationML } from '@api/entities';
import { MachineLearningManager } from '@managers/machine-learning.manager';

export const WizardAudioDataView: React.FC = () => {
    const translator = TranslatorManager.getInstance();
    const wizardManager = WizardManager.getInstance();
    const machineLearningManager = MachineLearningManager.getInstance();

    const draftRecording = useStoreSelector(state => state.reducer.wizard.generalData);
    const createdRecord: Recording = useStoreSelector(state => state.reducer.catalogue.selectedRecording);

    const [fileName, setFilename] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUploaded, setIsUploaded] = useState<boolean>(false);
    const [isAnalyzed, setIsAnalyzed] = useState<boolean>(createdRecord?.wizardStep === 1 && createdRecord?.status === RecordingStatusEnum.ANALYZED ? true : false);
    const [isAnalyzing, setIsAnalyzing] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
    const [validationError, setValidationError] = useState<string | undefined>(undefined);
    const [isRecordAlreadyCreated, setIsRecordAlreadyCreated] = useState<boolean>(!!createdRecord);

    const catalogueDetailTableColumns = [translator.get('catalogue.detail.table.header.title.text'), translator.get('catalogue.detail.table.header.value.text')];

    const handleFileChange = () => {
        setIsRecordAlreadyCreated(true);
        setIsLoading(true);
    };

    const handleFileUploaded = async (fileName: string, record: Recording) => {
        setIsAnalyzed(false);
        setIsUploaded(true);
        setFilename(fileName);
        const isFileValid: ResponseValidationML = await machineLearningManager.getValidateML(record.id as string);
        if (isFileValid.code === ValidationMLEnum.SUPPORTED_FILE || isFileValid.code === ValidationMLEnum.SUPPORTED_FORMAT) {
            setIsLoading(false);
            setValidationError(undefined);
            setIsAnalyzing(true);
            setIsValid(true);
            record.id && (await machineLearningManager.getAnalytics(record.id));
            setIsAnalyzing(false);
            setIsAnalyzed(true);
            store.dispatch(resetGeneralData({}));
        } else {
            setIsLoading(false);
            setIsValid(false);
            setValidationError(isFileValid.code);
        }
    };

    const handleUploadFail = () => {
        setIsLoading(false);
        setIsValid(false);
        setFilename('');
    };

    const handleNextClick = async () => {
        if (createdRecord?.status === RecordingStatusEnum.SEED ? isValid : createdRecord?.status == RecordingStatusEnum.ANALYZED) {
            store.dispatch(setCurrentStep(WizardStepsEnum.RECOGNITION));
            createdRecord.id && wizardManager.nextStep(createdRecord.id, 2);
        }
    };

    return (
        <div className='container-fluid px-4'>
            {/* TODO: to be democked */}
            <div className='row home-title-container mb-5'>
                <h5 className='title-description'>{translator.get('home.view.title.description.part.one')}</h5>
            </div>

            <div className='row mb-3'>
                <div className='col'>
                    <Accordion identifier='wizard.audio.data' id='audioData' isOpened={true}>
                        <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
                            <UploadComponent
                                dragNDrop={false}
                                handleFileChange={handleFileChange}
                                supports='.mp3,.wav,.aiff'
                                record={draftRecording}
                                onSuccess={handleFileUploaded}
                                onUploadFail={handleUploadFail}
                                isRecordAlreadyCreated={isRecordAlreadyCreated}
                                isAnalyzing={isAnalyzing}
                            />
                            {isLoading && (
                                <div className='d-flex gap-2'>
                                    <div className='d-flex align-items-center'>
                                        <div className='audio-loader'></div>
                                    </div>
                                </div>
                            )}
                            {isUploaded && (
                                <div className='d-flex align-items-center gap-2'>
                                    <SoundIcon />
                                    <div>{fileName}</div>
                                </div>
                            )}

                            {isValid && isUploaded ? (
                                <Check fill='#009138' width='40' />
                            ) : isValid === false ? (
                                <div className='d-flex align-items-center gap-2'>
                                    <Error fill='red' width='40' />
                                    <div className='d-flex flex-column'>
                                        <small style={{ color: 'red' }}>File not valid!</small>
                                        <small style={{ color: 'red' }}>
                                            {validationError === ValidationMLEnum.UNSUPPORTED_FORMAT
                                                ? 'Only *.mp3, *.wav, and *.aiff formats are supported'
                                                : 'Supported audio tracks must not exceed one minute in length'}
                                        </small>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </Accordion>
                </div>
            </div>
            {isAnalyzed ? (
                <>
                    <div className='row mb-3'>
                        <div className='col'>
                            <Accordion identifier='wizard.technical.details' id='technicalDetails'>
                                <div className='row'>
                                    <div className='col-md-6' style={{ display: 'flex', flexDirection: 'column' }}>
                                        <h6 style={{ color: '#009138' }}>Audio Info</h6>
                                        <Table
                                            columns={catalogueDetailTableColumns}
                                            rows={
                                                (isAnalyzed && isValid) || (createdRecord?.wizardStep === 1 && createdRecord?.status === RecordingStatusEnum.ANALYZED)
                                                    ? audioDataTableRowBuilder(createdRecord?.metadataML as Partial<Recording>)
                                                    : []
                                            }
                                            keyLabel='technicalDetails'
                                        />
                                    </div>
                                    <div className='col-md-6' style={{ display: 'flex', flexDirection: 'column' }}>
                                        <h6 style={{ color: '#009138' }}>Amplitude</h6>
                                        <Table
                                            columns={catalogueDetailTableColumns}
                                            rows={
                                                (isAnalyzed && isValid) || (createdRecord?.wizardStep === 1 && createdRecord?.status === RecordingStatusEnum.ANALYZED)
                                                    ? amplitudeDataTableRowBuilder(createdRecord?.amplitudeML as Partial<Recording>)
                                                    : []
                                            }
                                            keyLabel='technicalDetails'
                                        />
                                    </div>
                                </div>
                            </Accordion>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col'>
                            <Accordion identifier='wizard.audio.analytics' id='audioAnalytics'>
                                {isAnalyzed || (createdRecord?.wizardStep === 1 && createdRecord?.status === RecordingStatusEnum.ANALYZED) ? (
                                    <AudioAnalyticsComponent identifier={'audio.analytics'} record={createdRecord} />
                                ) : (
                                    <div className='d-flex justify-content-center'>
                                        <h6 style={{ fontWeight: 'normal' }}>No Audio Analytics found</h6>
                                    </div>
                                )}
                            </Accordion>
                        </div>
                    </div>
                </>
            ) : isAnalyzing ? (
                <div className='d-flex gap-3 justify-content-center align-items-center flex-column my-5'>
                    <div className='d-flex align-items-center'>
                        <div className='audio-loader-analytics'></div>
                    </div>
                    <span>The audio track is being analyzed, please wait for the process to be completed </span>
                </div>
            ) : null}
            <div className='col d-flex justify-content-end mb-4 mt-5'>
                <Button
                    identifier='wizard.audio.data.next'
                    variant='primary'
                    disabled={createdRecord?.status !== RecordingStatusEnum.ANALYZED || isAnalyzing || isLoading}
                    onClick={handleNextClick}
                />
            </div>
        </div>
    );
};
