import { ResponseMetricsGet } from '@api/entities';
import { TranslatorManager } from '@managers/TranslatorManager';

export interface IMetricsComponentProps {
    metrics: ResponseMetricsGet;
    identifier: string;
    configuration: { [key: string]: string };
}

export const MetricsComponent: React.FC<IMetricsComponentProps> = ({ metrics, identifier, configuration }) => {
    const translator = TranslatorManager.getInstance();

    return (
        <div className='row metrics-main-container'>
            <div className='start-page-top p-4'>
                <div className='home-header-title row'>
                    <h2 className='title col-12' style={{ fontSize: 32, fontWeight: 600, lineHeight: 1 }}>
                        {translator.get('home.view.metrics.title')}
                    </h2>
                </div>
                <div className='metrics-container'>
                    {Object.entries(metrics).map(([key, value]) => {
                        if (configuration[key] === 'true') {
                            return (
                                <div className='metrics-box'>
                                    <div className='metrics-value'> {value} </div>
                                    <div className='metrics-text'> {translator.get(`${identifier}.${key}.metric.label`)} </div>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
};
