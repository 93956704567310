import Breadcrumb from '@features/_shared/components/Breadcrumb/breadcrumb.component';
import { Button } from '@features/_shared/components/Button/button.component';
import { TranslatorManager } from '@managers/TranslatorManager';
import { RouteEnum } from '@routes';
import React, { useEffect } from 'react';
import { ReactComponent as Close } from '@assets/svg/it-close.svg';
import { NavigationManager } from '@managers/navigation.manager';
import { Stepper } from '@features/_shared/components/Stepper/stepper.component';
import { wizardSteps, WizardStepsEnum } from '../constants/constants';
import { WizardGeneralDataView } from './generalData/wizard.general.data.view';
import { WizardAudioDataView } from './audioData/wizard.audio.data.view';
import { WizardRecognitionView } from './recognition/wizard.recognition.view';
import { WizardSummaryView } from './summary/wizard.summary.data.view';
import { useStoreSelector } from '@store/hook';
import { store } from '@store/configure';
import { resetGeneralData, setCurrentStep } from '../state/wizard.slice';
import { WizardMetadataView } from './metadata/wizard.metadata.view';
import { WizardCompleteView } from './complete/wizard.complete.view';
import { Recording } from '@api/entities';
import { setSelectedRecord } from '@features/catalogue/state/catalogueSlice';

export const WizardView: React.FC = () => {
    const translator = TranslatorManager.getInstance();
    const navigationManager = NavigationManager.getInstance();
    const selectedRecording: Recording = useStoreSelector(state => state.reducer.catalogue.selectedRecording);
    const currentStep: WizardStepsEnum = useStoreSelector(state => state.reducer.wizard.currentStep);

    const stepsMap = new Map<WizardStepsEnum, React.ReactNode | JSX.Element>([
        [WizardStepsEnum.GENERAL_DATA, <WizardGeneralDataView />],
        [WizardStepsEnum.AUDIO_DATA, <WizardAudioDataView />],
        [WizardStepsEnum.RECOGNITION, <WizardRecognitionView />],
        [WizardStepsEnum.SUMMARY, <WizardSummaryView />],
        [WizardStepsEnum.METADATA, <WizardMetadataView />],
        [WizardStepsEnum.COMPLETE, <WizardCompleteView />],
    ]);

    const handleCloseClick = () => {
        navigationManager.navigateTo(RouteEnum.catalogue);
        store.dispatch(setCurrentStep(WizardStepsEnum.GENERAL_DATA));
        store.dispatch(setSelectedRecord(undefined));
        store.dispatch(resetGeneralData({}));
    };

    useEffect(() => {
        return () => {
            if (!window.location.href.includes('/wizard')) {
                store.dispatch(resetGeneralData({}));
            }
        };
    });

    return (
        <>
            <div className='header-title-container pb-0'>
                <p className='header-title col-12 mb-4 mt-0'>{translator.get('wizard.view.header.title')}</p>
                <Breadcrumb secondaryPages={[translator.get('wizard.view.breadcrumb.secondary.item')]} />
            </div>
            <div className='container-fluid'>
                <div className='d-flex justify-content-end mt-3'>
                    <Button variant='primary' onClick={handleCloseClick} identifier='common.close' icon={<Close className='icon ms-1' fill='white' />} />
                </div>
                <Stepper identifier='wizard.view.stepper' steps={wizardSteps} currentStep={Array.from(stepsMap.keys())[selectedRecording?.wizardStep as number] ?? currentStep} />
                <>{stepsMap.get(Array.from(stepsMap.keys())[selectedRecording?.wizardStep as number] ?? currentStep)}</>
            </div>
        </>
    );
};
