import { ResponseMetricsGet, ResponseRecordingsGet } from 'src/api';
import { HomepageApiClients, IHomepageApiClient } from './homepage.api.client';

export interface IHomepageApiService {
    getMetrics(): Promise<ResponseMetricsGet>;
    getLatestRecords(): Promise<ResponseRecordingsGet>;
}

export class HomepageApiService implements IHomepageApiService {
    private static instance: HomepageApiService;
    private api: IHomepageApiClient;

    private constructor(basePath: string) {
        this.api = HomepageApiClients.getInstance(basePath);
    }

    static getInstance(basePath: string) {
        if (HomepageApiService.instance == null) {
            HomepageApiService.instance = new HomepageApiService(basePath);
        }
        return HomepageApiService.instance;
    }

    async getMetrics(): Promise<ResponseMetricsGet> {
        return (await this.api.home.getMetrics()).data;
    }

    async getLatestRecords(): Promise<ResponseRecordingsGet> {
        return (await this.api.home.getLastRecords()).data;
    }
}
