import { ClassificationML, Coordinates, Recording, ResponseMlClassification } from '@api/entities';
import { Slice, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LatLng } from 'leaflet';
import localStorage from 'redux-persist/lib/storage';
import { WizardStepsEnum } from '../constants/constants';

//TODO: to be replaced by
export type GeneralData = Pick<Recording, 'country' | 'detectionAt' | 'recordingDevice' | 'microphone' | 'location' | 'training'>;
export type AudioData = {
    file: string;
    technicalDetails: {
        info: {
            fileType: string;
            length: number;
            sampleRate: number;
            sampleNumber: number;
            bitrate: number;
            channels: number;
            encoding: string;
        };
        amplitude: {
            rms: number;
            peak: number;
            max: number;
            min: number;
            mean: number;
            std: number;
        };
    };
    analytics: {
        timeFourierTransformation: string;
        melspectogram: string;
        melFrequency: string;
        spectogram: string;
        waveform: string;
    };
};

export type RecognitionData = {
    matchesList: Array<ClassificationML>;
    selectedItem?: Recording;
    choosenId?: string;
};

export const wizardPersistedConfig = {
    key: 'wizard',
    storage: localStorage,
    whitelist: ['generalData', 'audioData', 'currentStep', 'selectedItem', 'choosenId', 'matchesList'],
};

export interface WizardState {
    currentStep: WizardStepsEnum;
    isCustomData: boolean;
    generalData: GeneralData;
    audioData: AudioData;
    recognitionData: RecognitionData;
}

export const initialWizardState: WizardState = {
    //TODO: update with actual values
    currentStep: WizardStepsEnum.GENERAL_DATA,
    isCustomData: false,
    generalData: {
        country: '',
        training: false,
        location: {
            latitude: undefined,
            longitude: undefined,
            radius: undefined,
        },
        detectionAt: new Date().toISOString(),
        recordingDevice: '',
        microphone: '',
    },
    audioData: {
        file: '',
        technicalDetails: {
            info: {
                fileType: '',
                length: 0,
                sampleRate: 0,
                sampleNumber: 0,
                bitrate: 0,
                channels: 0,
                encoding: '',
            },
            amplitude: {
                rms: 0,
                peak: 0,
                max: 0,
                min: 0,
                mean: 0,
                std: 0,
            },
        },
        analytics: {
            timeFourierTransformation: '',
            melspectogram: '',
            melFrequency: '',
            spectogram: '',
            waveform: '',
        },
    },
    recognitionData: {
        matchesList: [],
        selectedItem: undefined,
        choosenId: undefined,
    },
};

export const wizardSlice: Slice = createSlice({
    name: 'wizard',
    initialState: initialWizardState,
    reducers: {
        setCurrentStep(state, action: PayloadAction<WizardStepsEnum>) {
            state.currentStep = action.payload;
        },
        setCoordinates(state, action: PayloadAction<LatLng>) {
            state.generalData.location.latitude = action.payload.lat;
            state.generalData.location.longitude = action.payload.lng;
        },
        resetGeneralData(state) {
            state.generalData = initialWizardState.generalData;
        },
        saveValue(state, action: PayloadAction<{ key: keyof Recording; value: string }>) {
            state.generalData[action.payload.key] = action.payload.value;
        },
        saveCoordinates(state, action: PayloadAction<{ key: keyof Coordinates; value: number }>) {
            state.generalData.location[action.payload.key] = action.payload.value;
        },
        setIsCustomData(state, action: PayloadAction<boolean>) {
            state.isCustomData = action.payload;
        },
        setSelectedItem(state, action: PayloadAction<Recording>) {
            state.recognitionData.selectedItem = action.payload;
        },
        resetSelectedItem(state, action: PayloadAction<Recording>) {
            state.recognitionData.selectedItem = undefined;
        },
        setChoosenItem(state, action: PayloadAction<ClassificationML>) {
            state.recognitionData.choosenId = action.payload;
        },
        resetChoosenItem(state, action: PayloadAction<string>) {
            state.recognitionData.choosenId = undefined;
        },
        setClassificationML(state, action: PayloadAction<ResponseMlClassification>) {
            state.recognitionData.matchesList = action.payload;
        },
        resetClassificationML(state) {
            state.recognitionData.matchesList = [];
        },
    },
});

export const {
    setRecordings,
    setMetrics,
    setCurrentStep,
    setCoordinates,
    resetGeneralData,
    saveCoordinates,
    saveValue,
    setIsCustomData,
    setSelectedItem,
    resetSelectedItem,
    setChoosenItem,
    resetChoosenItem,
    setClassificationML,
    resetClassificationML,
} = wizardSlice.actions;
export default wizardSlice.reducer;
