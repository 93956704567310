import { setUpdatedCoordinates } from '@features/catalogue/state/catalogueSlice';
import { setCoordinates } from '@features/wizard/state/wizard.slice';
import { store } from '@store/configure';
import { LatLng, LatLngExpression, LeafletMouseEvent, LocationEvent } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents, Circle } from 'react-leaflet';

export interface IMapComponentProps {
    latitude?: number;
    longitude?: number;
    radius?: number;
    onLocationChange?: (coordinates: LatLng) => void;
    isEditable?: boolean;
    origin: 'RECORD_DETAIL' | 'WIZARD';
}

export const MapComponent: React.FC<IMapComponentProps> = ({ origin, radius, latitude, longitude, onLocationChange, isEditable = true }) => {
    const LocationMarker = () => {
        const [position, setPosition] = useState<LatLng | null>(null);
        const map = useMapEvents({
            click(e: LeafletMouseEvent) {
                if (isEditable) {
                    setPosition(e.latlng);
                    origin === 'WIZARD' ? store.dispatch(setCoordinates(e.latlng)) : store.dispatch(setUpdatedCoordinates(e.latlng));
                    onLocationChange?.(e.latlng);
                }
            },
            locationfound(e: LocationEvent) {
                setPosition(e.latlng);
                map.flyTo(e.latlng, map.getZoom());
                origin === 'WIZARD' ? store.dispatch(setCoordinates(e.latlng)) : store.dispatch(setUpdatedCoordinates(e.latlng));
                onLocationChange?.(e.latlng);
            },
        });

        useEffect(() => {
            if (latitude && longitude) {
                map.flyTo([latitude, longitude], map.getZoom());
                setPosition([latitude, longitude] as any);
            } else {
                map.locate({
                    enableHighAccuracy: true,
                });
            }
        }, [latitude, longitude]);

        return position === null ? null : (
            <Marker position={position as LatLngExpression}>
                <Popup>You are here</Popup>
                <Circle center={position} fillColor='#009138' radius={radius ?? 0} />
            </Marker>
        );
    };

    return (
        <div style={{ height: 'inherit' }}>
            <MapContainer center={[50, 9]} zoom={14} style={{ height: 'inherit', width: 'inherit' }} className='focus--mouse'>
                <TileLayer
                    noWrap
                    minZoom={1}
                    attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
                <LocationMarker />
            </MapContainer>
        </div>
    );
};
