import { ErrorManager } from '@managers/error.manager';
import { NavigationManager } from '@managers/navigation.manager';
import { RouteEnum } from '@routes';
import axios from 'axios';
import KeycloakUtil, { KeycloakRoleEnum } from './keycloak.util';

const isInterceptorEnabled = process.env.REACT_APP_ENABLE_INTERCEPTOR_LOGS === 'true';

const prettify = (type: string, method?: string, url?: string, payload?: any) => {
    return `${type}: [${method?.toUpperCase()}] - ${url}\n${'-'.repeat(50)}\nPayload: ${payload}`;
};

// Request interceptor
axios.interceptors.request.use(
    config => {
        //StoreManager.getInstance().dispatch(new ShowLoader());
        if (KeycloakUtil.isLoggedIn()) {
            config.headers = {
                Authorization: `Bearer ${KeycloakUtil.getToken()}`,
                'Content-Type': 'application/json',
            };
            const cb = () => {
                config.headers.Authorization = `Bearer ${KeycloakUtil.getToken()}`;
                return Promise.resolve(config);
            };
            KeycloakUtil.updateToken(cb);
        }
        return config;
    },
    function (error) {
        if (isInterceptorEnabled) {
            console.log(`REQUEST ERROR: ${error}`);
        }
        //ErrorManager.getInstance().notifyUser(error);
        return Promise.reject(error);
    },
);

// Response interceptor
axios.interceptors.response.use(
    response => {
        if (isInterceptorEnabled) {
            console.log(prettify('RESPONSE', response.config.method, response.config.url, JSON.stringify(response.data, null, 2)));
        }
        // StoreManager.getInstance().dispatch(new HideLoader());
        if (KeycloakUtil.isLoggedIn() && !KeycloakUtil.hasRole([KeycloakRoleEnum.admin, KeycloakRoleEnum.researcher, KeycloakRoleEnum.reviewer])) {
            KeycloakUtil.doLogin();
            return response;
        } else return response;
    },
    function (error) {
        // StoreManager.getInstance().dispatch(new HideLoader());
        if (isInterceptorEnabled) {
            console.log(`RESPONSE ERROR: ${error}`);
        }
        if (error.response?.status === 401) {
            KeycloakUtil.doLogin();
        }
        if (error.response?.status === 403) {
            NavigationManager.getInstance().navigateTo(RouteEnum.home);
        }
        error.response?.status !== 401 && ErrorManager.getInstance().notifyUser(error);
        return Promise.reject(error);
    },
);
