import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { RouteEnum } from '@routes';
import storage from 'redux-persist/lib/storage';

export const sharedPersistedConfig = {
    key: 'shared',
    storage,
    blacklist: ['loading', 'popup'],
    whitelist: ['currentRoute', 'configuration'],
};

export interface SharedState {
    loading: {
        global: number;
        [key: string]: number;
    };
    popup: {
        isVisible: boolean;
    };
    currentRoute?: string;
    configuration: {
        [key: string]: string | undefined;
    };
}

export const sharedInitialState: SharedState = {
    loading: {
        global: 0,
    },
    popup: {
        isVisible: false,
    },
    currentRoute: RouteEnum.home,
    configuration: {},
};

export const sharedSlice: Slice = createSlice({
    name: 'shared',
    initialState: sharedInitialState,
    reducers: {
        queueTaskForLoader(state, action: PayloadAction<string>) {
            //const key: string = action?.payload || 'global';
            state.loading[action?.payload] = (state.loading[action?.payload] || 0) + 1;
        },
        dequeueTaskForLoader(state, action: PayloadAction<string>) {
            //const key: string = action?.payload || 'global';
            state.loading[action?.payload] = state.loading[action?.payload] - 1;
        },
        showPopup(state) {
            state.popup.isVisible = true;
        },
        hidePopup(state) {
            state.popup.isVisible = false;
        },
        setCurrentRoute(state, action: PayloadAction<string>) {
            state.currentRoute = action.payload;
        },
        setConfiguration(state, action: PayloadAction<{ [key: string]: string }>) {
            state.configuration = action.payload;
        },
    },
});

export const { queueTaskForLoader, dequeueTaskForLoader, showPopup, hidePopup, setCurrentRoute, setInitialState, setConfiguration } = sharedSlice.actions;
export default sharedSlice.reducer;
