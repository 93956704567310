import { RecordingStatusEnum, ResponseValidationML } from '@api/entities';
import { setAnalytics } from '@features/catalogue/state/catalogueSlice';
import { setClassificationML } from '@features/wizard/state/wizard.slice';
import { store } from '@store/configure';
import { IMachineLearningApiService, MachineLearningApiService } from '../services/machine-learning/machine-learning.api.service';

export interface IMachineLearningManagerProps {
    getAnalytics(idRecording: string): Promise<void>;
    getValidateML(idRecording: string): Promise<ResponseValidationML>;
}

export class MachineLearningManager implements IMachineLearningManagerProps {
    private static instance: MachineLearningManager;
    private machineLearningService: IMachineLearningApiService;

    private constructor() {
        this.machineLearningService = MachineLearningApiService.getInstance(process.env.REACT_APP_MICROSERVICE_API_BASEPATH!);
    }

    static getInstance() {
        if (MachineLearningManager.instance == null) {
            MachineLearningManager.instance = new MachineLearningManager();
        }
        return MachineLearningManager.instance;
    }

    async getAnalytics(idRecording: string): Promise<void> {
        const response = await this.machineLearningService.getResourcesML(idRecording);
        store.dispatch(setAnalytics({ ...response, status: RecordingStatusEnum.ANALYZED }));
    }

    async getClassificationML(idRecording: string): Promise<void> {
        const response = await this.machineLearningService.getClassificationML(idRecording);
        store.dispatch(setClassificationML(response.classificationML));
    }

    async getValidateML(idRecording: string): Promise<ResponseValidationML> {
        return await this.machineLearningService.getValidateML(idRecording);
    }
}
