/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface BelindaMetadata
 */
export interface BelindaMetadata {
    /**
     *
     * @type {string}
     * @memberof BelindaMetadata
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof BelindaMetadata
     */
    timestamp?: string;
    /**
     *
     * @type {string}
     * @memberof BelindaMetadata
     */
    id_belinda?: string;
    /**
     *
     * @type {string}
     * @memberof BelindaMetadata
     */
    embed_url?: string;
    /**
     *
     * @type {string}
     * @memberof BelindaMetadata
     */
    external_url?: string;
    /**
     *
     * @type {string}
     * @memberof BelindaMetadata
     */
    type?: BelindaMetadataTypeEnum;
}

export const BelindaMetadataTypeEnum = {
    AUDIO: 'AUDIO',
} as const;

export type BelindaMetadataTypeEnum = (typeof BelindaMetadataTypeEnum)[keyof typeof BelindaMetadataTypeEnum];
