import { CatalogueControllerApi } from '../../../api/api';

export interface ICatalogueApiClient {
    catalogue: CatalogueControllerApi;
}

export class CatalogueApiClients {
    private static instance: ICatalogueApiClient;

    static getInstance(basePath: string): ICatalogueApiClient {
        if (CatalogueApiClients.instance == null) {
            CatalogueApiClients.instance = {
                catalogue: new CatalogueControllerApi(undefined, basePath),
            };
        }
        return CatalogueApiClients.instance;
    }
}
