/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ResponseMetricsGet } from '../entities';
// @ts-ignore
import { ResponseRecordingsGet } from '../entities';
/**
 * HomeControllerApi - axios parameter creator
 * @export
 */
export const HomeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve latest ten public recordings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastRecords: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/home/latest/records`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get metrics for home page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/home/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * HomeControllerApi - functional programming interface
 * @export
 */
export const HomeControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = HomeControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieve latest ten public recordings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastRecords(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseRecordingsGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastRecords(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get metrics for home page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetrics(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMetricsGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetrics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * HomeControllerApi - factory interface
 * @export
 */
export const HomeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HomeControllerApiFp(configuration);
    return {
        /**
         * Retrieve latest ten public recordings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastRecords(options?: any): AxiosPromise<ResponseRecordingsGet> {
            return localVarFp.getLastRecords(options).then(request => request(axios, basePath));
        },
        /**
         * Get metrics for home page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics(options?: any): AxiosPromise<ResponseMetricsGet> {
            return localVarFp.getMetrics(options).then(request => request(axios, basePath));
        },
    };
};

/**
 * HomeControllerApi - interface
 * @export
 * @interface HomeControllerApi
 */
export interface HomeControllerApiInterface {
    /**
     * Retrieve latest ten public recordings.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeControllerApiInterface
     */
    getLastRecords(options?: AxiosRequestConfig): AxiosPromise<ResponseRecordingsGet>;

    /**
     * Get metrics for home page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeControllerApiInterface
     */
    getMetrics(options?: AxiosRequestConfig): AxiosPromise<ResponseMetricsGet>;
}

/**
 * HomeControllerApi - object-oriented interface
 * @export
 * @class HomeControllerApi
 * @extends {BaseAPI}
 */
export class HomeControllerApi extends BaseAPI implements HomeControllerApiInterface {
    /**
     * Retrieve latest ten public recordings.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeControllerApi
     */
    public getLastRecords(options?: AxiosRequestConfig) {
        return HomeControllerApiFp(this.configuration)
            .getLastRecords(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Get metrics for home page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeControllerApi
     */
    public getMetrics(options?: AxiosRequestConfig) {
        return HomeControllerApiFp(this.configuration)
            .getMetrics(options)
            .then(request => request(this.axios, this.basePath));
    }
}
