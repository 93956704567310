import { ClassificationML, Recording } from '@api/entities';
import { Accordion } from '@features/_shared/components/Accordion/accordion.component';
import { AudioPlayerComponent } from '@features/_shared/components/AudioPlayer/audio.player';
import { Button } from '@features/_shared/components/Button/button.component';
import { Card } from '@features/_shared/components/Card/card.component';
import { Table } from '@features/_shared/components/Table';
import { TranslatorManager } from '@managers/TranslatorManager';
import { useStoreSelector } from '@store/hook';
import { FormatUtil } from '@utils/formaters.util';
import React, { useState } from 'react';
import { animalDataRowBuilder, audioDataTableCompareRowBuilder, technicalDetailsAmplitudeCompareRowBuilder } from '../constants/constants';
import { WizardUtil } from '../utils/wizard.util';
import { AudioAnalyticsComparisonComponent } from './match.audio.analytics.component';

export interface IMatchComparisonComponentProps {
    identifier: string;
    item: Recording;
    onClose: () => void;
    onChoose: (id: string) => void;
    isChoosen: boolean;
    selectedItem: ClassificationML;
}

export const MatchComparisonComponent: React.FC<IMatchComparisonComponentProps> = ({ identifier, item, onClose, isChoosen, onChoose, selectedItem }) => {
    const translator = TranslatorManager.getInstance();
    const [choosen, setChoosen] = useState<boolean>(isChoosen);
    const myRecording: Recording = useStoreSelector(state => state.reducer.catalogue.selectedRecording);
    const comparisonRecording: Recording = useStoreSelector(state => state.reducer.wizard.recognitionData.selectedItem);

    const catalogueDetailTableColumns = [translator.get('catalogue.detail.table.header.title.text'), translator.get('catalogue.detail.table.header.value.text')];
    const recognitionTechnicalDetailsColumns = [
        translator.get('catalogue.detail.table.header.title.text'),
        translator.get('wizard.recognition.table.header.my.recording.text'),
        translator.get('wizard.recognition.table.header.compared.text'),
    ];

    const handleChooseClick = (id: string) => {
        onChoose(id);
        setChoosen(true);
    };

    return (
        <>
            <div className='mb-4'>
                <Card>
                    <div className='col-12'>
                        <div className='p-4'>
                            <div className='px-4'>
                                <h6 className='mt-3 speces-text-detail-size'>{translator.get(`${identifier}.card.title`)}</h6>
                            </div>
                            <div className='row d-flex justify-content-between'>
                                <div className={`col-2 circle-container-detail ${WizardUtil.getLevelColor((selectedItem.percentage as number) * 100)}`}>
                                    <h6 className='m-0'>{`${FormatUtil.roundNumberAfterComma((selectedItem.percentage as number) * 100, 1)}%`}</h6>
                                </div>
                                <div className='col-md-10 col-lg-7 mt-3 p-0'>
                                    <div className='size-audioPlayer-Match'>
                                        <h6>{`${selectedItem.genus} ${selectedItem.species}`}</h6>
                                        <AudioPlayerComponent recordId={selectedItem?.idReferral as string} autoPlay={false} />
                                    </div>
                                </div>
                                <div className='col-md-12 mt-4 gap-2 col-lg-3 p-0 justify-content-end recognition-button'>
                                    {!choosen ? (
                                        <>
                                            <Button identifier='wizard.recognition.close' variant='secondary' onClick={onClose} />
                                            <Button
                                                identifier='wizard.recognition.choose'
                                                variant='primary'
                                                onClick={() => selectedItem.idReferral && handleChooseClick(selectedItem.idReferral)}
                                            />
                                        </>
                                    ) : (
                                        <Button identifier='wizard.recognition.revert' variant='primary' onClick={() => setChoosen(false)} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>

            {!choosen && (
                <>
                    <div className='row mb-3'>
                        <div className='col'>
                            <Accordion identifier='record.detail.soundData' id='soundData'>
                                <Table columns={catalogueDetailTableColumns} rows={animalDataRowBuilder(item) ?? []} keyLabel='soundData' />
                            </Accordion>
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className='col'>
                            <Accordion identifier='record.detail.technicalDetails' id='technicalDetails'>
                                <>
                                    <h6 style={{ color: '#009138' }}>Audio Info</h6>
                                    <Table
                                        columns={recognitionTechnicalDetailsColumns}
                                        rows={audioDataTableCompareRowBuilder(myRecording, comparisonRecording) ?? []}
                                        keyLabel='soundData'
                                    />
                                    <h6 style={{ color: '#009138' }}>Amplitude</h6>
                                    <Table
                                        columns={recognitionTechnicalDetailsColumns}
                                        rows={technicalDetailsAmplitudeCompareRowBuilder(myRecording, comparisonRecording) ?? []}
                                        keyLabel='soundData'
                                    />
                                </>
                            </Accordion>
                        </div>
                    </div>

                    <div className='row mb-3'>
                        <div className='col'>
                            <Accordion identifier='wizard.recognition.audio.analytics' id='audioAnalytics'>
                                <AudioAnalyticsComparisonComponent identifier='wizard.recognition' recording={myRecording} comparisonRecording={comparisonRecording} />
                            </Accordion>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
