/* eslint-disable */
import { IsNotEmpty, Matches, ValidateIf } from 'class-validator';

export class RecordDetailData {
    @Matches(/^(?!['-])[a-zA-Z\s'-]*[a-zA-Z](?![a-zA-Z\s'-])$/)
    @IsNotEmpty()
    country: string;

    @Matches(/^([+\-]?[0-9]+(\.[0-9]+)?)?$/)
    @ValidateIf(value => typeof value.latitude === 'string')
    @IsNotEmpty()
    latitude: string;

    @Matches(/^([+\-]?[0-9]+(\.[0-9]+)?)?$/)
    @ValidateIf(value => typeof value.longitude === 'string')
    @IsNotEmpty()
    longitude: string;

    @ValidateIf(value => value.radius)
    @Matches(/^[0-9]*(?:\.[0-9]+)?$/)
    radius: number;

    @IsNotEmpty()
    detectionAt: string | Date;

    @Matches(/^(?!['-])[a-zA-Z0-9\s'-]*[a-zA-Z0-9](?![a-zA-Z0-9\s'-])$/)
    @IsNotEmpty()
    recordingDevice: string;

    @Matches(/^(?!['-])[a-zA-Z0-9\s'-]*[a-zA-Z0-9](?![a-zA-Z0-9\s'-])$/)
    @IsNotEmpty()
    microphone: string;

    @ValidateIf(value => value.order)
    @Matches(/^(?!['-])[a-zA-Z0-9\s'-]*[a-zA-Z0-9](?![a-zA-Z0-9\s'-])$/)
    order: string;

    @ValidateIf(value => value.family)
    @Matches(/^(?!['-])[a-zA-Z0-9\s'-]*[a-zA-Z0-9](?![a-zA-Z0-9\s'-])$/)
    family: string;

    @Matches(/^(?!['-])[a-zA-Z0-9\s'-]*[a-zA-Z0-9](?![a-zA-Z0-9\s'-])$/)
    @IsNotEmpty()
    genus: string;

    @Matches(/^(?!['-])[a-zA-Z0-9\s'-]*[a-zA-Z0-9](?![a-zA-Z0-9\s'-])$/)
    @IsNotEmpty()
    species: string;

    @ValidateIf(value => value.scientificName)
    @Matches(/^(?!['-])[a-zA-Z0-9\s'-]*[a-zA-Z0-9](?![a-zA-Z0-9\s'-])$/)
    scientificName: string;

    @ValidateIf(value => value.commonName)
    @Matches(/^(?!['-])[a-zA-Z0-9\s'-]*[a-zA-Z0-9](?![a-zA-Z0-9\s'-])$/)
    commonName: string;
}
