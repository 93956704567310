import React, { ReactElement } from 'react';
import { NotificationManager } from './notification.manager';
import PopupItem, { PopupItemProps } from '@features/_shared/components/Popup/popup.item';
import { store } from '@store/configure';
import { hidePopup, showPopup } from '@features/_shared/store/sharedSlices';

type Popup = ReactElement | null;

export class PopupManager extends NotificationManager {
    private static instance: PopupManager;
    private popup: Popup = null;

    static getInstance() {
        if (PopupManager.instance == null) {
            PopupManager.instance = new PopupManager();
        }
        return PopupManager.instance;
    }

    private constructor() {
        super();
    }

    show(props: PopupItemProps) {
        this.popup && this.hide();
        this.popup = React.createElement(PopupItem, props);
        store.dispatch(showPopup(true));
        //TODO: update store with redux toolkit
    }

    hide() {
        this.popup = null;
        store.dispatch(hidePopup(false));
        //TODO: update store with redux toolkit
    }

    get() {
        return this.popup;
    }
}
