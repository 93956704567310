import { MachineLearningControllerApi } from '@api/api';

export interface IMachineLearningApiClient {
    machineLearning: MachineLearningControllerApi;
}

export class MachineLearningApiClients {
    private static instance: IMachineLearningApiClient;

    static getInstance(basePath: string): IMachineLearningApiClient {
        if (MachineLearningApiClients.instance == null) {
            MachineLearningApiClients.instance = {
                machineLearning: new MachineLearningControllerApi(undefined, basePath),
            };
        }
        return MachineLearningApiClients.instance;
    }
}
