import { Button } from '@features/_shared/components/Button/button.component';
import { NavigationManager } from '@managers/navigation.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import './wizard.complete.view.style.scss';
import { Paths } from '@routes';
import React from 'react';
import { useStoreSelector } from '@store/hook';
import { Recording } from '@api/entities';
import { WizardManager } from '@managers/wizard.manager';

export const WizardCompleteView = () => {
    const translator = TranslatorManager.getInstance();
    const navigationManager = NavigationManager.getInstance();

    const record: Recording = useStoreSelector(state => state.reducer.catalogue.selectedRecording);

    const handleCompletePublication = () => {
        if (record.id) {
            WizardManager.getInstance().nextStep(record.id, 6);
            navigationManager.navigateTo(`/record/${record.id}` as Paths);
        }
    };

    return (
        <>
            <div className='col-12'>
                <h3 className='mb-5 text-center'>{translator.get('wizard.complete.title')}</h3>
                <div className='d-flex justify-content-center text-start mb-2 ps-5' style={{ whiteSpace: 'pre-line' }}>
                    {translator.get('wizard.complete.description')}
                </div>
            </div>

            <div className='col d-flex position-button-complete mb-4'>
                <Button identifier='wizard.complete.detail' variant='primary' onClick={handleCompletePublication} />
            </div>
        </>
    );
};
