import { ReactComponent as ArrowDown } from '@assets/svg/it-arrow-down-triangle.svg';
import { ReactComponent as ArrowUp } from '@assets/svg/it-arrow-up-triangle.svg';
import { ReactComponent as Eye } from '@assets/svg/it-password-visible.svg';
import { TranslatorManager } from '@managers/TranslatorManager';
import './styles/action.button.component.style.scss';
import { store } from '@store/configure';
import { DisplayModeEnum, setDisplayMode } from '../state/catalogueSlice';

export interface IDisplayModeButtonProps {
    identifier: string;
    currentMode?: DisplayModeEnum;
}

import React, { useRef, useState, useEffect } from 'react';

export const DisplayModeButton: React.FC<IDisplayModeButtonProps> = ({ identifier, currentMode }) => {
    const translator = TranslatorManager.getInstance();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const buttonRef = useRef(null);

    const displayMode = (display: DisplayModeEnum) => {
        store.dispatch(setDisplayMode(display));
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (buttonRef.current && !(buttonRef.current as any).contains(event.target)) {
                setIsDropdownOpen(false);
            }
        }

        window.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <>
            <div className='btn-group' ref={buttonRef}>
                <button
                    className='btn btn-outline-primary d-flex flex-row rounded-3 align-items-center gap-2'
                    type='button'
                    data-bs-toggle='dropdown'
                    aria-controls='dropdown'
                    aria-haspopup='true'
                    onClick={toggleDropdown}
                    aria-expanded={isDropdownOpen}>
                    <Eye className='icon' fill='#009138' />
                    {translator.get(`${identifier}.button.label`)}
                    <ArrowDown className='arrow-down icon' fill='#009138' />
                    <ArrowUp className='arrow-up icon' fill='#009138' />
                </button>
                <div className={`dropdown-menu ${isDropdownOpen && 'show'}`} tabIndex={1}>
                    <div className='link-list-wrapper'>
                        <ul className='link-list'>
                            <li>
                                <a
                                    onClick={() => displayMode(DisplayModeEnum.SIMPLIFIED)}
                                    className={`dropdown-item list-item link-item ${currentMode === DisplayModeEnum.SIMPLIFIED && 'disabled'}`}>
                                    <span>{translator.get(`${identifier}.action.simplified.text`)}</span>
                                </a>
                            </li>

                            <li>
                                <a
                                    onClick={() => displayMode(DisplayModeEnum.ADVANCED)}
                                    className={`dropdown-item list-item link-item ${currentMode === DisplayModeEnum.ADVANCED && 'disabled'}`}>
                                    <span>{translator.get(`${identifier}.action.advanced.text`)}</span>
                                </a>
                            </li>

                            {/* <li>
                                <a
                                    onClick={() => displayMode(DisplayModeEnum.METADATA)}
                                    className={`dropdown-item list-item link-item ${currentMode === DisplayModeEnum.METADATA && 'disabled'}`}>
                                    <span>{translator.get(`${identifier}.action.metadata.text`)}</span>
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};
