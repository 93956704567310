import { Recording } from '@api/entities';
import { Button } from '@features/_shared/components/Button/button.component';
import { TranslatorManager } from '@managers/TranslatorManager';
import { ReactComponent as Close } from '@assets/svg/it-close.svg';
import { NavigationManager } from '@managers/navigation.manager';
import { RouteEnum } from '@routes';

export interface ICatalogueDetailHeaderProps {
    record: Recording;
    identifier: string;
}

export const CatalogueDetailHeader: React.FC<ICatalogueDetailHeaderProps> = ({ record, identifier }) => {
    const translator = TranslatorManager.getInstance();
    const navigationManager = NavigationManager.getInstance();

    return (
        <div className={`container-fluid container-gray mb-3`}>
            <div className='container-fluid container-gray mb-3'>
                <div className='row pt-3 justify-content-between'>
                    <div className='col white-space-nowrap d-flex position-detail-element justify-content-between'>
                        <div className='row padding-button-header align-content-center flex-column flex-lg-row justify-content-start'>
                            <div className='col p-0 align-item-header-detail width-element-header'>
                                <span style={{ color: '#009138', fontWeight: 600, fontSize: 15 }}>{record.status!.replace(/_/g, ' ')} - </span>
                                <p className='inline-text ms-1 text-header-detail'>{translator.get(`${identifier}.recordist.text`)}:</p>
                                <p className='inline-text ms-1 text-header'>{`${record?.recordist?.name} ${record?.recordist?.surname} `} </p>
                                <p className=' ms-1 separator-detail'>- </p>
                            </div>
                            <div className='col p-0 align-item-header-detail width-element-header'>
                                <p className='inline-text ms-1 text-header-detail'>{translator.get(`${identifier}.species.text`)}:</p>
                                <p className='inline-text ms-1 text-header'>{`${record?.species ? record.species : 'N.A.'}`} </p>
                                <p className=' ms-1 separator-detail'>- </p>
                            </div>
                            <div className='col p-0 align-item-header-detail width-element-header'>
                                <p className='inline-text ms-1 text-header-detail'>{translator.get(`${identifier}.scientific.name.text`)}:</p>
                                <p className='inline-text ms-1 text-header'>{`${record?.scientificName ? record.scientificName : 'N.A.'}`} </p>
                                <p className=' ms-1 separator-detail'>- </p>
                            </div>
                            <div className='col p-0 align-item-header-detail width-element-header'>
                                <p className='inline-text ms-1 text-header-detail'>{translator.get(`${identifier}.common.name.text`)}:</p>
                                <p className='inline-text ms-1 text-header'>{`${record?.commonName ? record.commonName : 'N.A.'}`} </p>
                            </div>
                        </div>
                        <div className='ms-4'>
                            <Button
                                variant='primary'
                                onClick={() => navigationManager.navigateTo(RouteEnum.catalogue)}
                                identifier='common.close'
                                icon={<Close className='icon ms-1' fill='white' />}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
