import { ClassificationML, Recording, RecordingStatusEnum } from '@api/entities';
import { setSelectedRecord } from '@features/catalogue/state/catalogueSlice';
import { WizardStepsEnum } from '@features/wizard/constants/constants';
import { resetClassificationML, setChoosenItem, setCurrentStep, setIsCustomData } from '@features/wizard/state/wizard.slice';
import { AudioPlayerComponent } from '@features/_shared/components/AudioPlayer/audio.player';
import { Button } from '@features/_shared/components/Button/button.component';
import { Card } from '@features/_shared/components/Card/card.component';
import { MachineLearningManager } from '@managers/machine-learning.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import { WizardManager } from '@managers/wizard.manager';
import { store } from '@store/configure';
import { useStoreSelector } from '@store/hook';
import React, { useEffect, useState } from 'react';
import { MatchComparisonComponent } from '../../components/match.comparison.component';
import { MatchOptionComponent } from '../../components/match.options.component';
import '../audioData/wizard.audio.data.view.style.scss';

export const WizardRecognitionView: React.FC = () => {
    const translator = TranslatorManager.getInstance();
    const wizardManager = WizardManager.getInstance();
    const machineLearningManager = MachineLearningManager.getInstance();

    const queueManager = QueueManager.getInstance();
    const [customizeData, setCustomizeData] = useState<boolean>(false);
    const [isCompareSelected, setIsCompareSelected] = useState<boolean>(false);
    const [isChooseSelected, setIsChooseSelected] = useState<boolean>(false);
    const recording: Recording = useStoreSelector(state => state.reducer.catalogue.selectedRecording);
    const matchesList: Array<ClassificationML> = useStoreSelector(state => state.reducer.wizard.recognitionData.matchesList);
    const choosenItem: Recording = useStoreSelector(state => state.reducer.wizard.recognitionData.selectedItem);
    const choosenItemId: Recording = useStoreSelector(state => state.reducer.wizard.recognitionData.choosenId);

    const handleChooseClick = async (id: string) => {
        await wizardManager.getMyRecording(id);
        matchesList.find((item: ClassificationML) => {
            id === item.idReferral && store.dispatch(setChoosenItem(item));
        });
        setIsCompareSelected(false);
        setIsChooseSelected(true);
    };

    const handleCompareClick = async (id: string) => {
        await wizardManager.getMyRecording(id);
        matchesList.find((item: ClassificationML) => {
            id === item.idReferral && store.dispatch(setChoosenItem(item));
        });
        setIsCompareSelected(true);
        setIsChooseSelected(false);
    };

    const handleCloseClick = () => {
        setIsCompareSelected(false);
        setIsChooseSelected(false);
    };

    const handleNextClick = async () => {
        if (customizeData) {
            recording.id && wizardManager.nextStep(recording.id, 3);
        } else {
            recording.id && choosenItem.id && (await queueManager.queue(QueueKeyType.DEFAULT, wizardManager.classifyRecording(recording.id, choosenItem.id)));
            recording.id && (await wizardManager.nextStep(recording.id, 3));
        }

        store.dispatch(setIsCustomData(customizeData));
        store.dispatch(setCurrentStep(WizardStepsEnum.SUMMARY));
    };

    useEffect(() => {
        (async () => {
            recording.id && (await queueManager.queue(QueueKeyType.DEFAULT, machineLearningManager.getClassificationML(recording.id)));
            store.dispatch(setSelectedRecord({ ...recording, status: RecordingStatusEnum.CLASSIFIED }));
            recording.id && (await wizardManager.getAudioData(recording.id));
        })();

        return () => {
            store.dispatch(resetClassificationML([]));
        };
    }, []);

    return (
        <div className='container-fluid px-4'>
            {/* TODO: to be democked */}
            <div className='row home-title-container mb-5'>
                <h5 className='title-description'>{translator.get('home.view.title.description.part.one')}</h5>
            </div>

            <div className='mb-4'>
                <Card>
                    <div className='px-4'>
                        <h6 style={{ color: '#009138' }}>{translator.get('wizard.recognition.my.recording.card.title')}</h6>
                        <div>
                            <AudioPlayerComponent recordId={recording.id as string} />
                        </div>
                    </div>
                </Card>
            </div>

            {!customizeData && !isCompareSelected && !isChooseSelected && (
                <div className='mb-4'>
                    <Card>
                        <div className='px-4'>
                            <h6 className='mt-3 speces-text-detail-size'>{translator.get('wizard.recognition.potential.matches.card.title')}</h6>
                        </div>
                        <MatchOptionComponent onChooseClick={handleChooseClick} onCompareClick={handleCompareClick} matchesList={matchesList} />
                    </Card>
                </div>
            )}

            {!isCompareSelected && !isChooseSelected && (
                <div className='mb-5'>
                    <Card>
                        <div className='d-flex justify-content-between align-item-baseline px-4'>
                            <h6 style={{ color: '#009138' }}>{translator.get('wizard.recognition.customize.data.card.title')}</h6>
                            {!customizeData ? (
                                <Button identifier='wizard.recognition.choose' variant='primary' onClick={() => setCustomizeData(true)} />
                            ) : (
                                <Button identifier='wizard.recognition.revert' variant='primary' onClick={() => setCustomizeData(false)} />
                            )}
                        </div>
                    </Card>
                </div>
            )}

            {(isCompareSelected || isChooseSelected) && (
                <MatchComparisonComponent
                    identifier='wizard.recognition.comparison'
                    item={choosenItem}
                    onClose={handleCloseClick}
                    onChoose={handleChooseClick}
                    isChoosen={isChooseSelected}
                    selectedItem={choosenItemId}
                />
            )}

            <div className='col d-flex justify-content-end mb-4 mt-5'>
                {/* <Button identifier='wizard.general.data.skip' variant='primary' onClick={() => store.dispatch(setCurrentStep(WizardStepsEnum.METADATA))} /> */}
                <Button identifier='wizard.general.data.next' variant='primary' disabled={!customizeData && !isChooseSelected} onClick={handleNextClick} />
            </div>
        </div>
    );
};
