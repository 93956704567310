import { Recording } from '@api/entities';
import { Graph } from '@features/_shared/components/Graph/graph.component';
import { TranslatorManager } from '@managers/TranslatorManager';
import { WizardManager } from '@managers/wizard.manager';
import { useEffect, useState } from 'react';

export interface IAudioAnalyticsComparisonComponentProps {
    identifier: string;
    recording: Recording;
    comparisonRecording: Recording;
}

export const AudioAnalyticsComparisonComponent: React.FC<IAudioAnalyticsComparisonComponentProps> = ({ identifier, recording, comparisonRecording }) => {
    const wizardManager = WizardManager.getInstance();
    const translator = TranslatorManager.getInstance();

    const [ftt, setFtt] = useState<string | undefined>(undefined);
    const [mel, setMel] = useState<string | undefined>(undefined);
    const [mfcc, setMfcc] = useState<string | undefined>(undefined);
    const [spec, setSpec] = useState<string | undefined>(undefined);
    const [wave, setWave] = useState<string | undefined>(undefined);
    const [fttComparison, setFttComparison] = useState<string | undefined>(undefined);
    const [melComparison, setMelComparison] = useState<string | undefined>(undefined);
    const [mfccComparison, setMfccComparison] = useState<string | undefined>(undefined);
    const [specComparison, setSpecComparison] = useState<string | undefined>(undefined);
    const [waveComparison, setWaveComparison] = useState<string | undefined>(undefined);

    const getGraphImg = async (item: Recording, analytics: 'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM', isComparisonItem: boolean) => {
        switch (analytics) {
            case 'FTT':
                {
                    if (item.fttJwtUrl) {
                        const [header, payload, signature] = item.fttJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'JPEG', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        isComparisonItem ? setFttComparison(href) : setFtt(href);
                    }
                }
                break;
            case 'MELSPECTROGRAM':
                {
                    if (item.melspectrogramJwtUrl) {
                        const [header, payload, signature] = item.melspectrogramJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'JPEG', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        isComparisonItem ? setMelComparison(href) : setMel(href);
                    }
                }
                break;
            case 'MFCC':
                {
                    if (item.mfccJwtUrl) {
                        const [header, payload, signature] = item.mfccJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'JPEG', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        isComparisonItem ? setMfccComparison(href) : setMfcc(href);
                    }
                }
                break;
            case 'SPECTROGRAM':
                {
                    if (item.spectrogramJwtUrl) {
                        const [header, payload, signature] = item.spectrogramJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'JPEG', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        isComparisonItem ? setSpecComparison(href) : setSpec(href);
                    }
                }
                break;
            case 'WAVEFORM':
                {
                    if (item.waveformJwtUrl) {
                        const [header, payload, signature] = item.waveformJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'JPEG', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        isComparisonItem ? setWaveComparison(href) : setWave(href);
                    }
                }
                break;
        }
    };

    const handleOnDownloadClick = async (item: Recording, analytics: 'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM') => {
        switch (analytics) {
            case 'FTT':
                {
                    if (recording?.fttJwtUrl) {
                        const [header, payload, signature] = recording?.fttJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'CSV', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', 'ftt.csv'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    }
                }
                break;
            case 'MELSPECTROGRAM':
                {
                    if (recording?.melspectrogramJwtUrl) {
                        const [header, payload, signature] = recording?.melspectrogramJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'CSV', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', 'melspectrogram.csv'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    }
                }
                break;
            case 'MFCC':
                {
                    if (recording?.mfccJwtUrl) {
                        const [header, payload, signature] = recording?.mfccJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'CSV', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', 'mfcc.csv'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    }
                }
                break;
            case 'SPECTROGRAM':
                {
                    if (recording?.spectrogramJwtUrl) {
                        const [header, payload, signature] = recording?.spectrogramJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'CSV', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', 'spectrogram.csv'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    }
                }
                break;
            case 'WAVEFORM':
                {
                    if (recording?.waveformJwtUrl) {
                        const [header, payload, signature] = recording?.waveformJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'CSV', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', 'waveform.csv'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    }
                }
                break;
        }
    };

    useEffect(() => {
        getGraphImg(recording, 'FTT', false);
        getGraphImg(recording, 'MELSPECTROGRAM', false);
        getGraphImg(recording, 'MFCC', false);
        getGraphImg(recording, 'SPECTROGRAM', false);
        getGraphImg(recording, 'WAVEFORM', false);
        getGraphImg(comparisonRecording, 'FTT', true);
        getGraphImg(comparisonRecording, 'MELSPECTROGRAM', true);
        getGraphImg(comparisonRecording, 'MFCC', true);
        getGraphImg(comparisonRecording, 'SPECTROGRAM', true);
        getGraphImg(comparisonRecording, 'WAVEFORM', true);
    }, []);

    return (
        <div className='container-fluid'>
            <div className='row'>
                <h6 style={{ color: '#009138' }}>{translator.get(`${identifier}.time.fourier.transform`)}</h6>
                <div className='col-xl-6 col-lg-12 d-flex justify-content-center'>
                    <Graph img={ftt as string} isDownloadable onDownloadClick={() => handleOnDownloadClick(recording, 'FTT')} />
                </div>
                <div className='col-xl-6 col-lg-12 d-flex justify-content-center'>
                    <Graph img={fttComparison as string} isDownloadable onDownloadClick={() => handleOnDownloadClick(comparisonRecording, 'FTT')} />
                </div>
            </div>
            <div className='row'>
                <h6 style={{ color: '#009138' }}>{translator.get(`${identifier}.melspectrogram`)}</h6>
                <div className='col-xl-6 col-lg-12 d-flex justify-content-center'>
                    <Graph img={mel as string} isDownloadable onDownloadClick={() => handleOnDownloadClick(recording, 'MELSPECTROGRAM')} />
                </div>
                <div className='col-xl-6 col-lg-12 d-flex justify-content-center'>
                    <Graph img={melComparison as string} isDownloadable onDownloadClick={() => handleOnDownloadClick(comparisonRecording, 'MELSPECTROGRAM')} />
                </div>
            </div>
            <div className='row'>
                <h6 style={{ color: '#009138' }}>{translator.get(`${identifier}.mfcc`)}</h6>
                <div className='col-xl-6 col-lg-12 d-flex justify-content-center'>
                    <Graph img={mfcc as string} isDownloadable onDownloadClick={() => handleOnDownloadClick(recording, 'MFCC')} />
                </div>
                <div className='col-xl-6 col-lg-12 d-flex justify-content-center'>
                    <Graph img={mfccComparison as string} isDownloadable onDownloadClick={() => handleOnDownloadClick(comparisonRecording, 'MFCC')} />
                </div>
            </div>
            <div className='row'>
                <h6 style={{ color: '#009138' }}>{translator.get(`${identifier}.spectrogram`)}</h6>
                <div className='col-xl-6 col-lg-12 d-flex justify-content-center'>
                    <Graph img={spec as string} isDownloadable onDownloadClick={() => handleOnDownloadClick(recording, 'SPECTROGRAM')} />
                </div>
                <div className='col-xl-6 col-lg-12 d-flex justify-content-center'>
                    <Graph img={specComparison as string} isDownloadable onDownloadClick={() => handleOnDownloadClick(comparisonRecording, 'SPECTROGRAM')} />
                </div>
            </div>
            <div className='row'>
                <h6 style={{ color: '#009138' }}>{translator.get(`${identifier}.waveform`)}</h6>
                <div className='col-xl-6 col-lg-12 d-flex justify-content-center'>
                    <Graph img={wave as string} isDownloadable onDownloadClick={() => handleOnDownloadClick(recording, 'WAVEFORM')} />
                </div>
                <div className='col-xl-6 col-lg-12 d-flex justify-content-center'>
                    <Graph img={waveComparison as string} isDownloadable onDownloadClick={() => handleOnDownloadClick(comparisonRecording, 'WAVEFORM')} />
                </div>
            </div>
        </div>
    );
};
