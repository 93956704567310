import { ReactComponent as MoreActions } from '@assets/svg/it-more-actions.svg';
import { ReactComponent as ArrowDown } from '@assets/svg/it-arrow-down-triangle.svg';
import { ReactComponent as ArrowUp } from '@assets/svg/it-arrow-up-triangle.svg';
import { TranslatorManager } from '@managers/TranslatorManager';
import './styles/action.button.component.style.scss';
import { WizardManager } from '@managers/wizard.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { useStoreSelector } from '@store/hook';
import { Recording, RecordingStatusEnum } from '@api/entities';
import { store } from '@store/configure';
import { setRecordingList, setSelectedRecord } from '../state/catalogueSlice';
import { NavigationManager } from '@managers/navigation.manager';
import { RouteEnum } from '@routes';
import { useEffect, useRef, useState } from 'react';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';
import { PopupType } from '@features/_shared/components/Popup/popup.item';
import { PopupManager } from '@managers/popup.manager';
import { CatalogueManager } from '@managers/catalogue.manager';

export interface IActionButtonProps {
    isCompact?: boolean;
    isRecordConfidential?: boolean;
    recordId?: string;
}

export const ActionButton: React.FC<IActionButtonProps> = ({ isCompact = true, isRecordConfidential, recordId }) => {
    const translator = TranslatorManager.getInstance();
    const queueManager = QueueManager.getInstance();
    const wizardManager = WizardManager.getInstance();
    const catalogueManager = CatalogueManager.getInstance();
    const popupManager = PopupManager.getInstance();
    const navigationManager = NavigationManager.getInstance();
    const recordingList: Array<Recording> = useStoreSelector(state => state.reducer.catalogue.recordings);
    const recording: Recording = useStoreSelector(state => state.reducer.catalogue.selectedRecording);
    const [record, setRecord] = useState<Recording | undefined>(undefined);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const buttonRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (buttonRef.current && !(buttonRef.current as any).contains(event.target)) {
                setIsDropdownOpen(false);
            }
        }

        window.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = (e: any) => {
        e.stopPropagation();
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleVisibility = async (e: any, target: boolean) => {
        e.stopPropagation();
        if (recordId) {
            const response = await queueManager.queue(
                QueueKeyType.DEFAULT,
                wizardManager.updateRecord(recordId, {
                    confidential: target,
                }),
            );
            const updatedList = [...recordingList.filter((item: Recording) => item.id !== response.id), response].sort((a, b) => {
                const dateA = new Date(a.createdAt as string);
                const dateB = new Date(b.createdAt as string);
                return dateB.getTime() - dateA.getTime(); // Sort in descending order
            });
            store.dispatch(setRecordingList(updatedList));
            document.getElementById(`moreActionButton${recordId}`)?.click();
        }
    };

    const handleResumeWizard = (e: any) => {
        e.stopPropagation();
        store.dispatch(setSelectedRecord(recordingList.find((item: Recording) => item.id === recordId)));
        NavigationManager.getInstance().navigateTo(RouteEnum.wizard);
    };

    const handleDelete = async () => {
        const response = record?.id && (await queueManager.queue(QueueKeyType.DEFAULT, wizardManager.deleteRecording(record?.id)));
        const updatedList = [...recordingList.filter((item: Recording) => item.id !== response)].sort((a, b) => {
            const dateA = new Date(a?.createdAt as string);
            const dateB = new Date(b?.createdAt as string);
            return dateB.getTime() - dateA.getTime();
        });
        store.dispatch(setRecordingList(updatedList));
        if (window.location.href.includes('record')) {
            navigationManager.navigateTo(RouteEnum.catalogue);
        }
    };

    const handleDeleteClick = async (e: any) => {
        e.stopPropagation();
        popupManager.show({
            type: PopupType.Info,
            title: translator.get('catalogue.delete.popup.title'),
            description: translator.get('catalogue.delete.popup.description'),
            maxWidth: 'lg',
            stylingProp: 'popup-display',
            ctas: {
                primary: {
                    label: translator.get('catalogue.delete.popup.delete.button.label'),
                    className: 'popup-accept-button',
                    cta: () => handleDelete(),
                },
                secondary: {
                    label: translator.get('common.button.cancel.label'),
                    className: 'popup-accept-button',
                    cta: () => popupManager.hide(),
                },
            },
        });
    };

    const handleDownloadZipFile = async (e: any) => {
        e.stopPropagation();
        const response = await queueManager.queue(QueueKeyType.DEFAULT, catalogueManager.downloadFilesAsZip(recordId as string));
        const href = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `recording-${recordId}.zip`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    };

    useEffect(() => {
        if (!recording) {
            const record = recordingList.find((item: Recording) => item.id === recordId);
            setRecord(record);
        } else {
            setRecord(recording);
        }
    });

    return (
        <>
            <div className='btn-group' ref={buttonRef}>
                <button
                    className='btn btn-outline-primary d-flex flex-row rounded-3'
                    id={`moreActionButton${recordId}`}
                    type='button'
                    data-bs-toggle='dropdown'
                    aria-controls='dropdown'
                    aria-haspopup='true'
                    onClick={(e: any) => toggleDropdown(e)}
                    aria-expanded='false'>
                    {isCompact ? (
                        <MoreActions fill='#009138' style={{ height: 30, transform: 'rotate(90deg)' }} />
                    ) : (
                        <>
                            {translator.get('common.button.action.label')}
                            <ArrowDown className='arrow-down icon' fill='#009138' />
                            <ArrowUp className='arrow-up icon' fill='#009138' />
                        </>
                    )}
                </button>
                <div className={`dropdown-menu ${isDropdownOpen && 'show'}`} tabIndex={1}>
                    <div className='link-list-wrapper'>
                        <ul className='link-list'>
                            {((record?.status !== RecordingStatusEnum.COMPLETED && record?.recordist?.email === KeycloakUtil.getEmail() && KeycloakUtil.isLoggedIn()) ||
                                (record?.wizardStep &&
                                    record?.wizardStep <= 4 &&
                                    record?.recordist?.email === KeycloakUtil.getEmail() &&
                                    KeycloakUtil.isLoggedIn() &&
                                    KeycloakUtil.hasRole([KeycloakRoleEnum.researcher]))) && (
                                <li onClick={handleResumeWizard}>
                                    <a className='dropdown-item list-item link-item'>
                                        <span>{translator.get('catalogue.table.action.resume.text')}</span>
                                    </a>
                                </li>
                            )}

                            {((record?.recordist?.email === KeycloakUtil.getEmail() &&
                                (KeycloakUtil.hasRole([KeycloakRoleEnum.researcher]) || KeycloakUtil.hasRole([KeycloakRoleEnum.reviewer]))) ||
                                KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) &&
                                (!isRecordConfidential ? (
                                    <li onClick={(e: any) => handleVisibility(e, true)}>
                                        <a className='dropdown-item list-item link-item'>
                                            <span>{translator.get('catalogue.table.action.confidential.text')}</span>
                                        </a>
                                    </li>
                                ) : (
                                    <li onClick={(e: any) => handleVisibility(e, false)}>
                                        <a className='dropdown-item list-item link-item'>
                                            <span>{translator.get('catalogue.table.action.public.text')}</span>
                                        </a>
                                    </li>
                                ))}

                            <li onClick={(e: any) => handleDownloadZipFile(e)}>
                                <a className='dropdown-item list-item link-item'>
                                    <span>{translator.get('catalogue.table.action.download.text')}</span>
                                </a>
                            </li>

                            {((record?.recordist?.email === KeycloakUtil.getEmail() && KeycloakUtil.isLoggedIn()) || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) && (
                                <li onClick={(e: any) => handleDeleteClick(e)}>
                                    <a className='dropdown-item list-item link-item'>
                                        <span>{translator.get('catalogue.table.action.delete.text')}</span>
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};
