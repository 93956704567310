import { Recording } from '@api/entities';
import { TranslatorManager } from '@managers/TranslatorManager';
import moment from 'moment';
import { ActionButton } from '../components/action.button.component';

export const catalogueTableHeaderLabels = [
    'catalogue.table.header.genus.text',
    'catalogue.table.header.species.text',
    'catalogue.table.header.length.text',
    'catalogue.table.header.recordist.text',
    'catalogue.table.header.detectionAt.text',
    'catalogue.table.header.country.text',
    'catalogue.table.header.status.text',
    'catalogue.table.header.catNr.text',
    'catalogue.table.header.confidential.text',
    'catalogue.table.header.actions.text',
];

export const catalogueTableRowBuilder = (item: Recording) => {
    return {
        id: item.id as string,
        values: {
            genus: item.genus,
            species: item.species,
            length: moment.utc(Number(item.metadataML?.length) * 1000).format('HH:mm:ss'),
            recordist: `${item.recordist?.name} ${item.recordist?.surname}`,
            date: moment.utc(item.detectionAt).local().format('DD/MM/YYYY HH:mm A'),
            country: item.country,
            status: <span className='table-status-label'>{item.status!.replace(/_/g, ' ')}</span>,
            catNr: item.catalogueNumber,
            confidential: item.confidential ? 'Yes' : 'No',
            action: <ActionButton isRecordConfidential={item.confidential} recordId={item.id} />,
        },
    };
};

export const soundDataTableRowBuilder = (item: Recording) => {
    const translator = TranslatorManager.getInstance();
    if (item) {
        const filteredItem: Partial<typeof item> = Object.entries(item)
            .filter(([key]) => ['order', 'family', 'genus', 'species', 'scientificName', 'commonName'].includes(key))
            .reduce((obj: any, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});
        const rows = Object.entries(filteredItem).map(([key, value]) => {
            return {
                id: item.id,
                values: {
                    title: <span style={{ fontWeight: 'bold' }}>{translator.get(`catalogue.detail.sound.data.table.${key}.key`)}</span>,
                    value: <span>{value}</span>,
                },
            };
        });
        return rows;
    }
};

export const audioDataTableRowBuilder = (item: Partial<Recording>) => {
    const translator = TranslatorManager.getInstance();
    if (item) {
        const filteredItem: Partial<typeof item> = Object.entries(item)
            .filter(([key]) => ['fileType', 'length', 'sampleRate', 'sampleNumber', 'bitrate', 'channels', 'encoding'].includes(key))
            .reduce((obj: any, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});
        const rows = Object.entries(filteredItem).map(([key, value]) => {
            return {
                id: item.id,
                values: {
                    title: <span style={{ fontWeight: 'bold' }}>{translator.get(`catalogue.detail.sound.data.table.${key}.key`)}</span>,
                    value: key === 'length' ? <span>{value ? moment.utc(Number(value) * 1000).format('HH:mm:ss') : '-'}</span> : <span>{value ? value : '-'}</span>,
                },
            };
        });
        return rows;
    }
};

export const amplitudeDataTableRowBuilder = (item: Partial<Recording>) => {
    const translator = TranslatorManager.getInstance();
    if (item) {
        const filteredItem: Partial<typeof item> = Object.entries(item)
            .filter(([key]) => ['rms', 'peak', 'max', 'min', 'mean', 'std'].includes(key))
            .reduce((obj: any, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});
        const rows = Object.entries(filteredItem).map(([key, value]) => {
            return {
                id: item.id,
                values: {
                    title: <span style={{ fontWeight: 'bold' }}>{translator.get(`catalogue.detail.sound.data.table.${key}.key`)}</span>,
                    value: <span>{value ? value : '-'}</span>,
                },
            };
        });
        return rows;
    }
};
