import { useEffect, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import './audio.player.style.scss';
import 'react-h5-audio-player/lib/styles.css';
import { CatalogueManager } from '@managers/catalogue.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';

export interface IAudioPlayerComponentProps {
    recordId: string;
    autoPlay?: boolean;
}

export const AudioPlayerComponent: React.FC<IAudioPlayerComponentProps> = ({ recordId, autoPlay = false }) => {
    const catalogueManager = CatalogueManager.getInstance();
    const queueManager = QueueManager.getInstance();
    const [audio, setAudio] = useState<string>();
    const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(null);
    const [isSeeking, setIsSeeking] = useState<boolean>(false);
    const [volume, setVolume] = useState<number>(0.5);

    useEffect(() => {
        if (audioElement) {
            audioElement.pause();
            audioElement.src = '';
            setAudioElement(null);
        }

        if (audio) {
            const newAudioElement = new Audio(audio);
            setAudioElement(newAudioElement);
        } else {
            handleTrack();
        }
    }, [audio]);

    useEffect(() => {
        if (audioElement) {
            audioElement.addEventListener('seeked', handleSeeked);
            return () => {
                audioElement.removeEventListener('seeked', handleSeeked);
            };
        }
    }, [audioElement]);

    const handleTrack = async () => {
        if (audioElement) {
            audioElement.pause();
        }
        if (recordId) {
            const response = await queueManager.queue(QueueKeyType.DEFAULT, catalogueManager.getTrack(recordId));
            const href = URL.createObjectURL(new Blob([response.data]));
            setAudio(href);
        }
    };

    const handlePlay = () => {
        if (audioElement && !isSeeking) {
            audioElement.play();
        }
    };

    const handlePause = () => {
        if (audioElement) {
            audioElement.pause();
        }
    };

    const handleSeeked = () => {
        if (audioElement && isSeeking) {
            setIsSeeking(false);
            audioElement.play();
        }
    };

    const handleSeeking = () => {
        if (audioElement) {
            setIsSeeking(true);
            audioElement.pause();
        }
    };

    const handleVolumeChange = (e: any) => {
        if (audioElement) {
            audioElement.volume = e.target.volume;
            setVolume(e.target.volume);
        }
    };

    return (
        <AudioPlayer
            autoPlay={autoPlay}
            autoPlayAfterSrcChange={false}
            src={audio}
            onPlay={handlePlay}
            onPause={handlePause}
            onSeeking={handleSeeking}
            onSeeked={handleSeeked}
            onVolumeChange={(e: Event) => handleVolumeChange(e)}
            volume={volume}
        />
    );
};
