import { ApiView } from '@features/api/views/api.view';
import { BackofficeView } from '@features/backoffice/views/backoffice.view';
import { CatalogueView } from '@features/catalogue/view/catalogue.view';
import { RecordDetailView } from '@features/catalogue/view/record.detail.view';
import { FaqsView } from '@features/faqs/views/faqs.view';
import { HomeView } from '@features/home/views/home.view';
import { WizardView } from '@features/wizard/views/wizard.view';
import { Route } from 'react-router-dom';

export enum RouteEnum {
    home = '/home',
    catalogue = '/catalogue',
    recordDetail = '/record/:id',
    faq = '/faqs',
    administration = '/administration',
    wizardGeneralData = '/wizard/generalData',
    wizardAudio = '/wizard/audio',
    wizardRecognition = '/wizard/recognition',
    wizardSummary = '/wizard/summary',
    wizard = '/wizard',
    api = '/api',
}

export const PUBLIC_ROUTES = [] as const;

export const PRIVATE_ROUTES = [
    { id: 'home', path: RouteEnum.home, component: HomeView },
    { id: 'catalogue', path: RouteEnum.catalogue, component: CatalogueView },
    { id: 'recorddetail', path: RouteEnum.recordDetail, component: RecordDetailView },
    { id: 'faqs', path: RouteEnum.faq, component: FaqsView },
    { id: 'administration', path: RouteEnum.administration, component: BackofficeView },
    { id: 'wizard', path: RouteEnum.wizard, component: WizardView },
    { id: 'api', path: RouteEnum.api, component: ApiView },
] as const;

const routes = [...PUBLIC_ROUTES, ...PRIVATE_ROUTES] as const;

const GuardRoute: React.FC<any> = ({ path, component }) => {
    return <Route exact path={path} component={component} />;
};

export const PublicRoutes = () => (
    <>
        {PUBLIC_ROUTES.map(({ path, component }) => (
            <Route key={path} path={path} component={component} />
        ))}
    </>
);

export const PrivateRoutes = () => {
    return (
        <>
            {PRIVATE_ROUTES.map(({ path, component }) => (
                <GuardRoute key={path} path={path} component={component} />
            ))}
        </>
    );
};

export type Paths = (typeof routes)[number]['path'];
