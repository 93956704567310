import { TranslatorManager } from '@managers/TranslatorManager';
import { useRef, useState } from 'react';
import './styles/upload.component.style.scss';
import * as tus from 'tus-js-client';
import { Recording, ResponseRecordingCreate } from '@api/entities';
import KeycloakUtil from '@utils/keycloak.util';
import { PreviousUpload } from 'tus-js-client';
import { WizardManager } from '@managers/wizard.manager';
import { store } from '@store/configure';

export interface IUploadComponentProps {
    dragNDrop: boolean;
    supports?: string;
    record?: Recording;
    handleFileChange?: (fileName: string) => void;
    onSuccess?: (fileName: string, record: Recording) => void;
    onUploadFail?: () => void;
    isRecordAlreadyCreated?: boolean;
    isAnalyzing?: boolean;
}

export const UploadComponent: React.FC<IUploadComponentProps> = ({
    isAnalyzing,
    isRecordAlreadyCreated,
    dragNDrop,
    handleFileChange,
    supports,
    record,
    onSuccess,
    onUploadFail,
}) => {
    const translator = TranslatorManager.getInstance();
    const wizardManager = WizardManager.getInstance();
    const [dragActive, setDragActive] = useState<boolean>(false);
    const inputRef = useRef(null);

    /* --------------- D&D methods --------------*/

    const handleDrag = (e: React.DragEvent<HTMLFormElement> | React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = async (e: React.DragEvent<HTMLFormElement> | React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileChange?.(e.dataTransfer.files[0].name);
            // handleFiles(e.dataTransfer.files);
        }
    };

    /* ----------------------------------------*/

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            // handleFiles(e.target.files);
            handleFileChange?.(e.target.files[0].name);
            const response = record && !isRecordAlreadyCreated && (await wizardManager.createRecord(record));
            await handleResumableUpload(e.target.files[0], e.target.files[0].name, !isRecordAlreadyCreated ? response : store.getState().reducer.catalogue.selectedRecording);
        }
    };

    const onButtonClick = () => {
        if (inputRef.current) {
            (inputRef.current as any).click();
        }
    };

    const handleResumableUpload = async (file: any, fileName: string, record: ResponseRecordingCreate) => {
        let retryCount = 0;

        const createUploadInstance = () => {
            return new tus.Upload(file, {
                endpoint: `${window.location.origin}/app/services/wizard/upload`,
                metadata: {
                    filename: file.name,
                    filetype: file.type,
                    'id-recording': record.id as string,
                },
                chunkSize: 104857600, // 100MB
                removeFingerprintOnSuccess: true,
                headers: {
                    //'Tus-Resumable': '1.0.0',
                    Authorization: `Bearer ${KeycloakUtil.getToken()}`,
                    'id-recording': record.id as string,
                },
                onError: function (error: tus.DetailedError | Error) {
                    if (error.message.includes('401') && retryCount < 1) {
                        retryCount++;
                        upload.options.headers = {
                            ...upload.options.headers,
                            Authorization: `Bearer ${KeycloakUtil.getToken()}`,
                        };
                        upload.start();
                        retryCount = 0;
                    } else {
                        console.log('Failed because: ' + error);
                        onUploadFail?.();
                    }
                },
                onChunkComplete: async function () {
                    console.log('Chunk completed');
                },
                onSuccess: function () {
                    console.log('Download %s from %s', upload.file, upload.url);
                    onSuccess?.(fileName, record);
                },
            });
        };
        const upload = createUploadInstance();
        upload.findPreviousUploads().then(function (previousUploads) {
            // Found previous uploads so we select the first one.
            const prvUpload = previousUploads.find((item: any) => item.metadata.slotname === fileName);
            if (previousUploads && previousUploads.length > 0 && prvUpload) {
                upload.resumeFromPreviousUpload(prvUpload as PreviousUpload);
            }
            // Start the upload
            upload.start();
        });
    };

    return (
        <div>
            {dragNDrop && (
                <form id='form-file-upload' onDragEnter={handleDrag} onSubmit={e => e.preventDefault()}>
                    <input ref={inputRef} type='file' id='input-file-upload' accept={supports ? supports : ''} multiple={true} onChange={handleChange} />
                    <label id='label-file-upload' htmlFor='input-file-upload' className={dragActive ? 'drag-active' : ''}>
                        <div>
                            <button className='upload-button' onClick={onButtonClick}>
                                {translator.get('upload.file.drag.text')}
                            </button>
                            <p>{supports ? `Supports: ${supports}` : ''}</p>
                        </div>
                    </label>
                    {dragActive && <div id='drag-file-element' onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                </form>
            )}

            {!dragNDrop && (
                <div className='upload-component-container'>
                    <input type='file' ref={inputRef} onChange={handleChange} style={{ display: 'none' }} accept={supports ? supports : ''} />
                    <button className='btn btn-primary text-white' onClick={onButtonClick} disabled={isAnalyzing}>
                        {translator.get('upload.file.browse.text')}
                    </button>
                    <div className='mt-3'>{supports ? `Supports: ${supports}` : ''}</div>
                </div>
            )}
        </div>
    );
};
