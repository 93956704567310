import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { NavigationManager } from '@managers/navigation.manager';
import { RouteEnum } from '@routes';
import React from 'react';
import LinkList from './components/linkList.component';
import './styles/sidebar.style.scss';

export interface ISideBarProps {
    isLogged?: boolean;
    isIDEOpen?: boolean;
    currentRoute?: string;
}

class SideBar extends React.Component<ISideBarProps> {
    render() {
        return (
            <>
                <div className='sidebar-container'>
                    <div className='d-flex justify-content-center w-100'>
                        <a className='sidebar-logo d-flex justify-content-center' onClick={() => NavigationManager.getInstance().navigateTo(RouteEnum.home)}>
                            <Logo width={158} height={78.25} />
                        </a>
                    </div>

                    <LinkList isIDEOpen={this.props.isIDEOpen as boolean} route={this.props.currentRoute as string} />
                </div>
            </>
        );
    }
}

export default SideBar;
