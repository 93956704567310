import Breadcrumb from '@features/_shared/components/Breadcrumb/breadcrumb.component';
import { TranslatorManager } from '@managers/TranslatorManager';
import KeycloakUtil from '@utils/keycloak.util';
import React, { useEffect } from 'react';
import { ReactComponent as Plus } from '@assets/svg/it-plus.svg';
import { Table } from '@features/_shared/components/Table';
import './catalogue.view.scss';
import FilterBarProjectView from '../components/filterbar.component';
import { CatalogueManager } from '@managers/catalogue.manager';
import { useStoreSelector } from '@store/hook';
import { PageBean, Recording, RequestRecordingSearchSortEnum } from '@api/entities';
import { Paginator } from '@features/_shared/components/Paginator';
import { catalogueTableHeaderLabels, catalogueTableRowBuilder } from '../constats/constats';
import { Button } from '@features/_shared/components/Button/button.component';
import { NavigationManager } from '@managers/navigation.manager';
import { RouteEnum } from '@routes';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { store } from '@store/configure';
import { setPagination, setSelectedRecord } from '../state/catalogueSlice';
import { setCurrentStep } from '@features/wizard/state/wizard.slice';
import { WizardStepsEnum } from '@features/wizard/constants/constants';

export const CatalogueView: React.FC = () => {
    const translator = TranslatorManager.getInstance();
    const navigationManager = NavigationManager.getInstance();
    const catalogueManager = CatalogueManager.getInstance();
    const queueManager = QueueManager.getInstance();
    const recordList: Array<Recording> = useStoreSelector(state => state.reducer.catalogue.recordings);
    const pagination: PageBean = useStoreSelector(state => state.reducer.catalogue.pagination);
    const filters = useStoreSelector(state => state.reducer.catalogue.filters);

    const catalogueTableColumns = catalogueTableHeaderLabels.map((label: string) => translator.get(label));
    const catalogueTableRows = recordList?.map((item: Recording) => catalogueTableRowBuilder(item));

    useEffect(() => {
        (async () => {
            await queueManager.queue(
                QueueKeyType.DEFAULT,
                catalogueManager.search({
                    page: pagination.page,
                    size: pagination.size,
                    filter: filters,
                    sort: {
                        createdAt: RequestRecordingSearchSortEnum.DESC,
                    },
                }),
            );
            store.dispatch(setSelectedRecord(undefined));
        })();
    }, []);

    const handleTableRowClick = async (id: string) => {
        await queueManager.queue(QueueKeyType.DEFAULT, catalogueManager.getRecording(id));
    };

    const handleOnUploadClick = () => {
        store.dispatch(setCurrentStep(WizardStepsEnum.GENERAL_DATA));
        store.dispatch(setSelectedRecord(undefined));
        navigationManager.navigateTo(RouteEnum.wizard);
    };

    const handlePageChange = async (page: number, size: number, total: number) => {
        store.dispatch(setPagination({ page, size, total }));
        await queueManager.queue(
            QueueKeyType.DEFAULT,
            catalogueManager.search({
                page: page,
                size: size,
                filter: filters,
                sort: {
                    createdAt: RequestRecordingSearchSortEnum.DESC,
                },
            }),
        );
    };

    return (
        <>
            <div className='container-fluid'>
                <div className='row header-title-container pb-0'>
                    <p className='header-title col-12 mb-4'>{translator.get('catalogue.view.title')}</p>
                    <Breadcrumb secondaryPages={[translator.get('catalogue.breadcrumb.secondary.item')]} />
                </div>
                <div className='row home-title-container '>
                    <h5 className='title-description col-12'>{translator.get('catalogue.title.description')}</h5>
                </div>
                <div className='row pt-5'>
                    <div className='col-12 p-0'>
                        <FilterBarProjectView />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className='d-flex justify-content-between align-items-center p-2'>
                            <p className='tableTitle mb-3 mt-4'>{translator.get('catalogue.table.title')}</p>
                            {KeycloakUtil.isLoggedIn() && (
                                <Button variant='primary' onClick={handleOnUploadClick} identifier='catalogue.upload' icon={<Plus className='icon ms-1' fill='white' />} />
                            )}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className='project-table-container'>
                            <div className='size-tab'>
                                <Table
                                    columns={catalogueTableColumns}
                                    rows={catalogueTableRows ?? []}
                                    isStyledHeaders={true}
                                    onRowClick={handleTableRowClick}
                                    keyLabel='records'
                                    className='text-center'
                                />
                            </div>
                            <Paginator onChange={handlePageChange} page={pagination?.page ?? 0} size={pagination?.size ?? 10} total={pagination?.total ?? 0} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
