export class WizardUtil {
    static getLevelColor(probability: number) {
        if (probability >= 0 && probability <= 39) {
            return 'circle-container-color-low';
        } else if (probability >= 40 && probability <= 69) {
            return 'circle-container-color-medium';
        } else {
            return 'circle-container-color-high';
        }
    }
}
