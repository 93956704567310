import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { LatLng } from 'leaflet';
import sessionStorage from 'redux-persist/lib/storage';
import { Coordinates, LiteRecording, PageBean, Recording, RecordingSearchFilter, ResponseMlAnalyticsResources } from '../../../api';

export enum DisplayModeEnum {
    SIMPLIFIED = 'simplified',
    ADVANCED = 'advanced',
    METADATA = 'metadata',
}

export const cataloguePersistedConfig = {
    key: 'catalogue',
    storage: sessionStorage,
    whitelist: ['selectedRecording'],
};

export interface CatalogueState {
    recordings: Array<LiteRecording>;
    createUpdate: Partial<Recording>;
    displayMode: DisplayModeEnum;
    selectedRecording?: Recording;
    pagination: PageBean;
    filters?: RecordingSearchFilter;
}

export const catalogueInitialState: CatalogueState = {
    recordings: [],
    selectedRecording: undefined,
    displayMode: DisplayModeEnum.SIMPLIFIED,
    pagination: {
        page: 0,
        size: 10,
        total: 0,
    },
    createUpdate: {
        location: {},
    },
    filters: {
        species: undefined,
        genus: undefined,
        recordist: undefined,
        status: undefined,
        onlyMyRecords: undefined,
    },
};

export const catalogueSlice: Slice = createSlice({
    name: 'catalogue',
    initialState: catalogueInitialState,
    reducers: {
        setRecordingList(state, action: PayloadAction<Array<LiteRecording>>) {
            state.recordings = action.payload;
        },
        setDisplayMode(state, action: PayloadAction<DisplayModeEnum>) {
            state.displayMode = action.payload;
        },
        setSelectedRecord(state, action: PayloadAction<Recording>) {
            state.selectedRecording = action.payload;
        },
        setFilter(state, action: PayloadAction<{ key: keyof LiteRecording; value: string }>) {
            state.filters[action.payload.key] = action.payload.value;
        },
        resetFilter(state) {
            state.filters = catalogueInitialState.filters;
        },
        saveValue(state, action: PayloadAction<{ key: keyof Recording; value: string }>) {
            state.createUpdate[action.payload.key] = action.payload.value;
        },
        saveCoordinates(state, action: PayloadAction<{ key: keyof Coordinates; value: number | string }>) {
            state.createUpdate.location[action.payload.key] = action.payload.value;
        },
        setUpdatedCoordinates(state, action: PayloadAction<LatLng>) {
            state.createUpdate.location.latitude = action.payload.lat;
            state.createUpdate.location.longitude = action.payload.lng;
        },
        resetCreateUpdate(state) {
            state.createUpdate = catalogueInitialState.createUpdate;
        },
        setPagination(state, action: PayloadAction<PageBean>) {
            state.pagination.page = action.payload.page;
            state.pagination.size = action.payload.size;
            state.pagination.total = action.payload.total;
        },
        setAnalytics(state, action: PayloadAction<ResponseMlAnalyticsResources>) {
            state.selectedRecording = { ...state.selectedRecording, ...action.payload };
        },
    },
});

export const {
    setRecordingList,
    setSelectedRecord,
    setFilter,
    resetFilter,
    setDisplayMode,
    saveValue,
    saveCoordinates,
    resetCreateUpdate,
    setPagination,
    setAnalytics,
    setUpdatedCoordinates,
} = catalogueSlice.actions;
export default catalogueSlice.reducer;
