import { AxiosPromise } from 'axios';
import { Recording, RequestRecordingSearch, ResponseAudioMetadataRecordingGet, ResponseInternalRecordingGet, ResponseRecordingSearch } from 'src/api/entities';
import { CatalogueApiClients, ICatalogueApiClient } from './catalogue.api.client';

export interface ICatalogueApiService {
    getMetadataRecording(idrecord: string): Promise<ResponseAudioMetadataRecordingGet>;
    getInternalRecord(idrecord: string): Promise<ResponseInternalRecordingGet>;
    search(requestRecordingsGet: RequestRecordingSearch): Promise<ResponseRecordingSearch>;
    getRecording(idrecord: string): Promise<Recording>;
    getTrack(idrecord: string): Promise<AxiosPromise<File>>;
    downloadFilesAsZip(uuid: string): Promise<AxiosPromise<File>>;
}

export class CatalogueApiService implements ICatalogueApiService {
    private static instance: CatalogueApiService;
    private api: ICatalogueApiClient;

    private constructor(basePath: string) {
        this.api = CatalogueApiClients.getInstance(basePath);
    }

    static getInstance(basePath: string) {
        if (CatalogueApiService.instance == null) {
            CatalogueApiService.instance = new CatalogueApiService(basePath);
        }
        return CatalogueApiService.instance;
    }

    async getMetadataRecording(idrecord: string): Promise<ResponseAudioMetadataRecordingGet> {
        return (await this.api.catalogue.getMetadataRecording(idrecord)).data;
    }

    async getInternalRecord(idrecord: string): Promise<ResponseInternalRecordingGet> {
        return (await this.api.catalogue.getInternalRecord(idrecord)).data;
    }

    async search(requestRecordingsGet: RequestRecordingSearch): Promise<ResponseRecordingSearch> {
        return (await this.api.catalogue.search(requestRecordingsGet)).data;
    }

    async getRecording(idrecord: string): Promise<Recording> {
        return (await this.api.catalogue.getRecording(idrecord)).data;
    }

    async getTrack(idrecord: string): Promise<AxiosPromise<File>> {
        return await this.api.catalogue.getTrack1(idrecord, { responseType: 'blob' });
    }

    async downloadFilesAsZip(uuid: string): Promise<AxiosPromise<File>> {
        return await this.api.catalogue.downloadFilesAsZip(uuid, { responseType: 'blob' });
    }
}
