import { ReactComponent as Logout } from '@assets/svg/sign-out-alt-solid.svg';
import { ReactComponent as LoginLogo } from '@assets/svg/user-circle-solid.svg';
import { TranslatorManager } from '@managers/TranslatorManager';
import KeycloakUtil from '@utils/keycloak.util';
import './styles/login.header.styles.scss';
import { useStoreSelector } from '@store/hook';
import { RouteEnum } from '@routes';

const LoginHeader: React.FC = () => {
    const translator = TranslatorManager.getInstance();
    const isLogged: boolean = KeycloakUtil.isLoggedIn();
    const isHome = useStoreSelector(state => state.reducer.shared.currentRoute);

    const handleLogout = () => {
        //TODO: reset store with redux toolkit
        KeycloakUtil.handleLogout();
    };

    return (
        <div className={isHome === RouteEnum.home ? 'home-login-container' : 'login-container'}>
            {isLogged ? (
                <div className='login-link-container'>
                    <a className='d-flex align-items-center log-in-out-text logout-cursor'>
                        <span className={isHome === RouteEnum.home ? 'home-logout-text-container' : 'logout-text-container'}>{KeycloakUtil.getName()}</span>
                        <div onClick={handleLogout}>
                            <Logout className='ms-2' fill={isHome === RouteEnum.home ? '#009138' : 'white'} width={15} />
                        </div>
                    </a>
                </div>
            ) : (
                <div className='login-link-container'>
                    <a className='log-in-out-text' onClick={KeycloakUtil.handleLogin}>
                        <span className={isHome === RouteEnum.home ? 'home-login-text-container' : 'login-text-container'}>{translator.get('login.button.text')}</span>
                        <LoginLogo className='login-icon ms-2' fill={isHome === RouteEnum.home ? '#009138' : 'white'} />
                    </a>
                </div>
            )}
        </div>
    );
};

export default LoginHeader;
