import { Recording } from '@api/entities';
import { WizardStepsEnum } from '@features/wizard/constants/constants';
import { setCurrentStep } from '@features/wizard/state/wizard.slice';
import { Button } from '@features/_shared/components/Button/button.component';
import { PopupType } from '@features/_shared/components/Popup/popup.item';
import { NavigationManager } from '@managers/navigation.manager';
import { PopupManager } from '@managers/popup.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import { WizardManager } from '@managers/wizard.manager';
import { Paths, RouteEnum } from '@routes';
import { store } from '@store/configure';
import { useStoreSelector } from '@store/hook';
import KeycloakUtil from '@utils/keycloak.util';
import moment from 'moment';
import { useEffect, useState } from 'react';
import './wizard.general.metadata.style.scss';

export const WizardMetadataView = () => {
    const popupManager = PopupManager.getInstance();
    const translatorManager = TranslatorManager.getInstance();
    const navigationManager = NavigationManager.getInstance();
    const [url, setUrl] = useState<string | undefined>(undefined);
    const translator = TranslatorManager.getInstance();
    const element = document.querySelector('script[id="injected-integration-belinda-publish"]');
    const iframeURL = element?.textContent;
    const recording: Recording = useStoreSelector(state => state.reducer.catalogue.selectedRecording);

    const handleSkipClick = () => {
        popupManager.show({
            type: PopupType.Info,
            title: translatorManager.get('wizard.metadata.skip.title'),
            description: translatorManager.get('wizard.metadata.skip.description'),
            maxWidth: 'lg',
            stylingProp: 'popup-display',
            ctas: {
                primary: {
                    label: translatorManager.get('popup.menu.ok.title'),
                    className: 'popup-accept-button',
                    cta: () => navigationManager.navigateTo(`/record/${recording.id}` as Paths),
                },
                secondary: {
                    label: translatorManager.get('common.button.cancel.label'),
                    className: 'popup-accept-button',
                    cta: () => popupManager.hide(),
                },
            },
        });
    };

    (window as any).handleOnSuccess = () => {
        console.log('OnSuccess');
        recording.id && WizardManager.getInstance().nextStep(recording.id, 5);
        store.dispatch(setCurrentStep(WizardStepsEnum.COMPLETE));
    };

    (window as any).handleOnError = () => {
        popupManager.show({
            type: PopupType.Info,
            title: translator.get('provisioning.popup.error.publication.title'),
            description: translator.get('provisioning.popup.error.publication.description'),
            maxWidth: 'lg',
            stylingProp: 'popup-display',
            ctas: {
                primary: {
                    label: translator.get('popup.menu.ok.title'),
                    className: 'popup-accept-button',
                    cta: async () => {
                        popupManager.hide();
                        navigationManager.navigateTo(`record/${recording.id}` as Paths);
                    },
                },
            },
        });
    };

    (window as any).handleOnCancel = () => {
        navigationManager.navigateTo(`record/${recording.id}` as Paths);
    };

    useEffect(() => {
        const payload = {
            title: `Recording-${recording.catalogueNumber}`,
            recordist: KeycloakUtil.getName(),
            genus: recording.genus,
            species: recording.species,
            family: recording.family,
            order: recording.order,
            commonName: recording.commonName,
            scientificName: recording.scientificName,
            location: {
                country: recording.country,
                latitude: recording.location?.latitude,
                longitude: recording.location?.longitude,
                radius: recording.location?.radius || 0,
            },
            recordingDevice: recording.recordingDevice,
            microphone: recording.microphone,
            valuationDate: new Date(recording.detectionAt!).toISOString(),
            catalogueNumber: recording.catalogueNumber,
            audioInfo: {
                sampleRate: recording.metadataML?.sampleRate,
                sampleNumber: recording.metadataML?.sampleNumber,
                bitsPerSample: recording.metadataML?.bitrate,
                channels: recording.metadataML?.channels,
                encoding: recording.metadataML?.encoding,
                fileType: recording.metadataML?.fileType,
                length: moment.utc(Number(recording.metadataML?.length) * 1000).format('HH:mm:ss'),
            },
            amplitude: {
                RMS: recording.amplitudeML?.rms,
                max: recording.amplitudeML?.max,
                mean: recording.amplitudeML?.mean,
                min: recording.amplitudeML?.min,
                peak: recording.amplitudeML?.peak,
                std: recording.amplitudeML?.std,
            },
        };

        const encodedPayload = encodeURI(JSON.stringify(payload));
        const encodedUrl = encodeURI('/geonetwork/srv/eng/catalog.edit#/create-external?');
        const templateId = document.querySelector('script[id="injected-integration-belinda-audio-template-id"]')?.textContent!.toString();
        const encodedQueryParams = encodeURI(`embedder=isacco&embedderid=${recording?.id}&belinda_template_id=${templateId}&isaccoPayload=`);
        const url = `${iframeURL}/geonetwork/signin?redirectUrl=${encodedUrl}${encodedQueryParams}${encodedPayload}`;
        setUrl(url);
    });

    return (
        <>
            <div className='provisioning-ide-component'>{url && <iframe src={url} style={{ width: 'inherit', height: 'inherit' }} />}</div>

            <div className='col-12 d-flex position-button-metadata'>
                <Button identifier='wizard.general.data.skip' variant='secondary' onClick={handleSkipClick} />
                {/* <Button identifier='wizard.general.data.next' variant='primary' disabled={true} onClick={() => store.dispatch(setCurrentStep(WizardStepsEnum.COMPLETE))} /> */}
            </div>
        </>
    );
};
