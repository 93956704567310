import { Accordion } from '@features/_shared/components/Accordion/accordion.component';
import Breadcrumb from '@features/_shared/components/Breadcrumb/breadcrumb.component';
import { Button } from '@features/_shared/components/Button/button.component';
import { TranslatorManager } from '@managers/TranslatorManager';
import React from 'react';

export const ApiView: React.FC = () => {
    const translator = TranslatorManager.getInstance();

    return (
        <>
            <div className='container-fluid'>
                <div className='row header-title-container pb-0'>
                    <p className='header-title col-12 mb-4'>{translator.get('api.view.title')}</p>
                    <Breadcrumb secondaryPages={[translator.get('api.breadcrumb.secondary.item')]} />
                </div>
            </div>
            <div className=' row d-flex justify-content-evenly align-items-center mt-5 px-3 mx-0'>
                <div className='col-md-12 col-lg-6 mb-3'>
                    <Accordion identifier='api.application' id='applicationApi' isOpened={true}>
                        <div className='d-flex flex-column justify-content-between' style={{ minHeight: 180 }}>
                            <span>{translator.get('api.view.application.description')}</span>
                            <div className='d-flex justify-content-end'>
                                <Button
                                    variant='primary'
                                    identifier='common.go'
                                    onClick={() =>
                                        window.open(`${window.location.origin}/app/services/api/swagger-ui/index.html?configUrl=/app/services/api/json/swagger-config#/`, '_blank')
                                    }
                                />
                            </div>
                        </div>
                    </Accordion>
                </div>
                <div className='col-md-12 col-lg-6 mb-3'>
                    <Accordion identifier='api.ml' id='mlApi' isOpened={true}>
                        <div className='d-flex flex-column justify-content-between' style={{ height: 180 }}>
                            <div>
                                {translator.get('api.view.ml.description')}
                                <ul>
                                    <li>{translator.get('api.view.ml.description.li.one')}</li>
                                    <li>{translator.get('api.view.ml.description.li.two')}</li>
                                    <li>{translator.get('api.view.ml.description.li.three')}</li>
                                </ul>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <Button
                                    variant='primary'
                                    identifier='common.go'
                                    onClick={() =>
                                        window.open(
                                            `${window.location.origin}/app/services/api/swagger-ui/index.html?configUrl=/app/services/api/json/swagger-config#/machine-learning-controller`,
                                            '_blank',
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </Accordion>
                </div>
            </div>
        </>
    );
};
