import { Recording } from '@api/entities';
import { AudioPlayerComponent } from '@features/_shared/components/AudioPlayer/audio.player';
import Breadcrumb from '@features/_shared/components/Breadcrumb/breadcrumb.component';
import { CatalogueManager } from '@managers/catalogue.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import { RootState, store } from '@store/configure';
import { useStoreSelector } from '@store/hook';
import React, { useEffect } from 'react';
import { ActionButton } from '../components/action.button.component';
import { CatalogueDetailHeader } from '../components/catalogue.detail. header.component';
import { DataSectionComponent } from '../components/data.section.component';
import { DisplayModeButton } from '../components/display.mode.component';
import { ImageSectionComponent } from '../components/image.section.component';
import { DisplayModeEnum, setDisplayMode } from '../state/catalogueSlice';

export const RecordDetailView: React.FC = () => {
    const catalogueManager = CatalogueManager.getInstance();
    const queueManager = QueueManager.getInstance();
    const translator = TranslatorManager.getInstance();
    const record: Recording = useStoreSelector((state: RootState) => state.reducer.catalogue.selectedRecording);
    const displayMode: DisplayModeEnum = useStoreSelector((state: RootState) => state.reducer.catalogue.displayMode);

    useEffect(() => {
        (async () => {
            if (record.id === window.location.href.split('record/')[1]) {
                record.id && (await queueManager.queue(QueueKeyType.DEFAULT, catalogueManager.getTrack(record.id)));
            } else {
                const recordId = window.location.href.split('record/')[1];
                recordId && (await queueManager.queue(QueueKeyType.DEFAULT, catalogueManager.getUpdatedRecording(recordId)));
                recordId && (await queueManager.queue(QueueKeyType.DEFAULT, catalogueManager.getTrack(recordId)));
            }
        })();

        return () => {
            store.dispatch(setDisplayMode(DisplayModeEnum.SIMPLIFIED));
        };
    }, []);

    return (
        <>
            <div className='header-title-container pb-0'>
                <p className='header-title col-12 mb-4 mt-0'>{`Record ${record?.catalogueNumber}`}</p>
                <Breadcrumb secondaryPages={[translator.get('record.detail.breadcrumb.secondary.item')]} />
            </div>
            <CatalogueDetailHeader record={record} identifier={'record.detail.header'} />
            <div className='container-fluid'>
                <div className='d-flex flex-row mt-4'>
                    <div className='col-12 d-flex justify-content-end mb-4 pe-4 gap-3'>
                        <ActionButton isCompact={false} recordId={record?.id} isRecordConfidential={record.confidential} />
                        <DisplayModeButton identifier='record.detail.display.mode' currentMode={displayMode} />
                    </div>
                </div>
            </div>
            <div className='col-12 pe-4 ps-4'>
                <div
                    className=' d-flex col pe-4 ps-4 mb-4 py-3'
                    //style={{ boxShadow: displayMode === DisplayModeEnum.SIMPLIFIED ? 'none' : '7px 7px 12px 0px rgba(177,177,177,0.87)' }}
                >
                    {/* <ImageSectionComponent displayMode={displayMode} scientificName={record.scientificName} /> */}
                    <div className='col-12 align-self-center' style={{ boxShadow: '7px 7px 12px 0px rgba(177,177,177,0.87)' }}>
                        <div className='m-3'>
                            <h5 style={{ color: '#009138' }}>{record.scientificName ? record.scientificName : 'My recording'}</h5>
                            <AudioPlayerComponent recordId={record?.id as string} autoPlay={false} />
                        </div>
                    </div>
                    {/* {displayMode === DisplayModeEnum.ADVANCED && (
                        <div>
                            <hr className='stepper-divider-confirmed'></hr>
                            <AudioPlayerComponent recordId={record.id as string} autoPlay={false} />
                        </div>
                    )} */}
                </div>
            </div>
            <div className='col-12 pe-4 ps-4'>
                <div className='accordionContainer'>
                    <DataSectionComponent displayMode={displayMode} record={record} isAnimalDataEditable={true} />
                </div>
            </div>
        </>
    );
};
