import { setMetrics, setRecordings } from '@features/home/state/home.slice';
import { store } from '@store/configure';
import { HomepageApiService, IHomepageApiService } from '../services/homepage/homepage.api.service';
export interface IHomepageManagerProps {
    getMetrics(): Promise<void>;
    getLatestRecords(): Promise<void>;
}

export class HomepageManager implements IHomepageManagerProps {
    private static instance: HomepageManager;
    private homepageService: IHomepageApiService;

    private constructor() {
        this.homepageService = HomepageApiService.getInstance(process.env.REACT_APP_MICROSERVICE_API_BASEPATH!);
    }

    static getInstance() {
        if (HomepageManager.instance == null) {
            HomepageManager.instance = new HomepageManager();
        }
        return HomepageManager.instance;
    }

    async getMetrics(): Promise<void> {
        const response = await this.homepageService.getMetrics();
        store.dispatch(setMetrics(response));
    }
    async getLatestRecords(): Promise<void> {
        const response = await this.homepageService.getLatestRecords();
        store.dispatch(setRecordings(response.recordings));
    }
}
