import { KeyConfigurationControllerApi } from '@api/api';

export interface IBackofficeApiClient {
    configurator: KeyConfigurationControllerApi;
}

export class BackofficeApiClients {
    private static instance: IBackofficeApiClient;

    static getInstance(basePath: string): IBackofficeApiClient {
        if (BackofficeApiClients.instance == null) {
            BackofficeApiClients.instance = {
                configurator: new KeyConfigurationControllerApi(undefined, basePath),
            };
        }
        return BackofficeApiClients.instance;
    }
}
