import { TextField } from '@features/_shared/components/TextField';
import { FormManager } from '@managers/form.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import { StyledTooltip } from '@features/_shared/components/Tooltip/Tooltip';
import { FormHelperUtil } from '@utils/form.helper.util';
import React, { useContext, useState } from 'react';
import { IntlContext } from 'react-intl';
import { WizardGeneralDataFormData } from '../../beans/wizard.general.data.form.data';
import './wizard.general.data.view.style.scss';
import { MapComponent } from '@features/_shared/components/Map/map.component';
import { Button } from '@features/_shared/components/Button/button.component';
import { store } from '@store/configure';
import { WizardStepsEnum } from '@features/wizard/constants/constants';
import { GeneralData, saveCoordinates, saveValue, setCurrentStep } from '@features/wizard/state/wizard.slice';
import { useStoreSelector } from '@store/hook';
import { Calendar } from '@features/_shared/components/DatePicker/datepicker.component';
import { Controller } from 'react-hook-form';
import { LatLng } from 'leaflet';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';

export const WizardGeneralDataView: React.FC = () => {
    const translator = TranslatorManager.getInstance();
    const [radius, setRadius] = useState<number | undefined>(undefined);

    const lat: number = useStoreSelector(state => state.reducer.wizard.generalData.location?.latitude);
    const lng: number = useStoreSelector(state => state.reducer.wizard.generalData.location?.longitude);
    const generalData: GeneralData = useStoreSelector(state => state.reducer.wizard.generalData);

    const intl = useContext(IntlContext);
    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator('wizard.general.data', intl as any);

    const onSubmit = async () => {
        const isFormValid = await trigger();
        if (isFormValid) {
            store.dispatch(setCurrentStep(WizardStepsEnum.AUDIO_DATA));
        }
    };

    const handleOnBlur = (key: string, value: string | number | boolean) => {
        if (key === 'latitude' || key === 'longitude' || key === 'radius') {
            !errors[key] && store.dispatch(saveCoordinates({ key, value }));
        } else {
            store.dispatch(saveValue({ key, value }));
        }
    };

    const handleLocationChange = (coordinates: LatLng) => {
        setValue('latitude', coordinates.lat);
        setValue('longitude', coordinates.lng);
    };

    const { errors, register, trigger, getValues, control, setValue } = FormManager.getInstance().buildFormProxy<WizardGeneralDataFormData>(
        WizardGeneralDataFormData,
        onSubmit,
        {
            country: generalData.country,
            microphone: generalData.microphone,
            radius: generalData.location?.radius,
            recordingDevice: generalData.recordingDevice,
            latitude: lat ? lat.toString() : '',
            longitude: lng ? lng.toString() : '',
            detectionAt: generalData.detectionAt ?? new Date(),
        },
        'onChange',
    );

    return (
        <div className='container-fluid px-4'>
            {/* TODO: to be democked */}
            <div className='row home-title-container mb-5'>
                <h5 className='title-description'>{translator.get('wizard.view.title.description')}</h5>
            </div>

            <div className='col d-flex justify-content-end gap-2 mb-4'>
                {(KeycloakUtil.hasRole([KeycloakRoleEnum.reviewer]) || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) && (
                    <div className='form-check' style={{ marginLeft: 0 }}>
                        <input
                            id='training'
                            type='checkbox'
                            {...register('training')}
                            className='focus--mouse'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnBlur(e.target.name, e.target.checked)}
                        />
                        <label className='label-color d-flex flex-row align-items-center' htmlFor='training'>
                            {translator.get('wizard.general.data.training.set.label')}
                        </label>
                    </div>
                )}
            </div>

            <div className='row'>
                <div className='col-12 row'>
                    <div className='col-lg-7 col-md-12  mb-5' style={{ height: 425 }}>
                        <MapComponent radius={radius} latitude={lat} longitude={lng} onLocationChange={handleLocationChange} origin='WIZARD' />
                    </div>

                    <div className='col-lg-5 col-md-12'>
                        <div className='form-group'>
                            <label htmlFor='country' className='active d-flex flex-row align-items-center label-color'>
                                {translator.get('wizard.general.data.country.field.label')}
                                <p className='requiredSign'>*</p>
                                <StyledTooltip trigger='hover' position='right' labelKey={translator.get('wizard.general.data.country.field.tooltip')}>
                                    <div className='tooltip-icon-container'>?</div>
                                </StyledTooltip>
                            </label>
                            <TextField
                                type='text'
                                placeholder={translator.get('wizard.general.data.country.field.placeholder')}
                                id='country'
                                {...register('country')}
                                error={errors.country}
                                errorMessage={errors.country && getMessageByFieldAndError('country', errors)}
                                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e.target.name, e.target.value)}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor='latitude' className='active d-flex flex-row align-items-center label-color'>
                                {translator.get('wizard.general.data.latitude.field.label')}
                                <p className='requiredSign'>*</p>
                                <StyledTooltip trigger='hover' position='right' labelKey={translator.get('wizard.general.data.latitude.field.tooltip')}>
                                    <div className='tooltip-icon-container'>?</div>
                                </StyledTooltip>
                            </label>
                            <TextField
                                //type='number'
                                placeholder={translator.get('wizard.general.data.latitude.field.placeholder')}
                                id='latitude'
                                {...register('latitude')}
                                onKeyDown={evt => ['e', 'E'].includes(evt.key) && evt.preventDefault()}
                                error={errors.latitude}
                                errorMessage={errors.latitude && getMessageByFieldAndError('latitude', errors)}
                                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
                                    handleOnBlur(e.target.name, e.target.value);
                                }}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor='longitude' className='active d-flex flex-row align-items-center label-color'>
                                {translator.get('wizard.general.data.longitude.field.label')}
                                <p className='requiredSign'>*</p>
                                <StyledTooltip trigger='hover' position='right' labelKey={translator.get('wizard.general.data.longitude.field.tooltip')}>
                                    <div className='tooltip-icon-container'>?</div>
                                </StyledTooltip>
                            </label>
                            <TextField
                                placeholder={translator.get('wizard.general.data.longitude.field.placeholder')}
                                id='longitude'
                                {...register('longitude')}
                                onKeyDown={evt => ['e', 'E'].includes(evt.key) && evt.preventDefault()}
                                error={errors.longitude}
                                errorMessage={errors.longitude && getMessageByFieldAndError('longitude', errors)}
                                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
                                    //store.dispatch(setCoordinates(new LatLng(lat, Number(e.target.value))));
                                    handleOnBlur(e.target.name, e.target.value);
                                }}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor='radius' className='active d-flex flex-row align-items-center label-color'>
                                {translator.get('wizard.general.data.radius.field.label')}
                                <StyledTooltip trigger='hover' position='right' labelKey={translator.get('wizard.general.data.radius.field.tooltip')}>
                                    <div className='tooltip-icon-container'>?</div>
                                </StyledTooltip>
                            </label>
                            <TextField
                                type='number'
                                placeholder={translator.get('wizard.general.data.radius.field.placeholder')}
                                id='radius'
                                min='0'
                                {...register('radius')}
                                onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                                error={errors.radius}
                                errorMessage={errors.radius && getMessageByFieldAndError('radius', errors)}
                                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => {
                                    setRadius(Number(e.target.value));
                                    handleOnBlur(e.target.name, e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className='col-12 mb-4'>
                    <div className='form-group'>
                        <label htmlFor='detectionAt' className='active d-flex flex-row align-items-center label-color'>
                            {translator.get('wizard.general.data.detectionAt.field.label')}
                            <p className='requiredSign'>*</p>
                            <StyledTooltip trigger='hover' position='right' labelKey={translator.get('wizard.general.data.date.field.tooltip')}>
                                <div className='tooltip-icon-container'>?</div>
                            </StyledTooltip>
                        </label>
                        <Controller
                            name='detectionAt'
                            control={control as any}
                            render={({ field: { onChange } }) => (
                                <Calendar
                                    {...register('detectionAt')}
                                    value={getValues('detectionAt')}
                                    maxDate={new Date()}
                                    identifier='wizard.general.data.date'
                                    onChange={(e: any) => {
                                        onChange(e);
                                        handleOnBlur('detectionAt', e.toISOString());
                                    }}
                                />
                            )}
                        />
                    </div>

                    <div className='col'>
                        <div className='form-group'>
                            <label htmlFor='recordingDevice' className='active d-flex flex-row align-items-center label-color'>
                                {translator.get('wizard.general.data.recordingDevice.field.label')}
                                <p className='requiredSign'>*</p>
                                <StyledTooltip trigger='hover' position='right' labelKey={translator.get('wizard.general.data.recordingDevice.field.tooltip')}>
                                    <div className='tooltip-icon-container'>?</div>
                                </StyledTooltip>
                            </label>
                            <TextField
                                type='text'
                                placeholder={translator.get('wizard.general.data.recordingDevice.field.placeholder')}
                                id='recordingDevice'
                                {...register('recordingDevice')}
                                error={errors.recordingDevice}
                                errorMessage={errors.recordingDevice && getMessageByFieldAndError('recordingDevice', errors)}
                                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e.target.name, e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col'>
                        <div className='form-group'>
                            <label htmlFor='microphone' className='active d-flex flex-row align-items-center label-color'>
                                {translator.get('wizard.general.data.microphone.field.label')}
                                <p className='requiredSign'>*</p>
                                <StyledTooltip trigger='hover' position='right' labelKey={translator.get('wizard.general.data.microphone.field.tooltip')}>
                                    <div className='tooltip-icon-container'>?</div>
                                </StyledTooltip>
                            </label>
                            <TextField
                                type='text'
                                placeholder={translator.get('wizard.general.data.microphone.field.placeholder')}
                                id='microphone'
                                {...register('microphone')}
                                error={errors.microphone}
                                errorMessage={errors.microphone && getMessageByFieldAndError('microphone', errors)}
                                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e.target.name, e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className='col d-flex justify-content-end mt-5 mb-4'>
                    <Button identifier='wizard.general.data.next' variant='primary' onClick={onSubmit} />
                </div>
            </div>
        </div>
    );
};
