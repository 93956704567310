/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Recording } from '../entities';
// @ts-ignore
import { RequestRecordingSearch } from '../entities';
// @ts-ignore
import { ResponseAudioMetadataRecordingGet } from '../entities';
// @ts-ignore
import { ResponseInternalRecordingGet } from '../entities';
// @ts-ignore
import { ResponseRecordingSearch } from '../entities';
/**
 * CatalogueControllerApi - axios parameter creator
 * @export
 */
export const CatalogueControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFilesAsZip: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('downloadFilesAsZip', 'uuid', uuid);
            const localVarPath = `/catalogue/download/zip/{uuid}`.replace(`{${'uuid'}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve recording internal data (data inserted by the recordist).
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalRecord: async (idRecording: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRecording' is not null or undefined
            assertParamExists('getInternalRecord', 'idRecording', idRecording);
            const localVarPath = `/catalogue/{idRecording}/data`.replace(`{${'idRecording'}}`, encodeURIComponent(String(idRecording)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve recording audio analytics (metadata generated by machine learning processing).
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetadataRecording: async (idRecording: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRecording' is not null or undefined
            assertParamExists('getMetadataRecording', 'idRecording', idRecording);
            const localVarPath = `/catalogue/{idRecording}/audio/data`.replace(`{${'idRecording'}}`, encodeURIComponent(String(idRecording)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all recording information, including internal data, audio metadata, external metadata and machine learning optional data.
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecording: async (idRecording: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRecording' is not null or undefined
            assertParamExists('getRecording', 'idRecording', idRecording);
            const localVarPath = `/catalogue/{idRecording}`.replace(`{${'idRecording'}}`, encodeURIComponent(String(idRecording)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the audio registration to be played.
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrack1: async (idRecording: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRecording' is not null or undefined
            assertParamExists('getTrack1', 'idRecording', idRecording);
            const localVarPath = `/catalogue/{idRecording}/audio/stream`.replace(`{${'idRecording'}}`, encodeURIComponent(String(idRecording)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieve a list of recordings matching sorting, filtering and pagination constraints
         * @param {RequestRecordingSearch} requestRecordingSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (requestRecordingSearch: RequestRecordingSearch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestRecordingSearch' is not null or undefined
            assertParamExists('search', 'requestRecordingSearch', requestRecordingSearch);
            const localVarPath = `/catalogue/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestRecordingSearch, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * CatalogueControllerApi - functional programming interface
 * @export
 */
export const CatalogueControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = CatalogueControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFilesAsZip(uuid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFilesAsZip(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve recording internal data (data inserted by the recordist).
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInternalRecord(
            idRecording: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseInternalRecordingGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInternalRecord(idRecording, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve recording audio analytics (metadata generated by machine learning processing).
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetadataRecording(
            idRecording: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAudioMetadataRecordingGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetadataRecording(idRecording, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all recording information, including internal data, audio metadata, external metadata and machine learning optional data.
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecording(idRecording: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecording(idRecording, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the audio registration to be played.
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrack1(idRecording: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrack1(idRecording, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * retrieve a list of recordings matching sorting, filtering and pagination constraints
         * @param {RequestRecordingSearch} requestRecordingSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(
            requestRecordingSearch: RequestRecordingSearch,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseRecordingSearch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(requestRecordingSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * CatalogueControllerApi - factory interface
 * @export
 */
export const CatalogueControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CatalogueControllerApiFp(configuration);
    return {
        /**
         *
         * @param {string} uuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFilesAsZip(uuid: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadFilesAsZip(uuid, options).then(request => request(axios, basePath));
        },
        /**
         * Retrieve recording internal data (data inserted by the recordist).
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInternalRecord(idRecording: string, options?: any): AxiosPromise<ResponseInternalRecordingGet> {
            return localVarFp.getInternalRecord(idRecording, options).then(request => request(axios, basePath));
        },
        /**
         * Retrieve recording audio analytics (metadata generated by machine learning processing).
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetadataRecording(idRecording: string, options?: any): AxiosPromise<ResponseAudioMetadataRecordingGet> {
            return localVarFp.getMetadataRecording(idRecording, options).then(request => request(axios, basePath));
        },
        /**
         * Retrieve all recording information, including internal data, audio metadata, external metadata and machine learning optional data.
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecording(idRecording: string, options?: any): AxiosPromise<Recording> {
            return localVarFp.getRecording(idRecording, options).then(request => request(axios, basePath));
        },
        /**
         * Retrieves the audio registration to be played.
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrack1(idRecording: string, options?: any): AxiosPromise<File> {
            return localVarFp.getTrack1(idRecording, options).then(request => request(axios, basePath));
        },
        /**
         * retrieve a list of recordings matching sorting, filtering and pagination constraints
         * @param {RequestRecordingSearch} requestRecordingSearch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(requestRecordingSearch: RequestRecordingSearch, options?: any): AxiosPromise<ResponseRecordingSearch> {
            return localVarFp.search(requestRecordingSearch, options).then(request => request(axios, basePath));
        },
    };
};

/**
 * CatalogueControllerApi - interface
 * @export
 * @interface CatalogueControllerApi
 */
export interface CatalogueControllerApiInterface {
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueControllerApiInterface
     */
    downloadFilesAsZip(uuid: string, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * Retrieve recording internal data (data inserted by the recordist).
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueControllerApiInterface
     */
    getInternalRecord(idRecording: string, options?: AxiosRequestConfig): AxiosPromise<ResponseInternalRecordingGet>;

    /**
     * Retrieve recording audio analytics (metadata generated by machine learning processing).
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueControllerApiInterface
     */
    getMetadataRecording(idRecording: string, options?: AxiosRequestConfig): AxiosPromise<ResponseAudioMetadataRecordingGet>;

    /**
     * Retrieve all recording information, including internal data, audio metadata, external metadata and machine learning optional data.
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueControllerApiInterface
     */
    getRecording(idRecording: string, options?: AxiosRequestConfig): AxiosPromise<Recording>;

    /**
     * Retrieves the audio registration to be played.
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueControllerApiInterface
     */
    getTrack1(idRecording: string, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * retrieve a list of recordings matching sorting, filtering and pagination constraints
     * @param {RequestRecordingSearch} requestRecordingSearch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueControllerApiInterface
     */
    search(requestRecordingSearch: RequestRecordingSearch, options?: AxiosRequestConfig): AxiosPromise<ResponseRecordingSearch>;
}

/**
 * CatalogueControllerApi - object-oriented interface
 * @export
 * @class CatalogueControllerApi
 * @extends {BaseAPI}
 */
export class CatalogueControllerApi extends BaseAPI implements CatalogueControllerApiInterface {
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueControllerApi
     */
    public downloadFilesAsZip(uuid: string, options?: AxiosRequestConfig) {
        return CatalogueControllerApiFp(this.configuration)
            .downloadFilesAsZip(uuid, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Retrieve recording internal data (data inserted by the recordist).
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueControllerApi
     */
    public getInternalRecord(idRecording: string, options?: AxiosRequestConfig) {
        return CatalogueControllerApiFp(this.configuration)
            .getInternalRecord(idRecording, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Retrieve recording audio analytics (metadata generated by machine learning processing).
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueControllerApi
     */
    public getMetadataRecording(idRecording: string, options?: AxiosRequestConfig) {
        return CatalogueControllerApiFp(this.configuration)
            .getMetadataRecording(idRecording, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all recording information, including internal data, audio metadata, external metadata and machine learning optional data.
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueControllerApi
     */
    public getRecording(idRecording: string, options?: AxiosRequestConfig) {
        return CatalogueControllerApiFp(this.configuration)
            .getRecording(idRecording, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the audio registration to be played.
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueControllerApi
     */
    public getTrack1(idRecording: string, options?: AxiosRequestConfig) {
        return CatalogueControllerApiFp(this.configuration)
            .getTrack1(idRecording, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * retrieve a list of recordings matching sorting, filtering and pagination constraints
     * @param {RequestRecordingSearch} requestRecordingSearch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CatalogueControllerApi
     */
    public search(requestRecordingSearch: RequestRecordingSearch, options?: AxiosRequestConfig) {
        return CatalogueControllerApiFp(this.configuration)
            .search(requestRecordingSearch, options)
            .then(request => request(this.axios, this.basePath));
    }
}
