import { ReactComponent as GoPrevious } from '@assets/svg/arrows-left.svg';
import { ReactComponent as GoNext } from '@assets/svg/arrows-right.svg';
import { PaginationEnum } from './enums/PaginationEnum';
import './style/paginator.style.scss';

interface PaginatorProps {
    onChange?: (page: number, size: number, total: number) => Promise<void>;
    page: number;
    size: number;
    total: number;
    sizes?: Array<number>;
}

const Paginator: React.FC<PaginatorProps> = ({ page, size, total, onChange }) => {
    const handleOnEdgesClick = (type: PaginationEnum) => {
        switch (type) {
            case PaginationEnum.PREV:
                if (page > 0) {
                    onChange?.(page - 1, size, total);
                }
                break;
            case PaginationEnum.NEXT:
                if (page < Math.ceil(total / size) - 1) {
                    onChange?.(page + 1, size, total);
                }
                break;
        }
    };

    const totalPages = Math.ceil(total / size);

    const pageRange =
        totalPages <= 5
            ? Array.from({ length: totalPages }, (_, i) => i)
            : page < 2
            ? [0, 1, 2, 3, 'ellipsis', totalPages - 1]
            : page >= totalPages - 2
            ? [0, 'ellipsis', totalPages - 3, totalPages - 2, totalPages - 1]
            : [0, 'ellipsis', page - 1, page, page + 1, 'ellipsis', totalPages - 1];

    const handleOnPageClick = (value: number) => {
        value !== page && onChange?.(value, size, total);
    };

    return (
        <>
            {total > 0 && (
                <div id='paginator'>
                    <nav className='pagination-wrapper justify-content-center' aria-label='Navigazione centrata'>
                        <ul className='pagination'>
                            <li className={`page-item ${page === 0 ? 'disabled' : ''}`} onClick={() => handleOnEdgesClick(PaginationEnum.PREV)}>
                                <a className='page-link disabled' aria-hidden='true'>
                                    <GoPrevious className='previous-next-container' />
                                    <span className='visually-hidden'>Pagina precedente</span>
                                </a>
                            </li>
                            {pageRange.map((pageNumber, index) => (
                                <li
                                    key={index}
                                    className={`page-item ${pageNumber === 'ellipsis' ? 'ellipsis' : pageNumber === page ? 'active' : ''}`}
                                    onClick={() => pageNumber !== 'ellipsis' && handleOnPageClick(pageNumber as number)}>
                                    {pageNumber === 'ellipsis' ? (
                                        '...'
                                    ) : (
                                        <a className='page-link' aria-current='page'>
                                            {(pageNumber as number) + 1}
                                        </a>
                                    )}
                                </li>
                            ))}
                            <li className={`page-item ${page === totalPages - 1 ? 'disabled' : ''}`}>
                                <a onClick={() => handleOnEdgesClick(PaginationEnum.NEXT)} className='page-link'>
                                    <span className='visually-hidden'>Pagina successiva</span>
                                    <GoNext className='previous-next-container' />
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
        </>
    );
};

export default Paginator;
