import Breadcrumb from '@components/Breadcrumb/breadcrumb.component';
import { TranslatorManager } from '@managers/TranslatorManager';
import { NavigationManager } from '@managers/navigation.manager';
import { PollingUtil } from '@utils/polling.util';
import React, { useEffect } from 'react';
import '../styles/faqs.view.scss';

export const FaqsView: React.FC = () => {
    const translatorManager = TranslatorManager.getInstance();

    useEffect(() => {
        const iframe = document.querySelector('#faqs-iframe');
        const validateFaqs = () => NavigationManager.getInstance().getCurrentRoute() === '/faqs';
        PollingUtil.register(
            'faqs',
            async () => {
                (iframe as any).style.height = (iframe as any)!.contentDocument.body.scrollHeight + 'px';
                (iframe as any).style.width = (iframe as any).contentDocument.body.scrollWidth + 'px';
            },
            validateFaqs,
            200,
        );
        return () => PollingUtil.unregister('faqs');
    });

    return (
        <div className='container-fluid' id='test'>
            <div className='row header-title-container pb-0'>
                <div className='start-page-top mb-4'>
                    <h3 className='header-title'>{translatorManager.get('faqs.view.title')}</h3>
                </div>
                <Breadcrumb secondaryPages={[translatorManager.get('faqs.breadcrumb.secondary.item')]} />
            </div>
            <div className='row faqs-main-container'>
                <div className='d-flex'>
                    <iframe
                        id='faqs-iframe'
                        src={`${window.location.origin}/faq/ui`}
                        frameBorder='0'
                        scrolling='no'
                        style={{ overflow: 'hidden', width: '100%', top: 0, left: 0, right: 0, bottom: 0, minHeight: 600 }}
                        width='100%'
                        height='fit-content'
                        title='faqs'
                    />
                </div>
            </div>
        </div>
    );
};
