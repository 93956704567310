import { Footer } from '@features/_shared/components/Footer';
import LoginHeader from '@features/_shared/components/Header/login.header.component';
import { Loader } from '@features/_shared/components/Loader';
import { NavBar } from '@features/_shared/components/Navbar';
import SideBar from '@features/_shared/components/Navbar/sidebar.component';
import Popup from '@features/_shared/components/Popup/popup.container';
import { NavigationManager } from '@managers/navigation.manager';
import { PRIVATE_ROUTES, PrivateRoutes } from '@routes';
import { useStoreSelector } from '@store/hook';
import KeycloakUtil from '@utils/keycloak.util';
import { PollingUtil } from '@utils/polling.util';
import React, { useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import './App.css';
import keycloak from './_shared/keyclock/keyclock';

export const App: React.FC = () => {
    const history = NavigationManager.getInstance().getHistory();
    const isLogged = KeycloakUtil.isLoggedIn();
    const isLoading = useStoreSelector(state => state.reducer.shared.loading.global);
    const isVisible = useStoreSelector(state => state.reducer.shared.popup.isVisible);

    const pollTokenUpdate = async () => {
        const validateToken = () => true;
        PollingUtil.register(
            'token',
            async () => {
                await keycloak.updateToken(5);
            },
            validateToken,
            10000,
        );
    };

    useEffect(() => {
        pollTokenUpdate();

        return () => PollingUtil.unregister('token');
    }, []);

    return (
        <>
            <Loader isLoading={!!isLoading} />
            <Popup isVisible={isVisible} />
            <div className='app-container d-flex flex-column justify-content-between'>
                <div className='d-flex flex-row'>
                    <div className='d-none d-lg-block'>
                        <SideBar isLogged={isLogged} />
                    </div>
                    <div className={`app-view-container sidepanel-space`}>
                        <div>
                            <div className='d-lg-none'>
                                <NavBar isLogged={isLogged} />
                            </div>
                            <LoginHeader />

                            <Router history={history}>
                                <Switch>
                                    <Route exact path='/' component={() => <Redirect to='/home' />} />
                                    {/* <Route path={PUBLIC_ROUTES.map(({ path }) => path)} component={PublicRoutes} /> */}
                                    <Route path={PRIVATE_ROUTES.map(({ path }) => path)} component={PrivateRoutes} />
                                </Switch>
                            </Router>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default App;
