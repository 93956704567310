import { TranslatorManager } from '@managers/TranslatorManager';
import './accordion.component.style.scss';

export interface IAccordionProps {
    identifier: string;
    id: string;
    isOpened?: boolean;
    children: JSX.Element;
}

export const Accordion: React.FC<IAccordionProps> = ({ identifier, id, isOpened = false, children }) => {
    const translator = TranslatorManager.getInstance();

    return (
        <div className='accordion accordion-background-active border bg-white' id='accordion'>
            <div className='accordion-item'>
                <h2 className='accordion-header ' id='heading1c'>
                    <button
                        className={`accordion-button border ${!isOpened && 'collapsed'}`}
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#${id}`}
                        aria-expanded={isOpened}
                        aria-controls={id}>
                        {translator.get(`${identifier}.accordion.header.button.text`)}
                    </button>
                </h2>
                <div id={id} className={`collapse size-tab ${isOpened && 'show'}`} role='region' aria-labelledby={id}>
                    <div className='accordion-body'>{children}</div>
                </div>
            </div>
        </div>
    );
};
