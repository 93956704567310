import { TranslatorManager } from '@managers/TranslatorManager';
import './stepper.component.style.scss';

export interface IStepperProps {
    identifier: string;
    steps: Array<string>;
    currentStep: string;
}

export const Stepper: React.FC<IStepperProps> = ({ steps, currentStep, identifier }) => {
    const translator = TranslatorManager.getInstance();

    return (
        <div className='mb-4'>
            <div className='col-12'>
                <div className='steppers d-flex justify-content-center'>
                    <ul className='steppers-header'>
                        {steps.map((step: string, index: number) => (
                            <>
                                <li
                                    key={index}
                                    className={`
                                                ${index < steps.indexOf(currentStep) && 'confirmed no-line d-none d-lg-flex'} 
                                                ${index <= steps.indexOf(currentStep) && 'active no-line'} 
                                                ${index > steps.indexOf(currentStep) && 'd-none d-lg-flex'}
                                                d-flex flex-xs-row flex-lg-column justify-content-center step-container align-items-center steppers-padding
                                                `}>
                                    <span className={`steppers-number ${index <= steps.indexOf(currentStep) && 'active no-line'} m-0 text-center`}>{index + 1}</span>
                                    <p className='ps-xs-3 ps-lg-0 mb-0 text-center text-nowrap'>{translator.get(`${identifier}.${step}.label`)}</p>
                                </li>
                                {index < steps.length - 1 && <hr className={`stepper-divider ${index < steps.indexOf(currentStep) && 'stepper-divider-confirmed'}`} />}
                            </>
                        ))}
                        <li className='steppers-index' aria-hidden='true'>
                            {steps.map((step, index) => (
                                <span className={`${step === currentStep && 'active'}`}>{index + 1}</span>
                            ))}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
