import { RecordingStatusEnum } from '@api/entities';

export class CatalogueFilterbarData {
    species: string;

    genus: string;

    location: string;

    recordist: string;

    status: RecordingStatusEnum;

    onlyMyRecords: boolean;
}
