import { TranslatorManager } from '@managers/TranslatorManager';
import React from 'react';
import { Button } from '../Button/button.component';

export interface IGraphProps {
    img: string;
    identifier?: string;
    isDownloadable?: boolean;
    onDownloadClick?: () => void;
}

export const Graph: React.FC<IGraphProps> = ({ img, identifier, onDownloadClick }) => {
    const translator = TranslatorManager.getInstance();

    return (
        <div className='mb-4'>
            {identifier && <h6 style={{ color: '#009138' }}>{translator.get(`${identifier}.title`)}</h6>}
            <img src={img} alt={identifier} style={{ maxWidth: 550, maxHeight: 550 }} />
            <div className='d-flex justify-content-end'>
                <Button variant='primary' identifier={'common.download'} onClick={onDownloadClick} />
            </div>
        </div>
    );
};
