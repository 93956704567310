import { sharedPersistedConfig, sharedSlice } from '@features/_shared/store/sharedSlices';
import { cataloguePersistedConfig, catalogueSlice } from '@features/catalogue/state/catalogueSlice';
import { homePersistedConfig, homeSlice } from '@features/home/state/home.slice';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { wizardPersistedConfig, wizardSlice } from '@features/wizard/state/wizard.slice';

export const rootReducer = combineReducers({
    shared: persistReducer(sharedPersistedConfig, sharedSlice.reducer),
    catalogue: persistReducer(cataloguePersistedConfig, catalogueSlice.reducer),
    home: persistReducer(homePersistedConfig, homeSlice.reducer),
    wizard: persistReducer(wizardPersistedConfig, wizardSlice.reducer),
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['catalogue', 'shared'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: {
        reducer: persistedReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type StoreDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
