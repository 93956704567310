import { resetFilter, setFilter, setPagination, setRecordingList, setSelectedRecord } from '@features/catalogue/state/catalogueSlice';
import { Paths } from '@routes';
import { store } from '@store/configure';
import { AxiosPromise } from 'axios';
import { RecordingSearchFilter, RequestRecordingSearch, ResponseAudioMetadataRecordingGet, ResponseInternalRecordingGet } from 'src/api/entities';
import { CatalogueApiService, ICatalogueApiService } from '../services/catalogue/catalogue.api.service';
import { NavigationManager } from './navigation.manager';

export interface ICatalogueManagerProps {
    getMetadataRecording(idrecord: string): Promise<ResponseAudioMetadataRecordingGet>;
    getInternalRecord(idrecord: string): Promise<ResponseInternalRecordingGet>;
    search(filters: RequestRecordingSearch): Promise<void>;
    getRecording(idrecord: string): Promise<void>;
    getTrack(idrecord: string): Promise<AxiosPromise<File>>;
    setFilters(key: keyof RecordingSearchFilter, value: string): void;
    resetFilters(): void;
    downloadFilesAsZip(uuid: string): Promise<AxiosPromise<File>>;
}

export class CatalogueManager implements ICatalogueManagerProps {
    private static instance: CatalogueManager;
    private catalogueService: ICatalogueApiService;
    private navigationManager: NavigationManager;

    private constructor() {
        this.catalogueService = CatalogueApiService.getInstance(process.env.REACT_APP_MICROSERVICE_API_BASEPATH!);
        this.navigationManager = NavigationManager.getInstance();
    }

    static getInstance() {
        if (CatalogueManager.instance == null) {
            CatalogueManager.instance = new CatalogueManager();
        }
        return CatalogueManager.instance;
    }

    async getMetadataRecording(idrecord: string): Promise<ResponseAudioMetadataRecordingGet> {
        const response = await this.catalogueService.getMetadataRecording(idrecord);
        return response;
    }

    async getInternalRecord(idrecord: string): Promise<ResponseInternalRecordingGet> {
        const response = await this.catalogueService.getInternalRecord(idrecord);
        return response;
    }

    async search(request: RequestRecordingSearch): Promise<void> {
        const response = await this.catalogueService.search({
            page: request?.page,
            size: request?.size,
            filter: {
                species: request.filter?.species,
                //country: request.filter?.country,
                genus: request.filter?.genus,
                recordist: request.filter?.recordist,
                status: request.filter?.status,
                onlyMyRecords: request.filter?.onlyMyRecords,
            },
            sort: request.sort,
        });
        store.dispatch(setRecordingList(response.recordings));
        store.dispatch(setPagination(response.page));
    }

    async getRecording(idrecord: string): Promise<void> {
        const response = await this.catalogueService.getRecording(idrecord);
        store.dispatch(setSelectedRecord(response));
        this.navigationManager.navigateTo(`record/${response.id}` as Paths);
    }

    async getUpdatedRecording(idrecord: string): Promise<void> {
        const response = await this.catalogueService.getRecording(idrecord);
        store.dispatch(setSelectedRecord(response));
    }

    async getTrack(idrecord: string): Promise<AxiosPromise<File>> {
        return await this.catalogueService.getTrack(idrecord);
    }

    async downloadFilesAsZip(uuid: string): Promise<AxiosPromise<File>> {
        return await this.catalogueService.downloadFilesAsZip(uuid);
    }

    setFilters(key: keyof RecordingSearchFilter, value: string | boolean | undefined): void {
        store.dispatch(setFilter({ key, value }));
    }

    resetFilters(): void {
        store.dispatch(resetFilter({}));
    }
}
