import { AmplitudeML, MetadataML, Recording } from '@api/entities';
import { TranslatorManager } from '@managers/TranslatorManager';
import moment from 'moment';

export const wizardSteps = ['generalData', 'audioData', 'recognition', 'summary', 'metadata', 'complete'];

export enum WizardStepsEnum {
    GENERAL_DATA = 'generalData',
    AUDIO_DATA = 'audioData',
    RECOGNITION = 'recognition',
    SUMMARY = 'summary',
    METADATA = 'metadata',
    COMPLETE = 'complete',
}

export enum ValidationMLEnum {
    UNSUPPORTED_FILE = 'unsupported_file',
    SUPPORTED_FILE = 'supported_file',
    UNSUPPORTED_FORMAT = 'unsupported_format',
    SUPPORTED_FORMAT = 'supported_format',
}

//Util that build the audio data table rows in the comparison view in Wizard
export const audioDataTableCompareRowBuilder = (item: Recording, comparisonRecording: Recording) => {
    const translator = TranslatorManager.getInstance();
    const filteredItem: Partial<typeof item.metadataML> =
        item.metadataML &&
        Object.entries(item.metadataML)
            .filter(([key]) => ['fileType', 'length', 'sampleRate', 'sampleNumber', 'bitrate', 'channels', 'encoding'].includes(key))
            .reduce((obj: any, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});
    const rows =
        filteredItem &&
        Object.entries(filteredItem).map(([key, value]) => {
            return {
                id: item.id,
                values: {
                    title: <span style={{ fontWeight: 'bold' }}>{translator.get(`catalogue.detail.sound.data.table.${key}.key`)}</span>,
                    value: key === 'length' ? <span>{value ? moment.utc(Number(value) * 1000).format('HH:mm:ss') : '-'}</span> : <span>{value ? value : '-'}</span>,
                    comparison:
                        key === 'length' ? (
                            <span>
                                {comparisonRecording.metadataML && comparisonRecording.metadataML[key as keyof MetadataML]
                                    ? moment.utc(Number(comparisonRecording.metadataML[key as keyof MetadataML]) * 1000).format('HH:mm:ss')
                                    : '-'}
                            </span>
                        ) : (
                            <span>
                                {comparisonRecording.metadataML && comparisonRecording.metadataML[key as keyof MetadataML]
                                    ? comparisonRecording.metadataML[key as keyof MetadataML]
                                    : '-'}
                            </span>
                        ),
                },
            };
        });
    return rows;
};

//Util that build the amplitude data table rows in the comparison view in Wizard
export const technicalDetailsAmplitudeCompareRowBuilder = (item: Recording, comparisonRecording: Recording) => {
    const translator = TranslatorManager.getInstance();
    const filteredItem: Partial<typeof item.amplitudeML> =
        item.amplitudeML &&
        Object.entries(item.amplitudeML)
            .filter(([key]) => ['rms', 'peak', 'max', 'min', 'mean', 'std'].includes(key))
            .reduce((obj: any, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});
    const rows =
        filteredItem &&
        Object.entries(filteredItem).map(([key, value]) => {
            return {
                id: item.id,
                values: {
                    title: <span style={{ fontWeight: 'bold' }}>{translator.get(`catalogue.detail.sound.data.table.${key}.key`)}</span>,
                    myrecord: <span>{value ? value : '-'}</span>,
                    comparison: (
                        <span>
                            {comparisonRecording.amplitudeML && comparisonRecording.amplitudeML[key as keyof AmplitudeML]
                                ? comparisonRecording.amplitudeML[key as keyof AmplitudeML]
                                : '-'}
                        </span>
                    ),
                },
            };
        });
    return rows;
};

//Util that build the animal data table rows in the comparison view in Wizard
export const animalDataRowBuilder = (item: Recording) => {
    return [
        {
            id: 'row1',
            values: {
                title: <span style={{ fontWeight: 'bold' }}>Order</span>,
                value: item?.order,
            },
        },
        {
            id: 'row2',
            values: {
                title: <span style={{ fontWeight: 'bold' }}>Family</span>,
                value: item?.family,
            },
        },
        {
            id: 'row3',
            values: {
                title: <span style={{ fontWeight: 'bold' }}>Genus</span>,
                value: item?.genus,
            },
        },
        {
            id: 'row4',
            values: {
                title: <span style={{ fontWeight: 'bold' }}>Species</span>,
                value: item?.species,
            },
        },
        {
            id: 'row5',
            values: {
                title: <span style={{ fontWeight: 'bold' }}>Scientific name</span>,
                value: item?.scientificName,
            },
        },
        {
            id: 'row6',
            values: {
                title: <span style={{ fontWeight: 'bold' }}>Common name</span>,
                value: item?.commonName,
            },
        },
    ];
};
