/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Recording } from '../entities';
// @ts-ignore
import { RequestRecordingCreate } from '../entities';
// @ts-ignore
import { RequestRecordingUpdate } from '../entities';
// @ts-ignore
import { ResponseBelindaRecordGet } from '../entities';
// @ts-ignore
import { ResponseMlAnalyticsResources } from '../entities';
// @ts-ignore
import { ResponseRecordingCreate } from '../entities';
// @ts-ignore
import { ResponseRecordingUpdate } from '../entities';
/**
 * WizardControllerApi - axios parameter creator
 * @export
 */
export const WizardControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Classify the chosen record against a selected classifier record
         * @param {string} recordId
         * @param {string} classifierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyRecording: async (recordId: string, classifierId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordId' is not null or undefined
            assertParamExists('classifyRecording', 'recordId', recordId);
            // verify required parameter 'classifierId' is not null or undefined
            assertParamExists('classifyRecording', 'classifierId', classifierId);
            const localVarPath = `/wizard/classify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (recordId !== undefined) {
                localVarQueryParameter['recordId'] = recordId;
            }

            if (classifierId !== undefined) {
                localVarQueryParameter['classifierId'] = classifierId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a new recording to the catalogue.
         * @param {RequestRecordingCreate} requestRecordingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecord: async (requestRecordingCreate: RequestRecordingCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestRecordingCreate' is not null or undefined
            assertParamExists('createRecord', 'requestRecordingCreate', requestRecordingCreate);
            const localVarPath = `/wizard/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestRecordingCreate, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecording: async (idRecording: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRecording' is not null or undefined
            assertParamExists('deleteRecording', 'idRecording', idRecording);
            const localVarPath = `/wizard/{idRecording}/delete`.replace(`{${'idRecording'}}`, encodeURIComponent(String(idRecording)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves audio recording metadata.
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudioData: async (idRecording: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRecording' is not null or undefined
            assertParamExists('getAudioData', 'idRecording', idRecording);
            const localVarPath = `/wizard/{idRecording}/audio/data`.replace(`{${'idRecording'}}`, encodeURIComponent(String(idRecording)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM'} analytic
         * @param {'JPEG' | 'CSV'} format
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {string} jwtHeader
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataFromJwtML: async (
            analytic: 'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM',
            format: 'JPEG' | 'CSV',
            jwtPayload: string,
            jwtSignature: string,
            jwtHeader: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'analytic' is not null or undefined
            assertParamExists('getDataFromJwtML', 'analytic', analytic);
            // verify required parameter 'format' is not null or undefined
            assertParamExists('getDataFromJwtML', 'format', format);
            // verify required parameter 'jwtPayload' is not null or undefined
            assertParamExists('getDataFromJwtML', 'jwtPayload', jwtPayload);
            // verify required parameter 'jwtSignature' is not null or undefined
            assertParamExists('getDataFromJwtML', 'jwtSignature', jwtSignature);
            // verify required parameter 'jwtHeader' is not null or undefined
            assertParamExists('getDataFromJwtML', 'jwtHeader', jwtHeader);
            const localVarPath = `/wizard/ml/{analytic}/{format}/{jwt_payload}/{jwt_signature}/{jwt_header}`
                .replace(`{${'analytic'}}`, encodeURIComponent(String(analytic)))
                .replace(`{${'format'}}`, encodeURIComponent(String(format)))
                .replace(`{${'jwt_payload'}}`, encodeURIComponent(String(jwtPayload)))
                .replace(`{${'jwt_signature'}}`, encodeURIComponent(String(jwtSignature)))
                .replace(`{${'jwt_header'}}`, encodeURIComponent(String(jwtHeader)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the audio registration to be played.
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrack: async (idRecording: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRecording' is not null or undefined
            assertParamExists('getTrack', 'idRecording', idRecording);
            const localVarPath = `/wizard/{idRecording}/audio/stream`.replace(`{${'idRecording'}}`, encodeURIComponent(String(idRecording)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} idRecording
         * @param {number} numberStep
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextStep: async (idRecording: string, numberStep: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRecording' is not null or undefined
            assertParamExists('nextStep', 'idRecording', idRecording);
            // verify required parameter 'numberStep' is not null or undefined
            assertParamExists('nextStep', 'numberStep', numberStep);
            const localVarPath = `/wizard/{idRecording}/nextstep/{numberStep}`
                .replace(`{${'idRecording'}}`, encodeURIComponent(String(idRecording)))
                .replace(`{${'numberStep'}}`, encodeURIComponent(String(numberStep)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves/Synchronizes metadata information from the metadata catalog.
         * @param {string} idrecord
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncMetadata: async (idrecord: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idrecord' is not null or undefined
            assertParamExists('syncMetadata', 'idrecord', idrecord);
            const localVarPath = `/wizard/{idrecord}/metadata/sync`.replace(`{${'idrecord'}}`, encodeURIComponent(String(idrecord)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the information of an existing record.
         * @param {string} idRecording
         * @param {RequestRecordingUpdate} requestRecordingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecord: async (idRecording: string, requestRecordingUpdate: RequestRecordingUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRecording' is not null or undefined
            assertParamExists('updateRecord', 'idRecording', idRecording);
            // verify required parameter 'requestRecordingUpdate' is not null or undefined
            assertParamExists('updateRecord', 'requestRecordingUpdate', requestRecordingUpdate);
            const localVarPath = `/wizard/{idRecording}/update`.replace(`{${'idRecording'}}`, encodeURIComponent(String(idRecording)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestRecordingUpdate, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload an image on an existing record.
         * @param {string} idRecording
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: async (idRecording: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRecording' is not null or undefined
            assertParamExists('uploadImage', 'idRecording', idRecording);
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadImage', 'file', file);
            const localVarPath = `/wizard/{idRecording}/upload`.replace(`{${'idRecording'}}`, encodeURIComponent(String(idRecording)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload the landscape image on an existing record.
         * @param {string} idRecording
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLandscape: async (idRecording: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRecording' is not null or undefined
            assertParamExists('uploadLandscape', 'idRecording', idRecording);
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadLandscape', 'file', file);
            const localVarPath = `/wizard/{idRecording}/landscape`.replace(`{${'idRecording'}}`, encodeURIComponent(String(idRecording)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * WizardControllerApi - functional programming interface
 * @export
 */
export const WizardControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = WizardControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Classify the chosen record against a selected classifier record
         * @param {string} recordId
         * @param {string} classifierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async classifyRecording(
            recordId: string,
            classifierId: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.classifyRecording(recordId, classifierId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a new recording to the catalogue.
         * @param {RequestRecordingCreate} requestRecordingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecord(
            requestRecordingCreate: RequestRecordingCreate,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseRecordingCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRecord(requestRecordingCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRecording(idRecording: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRecording(idRecording, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves audio recording metadata.
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAudioData(idRecording: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMlAnalyticsResources>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAudioData(idRecording, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM'} analytic
         * @param {'JPEG' | 'CSV'} format
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {string} jwtHeader
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataFromJwtML(
            analytic: 'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM',
            format: 'JPEG' | 'CSV',
            jwtPayload: string,
            jwtSignature: string,
            jwtHeader: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataFromJwtML(analytic, format, jwtPayload, jwtSignature, jwtHeader, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the audio registration to be played.
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrack(idRecording: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrack(idRecording, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} idRecording
         * @param {number} numberStep
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextStep(idRecording: string, numberStep: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recording>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nextStep(idRecording, numberStep, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves/Synchronizes metadata information from the metadata catalog.
         * @param {string} idrecord
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncMetadata(idrecord: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseBelindaRecordGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncMetadata(idrecord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the information of an existing record.
         * @param {string} idRecording
         * @param {RequestRecordingUpdate} requestRecordingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecord(
            idRecording: string,
            requestRecordingUpdate: RequestRecordingUpdate,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseRecordingUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRecord(idRecording, requestRecordingUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload an image on an existing record.
         * @param {string} idRecording
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImage(idRecording: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImage(idRecording, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload the landscape image on an existing record.
         * @param {string} idRecording
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLandscape(idRecording: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLandscape(idRecording, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * WizardControllerApi - factory interface
 * @export
 */
export const WizardControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WizardControllerApiFp(configuration);
    return {
        /**
         * Classify the chosen record against a selected classifier record
         * @param {string} recordId
         * @param {string} classifierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classifyRecording(recordId: string, classifierId: string, options?: any): AxiosPromise<Recording> {
            return localVarFp.classifyRecording(recordId, classifierId, options).then(request => request(axios, basePath));
        },
        /**
         * Add a new recording to the catalogue.
         * @param {RequestRecordingCreate} requestRecordingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecord(requestRecordingCreate: RequestRecordingCreate, options?: any): AxiosPromise<ResponseRecordingCreate> {
            return localVarFp.createRecord(requestRecordingCreate, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecording(idRecording: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteRecording(idRecording, options).then(request => request(axios, basePath));
        },
        /**
         * Retrieves audio recording metadata.
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAudioData(idRecording: string, options?: any): AxiosPromise<ResponseMlAnalyticsResources> {
            return localVarFp.getAudioData(idRecording, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM'} analytic
         * @param {'JPEG' | 'CSV'} format
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {string} jwtHeader
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataFromJwtML(
            analytic: 'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM',
            format: 'JPEG' | 'CSV',
            jwtPayload: string,
            jwtSignature: string,
            jwtHeader: string,
            options?: any,
        ): AxiosPromise<File> {
            return localVarFp.getDataFromJwtML(analytic, format, jwtPayload, jwtSignature, jwtHeader, options).then(request => request(axios, basePath));
        },
        /**
         * Retrieves the audio registration to be played.
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrack(idRecording: string, options?: any): AxiosPromise<File> {
            return localVarFp.getTrack(idRecording, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} idRecording
         * @param {number} numberStep
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextStep(idRecording: string, numberStep: number, options?: any): AxiosPromise<Recording> {
            return localVarFp.nextStep(idRecording, numberStep, options).then(request => request(axios, basePath));
        },
        /**
         * Retrieves/Synchronizes metadata information from the metadata catalog.
         * @param {string} idrecord
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncMetadata(idrecord: string, options?: any): AxiosPromise<ResponseBelindaRecordGet> {
            return localVarFp.syncMetadata(idrecord, options).then(request => request(axios, basePath));
        },
        /**
         * Updates the information of an existing record.
         * @param {string} idRecording
         * @param {RequestRecordingUpdate} requestRecordingUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecord(idRecording: string, requestRecordingUpdate: RequestRecordingUpdate, options?: any): AxiosPromise<ResponseRecordingUpdate> {
            return localVarFp.updateRecord(idRecording, requestRecordingUpdate, options).then(request => request(axios, basePath));
        },
        /**
         * Upload an image on an existing record.
         * @param {string} idRecording
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(idRecording: string, file: File, options?: any): AxiosPromise<string> {
            return localVarFp.uploadImage(idRecording, file, options).then(request => request(axios, basePath));
        },
        /**
         * Upload the landscape image on an existing record.
         * @param {string} idRecording
         * @param {File} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLandscape(idRecording: string, file: File, options?: any): AxiosPromise<string> {
            return localVarFp.uploadLandscape(idRecording, file, options).then(request => request(axios, basePath));
        },
    };
};

/**
 * WizardControllerApi - interface
 * @export
 * @interface WizardControllerApi
 */
export interface WizardControllerApiInterface {
    /**
     * Classify the chosen record against a selected classifier record
     * @param {string} recordId
     * @param {string} classifierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApiInterface
     */
    classifyRecording(recordId: string, classifierId: string, options?: AxiosRequestConfig): AxiosPromise<Recording>;

    /**
     * Add a new recording to the catalogue.
     * @param {RequestRecordingCreate} requestRecordingCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApiInterface
     */
    createRecord(requestRecordingCreate: RequestRecordingCreate, options?: AxiosRequestConfig): AxiosPromise<ResponseRecordingCreate>;

    /**
     *
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApiInterface
     */
    deleteRecording(idRecording: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Retrieves audio recording metadata.
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApiInterface
     */
    getAudioData(idRecording: string, options?: AxiosRequestConfig): AxiosPromise<ResponseMlAnalyticsResources>;

    /**
     *
     * @param {'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM'} analytic
     * @param {'JPEG' | 'CSV'} format
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {string} jwtHeader
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApiInterface
     */
    getDataFromJwtML(
        analytic: 'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM',
        format: 'JPEG' | 'CSV',
        jwtPayload: string,
        jwtSignature: string,
        jwtHeader: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<File>;

    /**
     * Retrieves the audio registration to be played.
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApiInterface
     */
    getTrack(idRecording: string, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     *
     * @param {string} idRecording
     * @param {number} numberStep
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApiInterface
     */
    nextStep(idRecording: string, numberStep: number, options?: AxiosRequestConfig): AxiosPromise<Recording>;

    /**
     * Retrieves/Synchronizes metadata information from the metadata catalog.
     * @param {string} idrecord
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApiInterface
     */
    syncMetadata(idrecord: string, options?: AxiosRequestConfig): AxiosPromise<ResponseBelindaRecordGet>;

    /**
     * Updates the information of an existing record.
     * @param {string} idRecording
     * @param {RequestRecordingUpdate} requestRecordingUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApiInterface
     */
    updateRecord(idRecording: string, requestRecordingUpdate: RequestRecordingUpdate, options?: AxiosRequestConfig): AxiosPromise<ResponseRecordingUpdate>;

    /**
     * Upload an image on an existing record.
     * @param {string} idRecording
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApiInterface
     */
    uploadImage(idRecording: string, file: File, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Upload the landscape image on an existing record.
     * @param {string} idRecording
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApiInterface
     */
    uploadLandscape(idRecording: string, file: File, options?: AxiosRequestConfig): AxiosPromise<string>;
}

/**
 * WizardControllerApi - object-oriented interface
 * @export
 * @class WizardControllerApi
 * @extends {BaseAPI}
 */
export class WizardControllerApi extends BaseAPI implements WizardControllerApiInterface {
    /**
     * Classify the chosen record against a selected classifier record
     * @param {string} recordId
     * @param {string} classifierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApi
     */
    public classifyRecording(recordId: string, classifierId: string, options?: AxiosRequestConfig) {
        return WizardControllerApiFp(this.configuration)
            .classifyRecording(recordId, classifierId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Add a new recording to the catalogue.
     * @param {RequestRecordingCreate} requestRecordingCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApi
     */
    public createRecord(requestRecordingCreate: RequestRecordingCreate, options?: AxiosRequestConfig) {
        return WizardControllerApiFp(this.configuration)
            .createRecord(requestRecordingCreate, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApi
     */
    public deleteRecording(idRecording: string, options?: AxiosRequestConfig) {
        return WizardControllerApiFp(this.configuration)
            .deleteRecording(idRecording, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Retrieves audio recording metadata.
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApi
     */
    public getAudioData(idRecording: string, options?: AxiosRequestConfig) {
        return WizardControllerApiFp(this.configuration)
            .getAudioData(idRecording, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM'} analytic
     * @param {'JPEG' | 'CSV'} format
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {string} jwtHeader
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApi
     */
    public getDataFromJwtML(
        analytic: 'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM',
        format: 'JPEG' | 'CSV',
        jwtPayload: string,
        jwtSignature: string,
        jwtHeader: string,
        options?: AxiosRequestConfig,
    ) {
        return WizardControllerApiFp(this.configuration)
            .getDataFromJwtML(analytic, format, jwtPayload, jwtSignature, jwtHeader, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the audio registration to be played.
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApi
     */
    public getTrack(idRecording: string, options?: AxiosRequestConfig) {
        return WizardControllerApiFp(this.configuration)
            .getTrack(idRecording, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} idRecording
     * @param {number} numberStep
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApi
     */
    public nextStep(idRecording: string, numberStep: number, options?: AxiosRequestConfig) {
        return WizardControllerApiFp(this.configuration)
            .nextStep(idRecording, numberStep, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Retrieves/Synchronizes metadata information from the metadata catalog.
     * @param {string} idrecord
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApi
     */
    public syncMetadata(idrecord: string, options?: AxiosRequestConfig) {
        return WizardControllerApiFp(this.configuration)
            .syncMetadata(idrecord, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Updates the information of an existing record.
     * @param {string} idRecording
     * @param {RequestRecordingUpdate} requestRecordingUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApi
     */
    public updateRecord(idRecording: string, requestRecordingUpdate: RequestRecordingUpdate, options?: AxiosRequestConfig) {
        return WizardControllerApiFp(this.configuration)
            .updateRecord(idRecording, requestRecordingUpdate, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Upload an image on an existing record.
     * @param {string} idRecording
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApi
     */
    public uploadImage(idRecording: string, file: File, options?: AxiosRequestConfig) {
        return WizardControllerApiFp(this.configuration)
            .uploadImage(idRecording, file, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Upload the landscape image on an existing record.
     * @param {string} idRecording
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WizardControllerApi
     */
    public uploadLandscape(idRecording: string, file: File, options?: AxiosRequestConfig) {
        return WizardControllerApiFp(this.configuration)
            .uploadLandscape(idRecording, file, options)
            .then(request => request(this.axios, this.basePath));
    }
}
