import { WizardControllerApi } from '@api/api';

export interface IWizardApiClient {
    wizard: WizardControllerApi;
}

export class WizardApiClients {
    private static instance: IWizardApiClient;

    static getInstance(basePath: string): IWizardApiClient {
        if (WizardApiClients.instance == null) {
            WizardApiClients.instance = {
                wizard: new WizardControllerApi(undefined, basePath),
            };
        }
        return WizardApiClients.instance;
    }
}
