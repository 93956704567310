/* eslint-disable */
import { IsNotEmpty, Matches, ValidateIf } from 'class-validator';

export class WizardGeneralDataFormData {
    @Matches(/^(?!['-])[a-zA-Z\s'-]*[a-zA-Z](?![a-zA-Z\s'-])$/)
    @IsNotEmpty()
    country: string;

    @Matches(/^([+\-]?[0-9]+(\.[0-9]+)?)?$/)
    @ValidateIf(value => typeof value.latitude === 'string')
    @IsNotEmpty()
    latitude: string;

    @Matches(/^([+\-]?[0-9]+(\.[0-9]+)?)?$/)
    @ValidateIf(value => typeof value.longitude === 'string')
    @IsNotEmpty()
    longitude: string;

    @ValidateIf(value => value.radius)
    @Matches(/^[0-9]*(?:\.[0-9]+)?$/)
    radius: number;

    training: boolean;

    @IsNotEmpty()
    detectionAt: Date | string;

    @Matches(/^(?!['-])[a-zA-Z0-9\s'-]*[a-zA-Z0-9](?![a-zA-Z0-9\s'-])$/)
    @IsNotEmpty()
    recordingDevice: string;

    @Matches(/^(?!['-])[a-zA-Z0-9\s'-]*[a-zA-Z0-9](?![a-zA-Z0-9\s'-])$/)
    @IsNotEmpty()
    microphone: string;
}
