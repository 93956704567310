import { NavigationManager } from '@managers/navigation.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import { RouteEnum } from '@routes';
import '../styles/tabbar.style.scss';

export interface ITabBarProps {
    onRouteChange: (route: string) => void;
}

const TabBar: React.FC<ITabBarProps> = ({ onRouteChange }) => {
    const translator = TranslatorManager.getInstance();
    const navigationManager = NavigationManager.getInstance();

    const currentRoute = NavigationManager.getInstance().getCurrentRoute();

    return (
        <>
            <ul className='nav nav-pills' id='pills-tab' role='tablist'>
                <li className='nav-item' role='presentation'>
                    <button
                        className={`nav-link 
            ${currentRoute.includes(RouteEnum.catalogue) ? 'active' : ''} tabButton`}
                        id='project-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#project'
                        type='button'
                        role='tab'
                        aria-controls='project'
                        aria-selected='true'
                        onClick={() => {
                            onRouteChange(RouteEnum.catalogue);
                            navigationManager.navigateTo(RouteEnum.catalogue);
                        }}>
                        {translator.get('tabbar.catalogue.tab.text')}
                    </button>
                </li>
            </ul>
        </>
    );
};

export default TabBar;
