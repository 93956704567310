import { ReactComponent as Collapse } from '@assets/svg/it-collapse.svg';
import { ReactComponent as Expand } from '@assets/svg/it-expand.svg';
import { useState } from 'react';
import { Row } from './table.component';

export interface Props {
    row: Row | undefined;
    onRowClick(id: string): void;
    index: number;
    handleRowExpansion?: (e: any) => void;
    columns?: number;
    className?: string;
}
export const RowComponent: React.FC<Props> = ({ row, onRowClick, handleRowExpansion, columns, className }) => {
    const [expandRow, setExpandRow] = useState<boolean>(false);
    const handleExpandRow = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setExpandRow(prev => !prev);
        handleRowExpansion?.(e);
    };

    return (
        <tbody
            className='table-body'
            onClick={() => {
                onRowClick(row?.id as string);
            }}>
            <tr key={row?.id} id={String(row?.id)} className='table-row'>
                {columns &&
                    Object.values(row?.values || {}).map((col, index) => (
                        <td className={`table-padding col-${12 / columns} ${className}`} key={index}>
                            {col ? col : '-'}
                        </td>
                    ))}
                <>
                    {row?.collapsedRow && (
                        <td>
                            <button
                                className='btn btn-primary collapse-row-command p-1'
                                type='button'
                                data-bs-toggle='collapse'
                                data-bs-target={`#collapse-row-${row.id}`}
                                aria-expanded='false'
                                aria-controls='collapseExample'
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleExpandRow(e)}>
                                {expandRow ? <Collapse className='icon' fill='white' /> : <Expand className='icon' fill='white' />}
                            </button>
                        </td>
                    )}
                </>
            </tr>
            {row?.collapsedRow && (
                <tr>
                    <td className='p-0' colSpan={Object.values(row.values).length + 1}>
                        <div className='collapse' id={`collapse-row-${row.id}`}>
                            <div className='collapse-row-container'>{row.collapsedRow}</div>
                        </div>
                    </td>
                </tr>
            )}
        </tbody>
    );
};
