import Banner from '@assets/images/banner.png';
import { Table } from '@features/_shared/components/Table';
import { TranslatorManager } from '@managers/TranslatorManager';
import { HomepageManager } from '@managers/homepage.manager';
import { useStoreSelector } from '@store/hook';
import React, { useEffect } from 'react';
import { LiteRecording } from 'src/api';
import HomePageMockedImg from '@assets/images/homepageImage.png';
import '../../../_shared/theme/login.scss';
import { homeTableHeaderLabels, homeTableRowBuilder } from '../constants/constants';
import '../styles/home.style.scss';
import { MetricsComponent } from '../components/metrics.component';
import { store } from '@store/configure';
import { setCurrentRoute } from '@features/_shared/store/sharedSlices';
import { RouteEnum } from '@routes';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { Button } from '@features/_shared/components/Button/button.component';
import { NavigationManager } from '@managers/navigation.manager';
import { CatalogueManager } from '@managers/catalogue.manager';
import { BackofficeManager } from '@managers/backoffice.manager';

export const HomeView: React.FC = () => {
    const homepageManager = HomepageManager.getInstance();
    const queueManager = QueueManager.getInstance();
    const translator = TranslatorManager.getInstance();
    const backofficeManager = BackofficeManager.getInstance();
    const catalogueManager = CatalogueManager.getInstance();
    const configuration: { [key: string]: string } = useStoreSelector(state => state.reducer.shared.configuration);
    const latestRecords: Array<LiteRecording> = useStoreSelector(state => state.reducer.home.latestRecords);
    const metrics = useStoreSelector(state => state.reducer.home.metrics);
    const recordsTableColumns = homeTableHeaderLabels.map(label => translator.get(label));
    const tableRows = latestRecords?.map((item: LiteRecording) => homeTableRowBuilder(item));

    useEffect(() => {
        store.dispatch(setCurrentRoute(RouteEnum.home));
        window.scrollTo(0, 0);
        (async () => {
            await queueManager.queue(QueueKeyType.DEFAULT, backofficeManager.getKeys({ startsWith: 'ui.homepage' }));
            await queueManager.queue(QueueKeyType.DEFAULT, homepageManager.getMetrics());
            await queueManager.queue(QueueKeyType.DEFAULT, homepageManager.getLatestRecords());
        })();
    }, []);

    const handleRowClick = async (recordId: string) => {
        await queueManager.queue(QueueKeyType.DEFAULT, catalogueManager.getRecording(recordId));
    };

    return (
        <>
            <div className='container-fluid'>
                <div className='row home-title-container'>
                    <div className='col-12 col-sm-6'>
                        <div className='row'>
                            <p className='home-header-title-first'>{translator.get('home.view.title')}</p>
                        </div>
                        <p className='title-description-first'>{translator.get('home.view.title.description.part.one')}</p>
                    </div>
                    <div className='col-12 col-sm-6'>
                        <img src={HomePageMockedImg} className='image-title-homePage' alt='mock'></img>
                    </div>
                </div>

                {Object.values(configuration).some(value => value === 'true') && <MetricsComponent metrics={metrics} configuration={configuration} identifier='home.view' />}

                <div className='row'>
                    <div className='mt-4'>
                        <div className='home-header-title row'>
                            <h3 className='title col-12'>{translator.get('home.table.title')}</h3>
                            <h5 className='title-description col-12'>{translator.get('home.view.latest.published.description')}</h5>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col'>
                        <div className='project-table-container size-tab'>
                            <Table columns={recordsTableColumns} rows={tableRows} isStyledHeaders={true} keyLabel='home' onRowClick={handleRowClick} />
                        </div>
                    </div>
                </div>

                <div className='row mt-2'>
                    {latestRecords.length > 0 && (
                        <div className='d-flex justify-content-end'>
                            <Button identifier='common.see.all' variant='primary' onClick={() => NavigationManager.getInstance().navigateTo(RouteEnum.catalogue)} />
                        </div>
                    )}
                </div>

                <div className='row banner'>
                    <img src={Banner} className='banner-img' alt='banner'></img>
                </div>
            </div>
        </>
    );
};
