/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { RecordingSearchFilter } from './recording-search-filter';

/**
 *
 * @export
 * @interface RequestRecordingSearch
 */
export interface RequestRecordingSearch {
    /**
     *
     * @type {string}
     * @memberof RequestRecordingSearch
     */
    query?: string;
    /**
     *
     * @type {number}
     * @memberof RequestRecordingSearch
     */
    page?: number;
    /**
     *
     * @type {number}
     * @memberof RequestRecordingSearch
     */
    size?: number;
    /**
     *
     * @type {{ [key: string]: string | undefined; }}
     * @memberof RequestRecordingSearch
     */
    sort?: { [key: string]: string | undefined };
    /**
     *
     * @type {RecordingSearchFilter}
     * @memberof RequestRecordingSearch
     */
    filter?: RecordingSearchFilter;
}

export const RequestRecordingSearchSortEnum = {
    ASC: 'ASC',
    DESC: 'DESC',
} as const;

export type RequestRecordingSearchSortEnum = (typeof RequestRecordingSearchSortEnum)[keyof typeof RequestRecordingSearchSortEnum];
