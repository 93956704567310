//import { StoreManager } from '@hypereact/state';

import { dequeueTaskForLoader, queueTaskForLoader } from '@features/_shared/store/sharedSlices';
import { store } from '@store/configure';

export enum QueueKeyType {
    DEFAULT = 'default',
    POLLER = 'poller',
}

export class QueueManager {
    private static instance: QueueManager;

    static getInstance() {
        if (QueueManager.instance == null) {
            QueueManager.instance = new QueueManager();
        }
        return QueueManager.instance;
    }

    async queue<T>(key: QueueKeyType, task: Promise<T>): Promise<T> {
        if (key === QueueKeyType.DEFAULT) {
            try {
                //this.storeManager.dispatch(new QueueTaskForLoader());
                store.dispatch(queueTaskForLoader('global'));
                let result = await task;
                //this.storeManager.dispatch(new DequeueTaskForLoader());
                store.dispatch(dequeueTaskForLoader('global'));
                return result;
            } catch (e) {
                store.dispatch(dequeueTaskForLoader('global'));
                //this.storeManager.dispatch(new DequeueTaskForLoader());
                throw e;
            }
        } else {
            let result = await task;
            return result;
        }
    }
}
