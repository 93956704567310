import { HomeControllerApi } from '../../../../src/api';

export interface IHomepageApiClient {
    home: HomeControllerApi;
}

export class HomepageApiClients {
    private static instance: IHomepageApiClient;

    static getInstance(basePath: string): IHomepageApiClient {
        if (HomepageApiClients.instance == null) {
            HomepageApiClients.instance = {
                home: new HomeControllerApi(undefined, basePath),
            };
        }
        return HomepageApiClients.instance;
    }
}
