import { ClassificationML } from '@api/entities';
import { AudioPlayerComponent } from '@features/_shared/components/AudioPlayer/audio.player';
import { Button } from '@features/_shared/components/Button/button.component';
import { FormatUtil } from '@utils/formaters.util';
import React from 'react';
import { WizardUtil } from '../utils/wizard.util';
import './styles/match.options.component.style.scss';

export interface MatchComponentProps {
    onChooseClick: (id: string) => void;
    onCompareClick: (id: string) => void;
    matchesList?: Array<ClassificationML>;
}

export const MatchOptionComponent: React.FC<MatchComponentProps> = ({ onChooseClick, onCompareClick, matchesList }) => {
    return (
        <>
            {matchesList?.map((item, index) => {
                return (
                    <div className='col-12'>
                        <div className='p-4'>
                            <div className='row d-flex justify-content-between'>
                                <div className={`col-2 circle-container-detail ${WizardUtil.getLevelColor((item.percentage as number) * 100)}`}>
                                    <h6 className='m-0'>{`${FormatUtil.roundNumberAfterComma((item.percentage as number) * 100, 1)}%`}</h6>
                                </div>
                                <div className='col-md-10 col-lg-7 mt-3 p-0'>
                                    <div className='size-audioPlayer-Match'>
                                        <h6>{`${item.genus} ${item.species}`}</h6>
                                        <AudioPlayerComponent recordId={item.idReferral as string} autoPlay={false} />
                                    </div>
                                </div>
                                <div className='col-md-12 mt-4 gap-2 col-lg-3 p-0 recognitionButton d-flex justify-content-end'>
                                    <Button identifier='wizard.recognition.compare' variant='primary' onClick={() => item.idReferral && onCompareClick(item.idReferral)} />
                                    <Button identifier='wizard.recognition.choose' variant='primary' onClick={() => item.idReferral && onChooseClick(item.idReferral)} />
                                </div>
                            </div>
                        </div>
                        {index !== Object.keys(matchesList).length - 1 && <hr className='stepper-divider-confirmed'></hr>}
                    </div>
                );
            })}
        </>
    );
};
