import {
    Recording,
    RequestRecordingCreate,
    RequestRecordingUpdate,
    ResponseBelindaRecordGet,
    ResponseMlAnalyticsResources,
    ResponseRecordingCreate,
    ResponseRecordingUpdate,
} from '@api/entities';
import { AxiosPromise } from 'axios';
import { IWizardApiClient, WizardApiClients } from './wizard.api.client';

export interface IWizardApiService {
    createRecord(requestRecordingCreate: RequestRecordingCreate): Promise<ResponseRecordingCreate>;
    getTrack(idrecord: string): Promise<File>;
    syncMetadata(idrecord: string): Promise<ResponseBelindaRecordGet>;
    updateRecord(idrecord: string, requestRecordingUpdate: RequestRecordingUpdate): Promise<ResponseRecordingUpdate>;
    getDataFromJwtML(
        analytic: 'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM',
        format: 'JPEG' | 'CSV',
        jwtPayload: string,
        jwtSignature: string,
        jwtHeader: string,
    ): Promise<AxiosPromise<File>>;
    getAudioData(idRecording: string): Promise<ResponseMlAnalyticsResources>;
    nextStep(idRecording: string, numberStep: number): Promise<Recording>;
    deleteRecording(idRecording: string): Promise<string>;
    uploadImage(idRecording: string, file: File): Promise<string>;
    uploadLandscape(idRecording: string, file: File): Promise<string>;
    classifyRecording(recordId: string, classifierId: string): Promise<Recording>;
}

export class WizardApiService implements IWizardApiService {
    private static instance: WizardApiService;
    private api: IWizardApiClient;

    private constructor(basePath: string) {
        this.api = WizardApiClients.getInstance(basePath);
    }

    static getInstance(basePath: string) {
        if (WizardApiService.instance == null) {
            WizardApiService.instance = new WizardApiService(basePath);
        }
        return WizardApiService.instance;
    }

    async createRecord(requestRecordingCreate: RequestRecordingCreate): Promise<ResponseRecordingCreate> {
        return (await this.api.wizard.createRecord(requestRecordingCreate)).data;
    }

    async getTrack(idrecord: string): Promise<File> {
        return (await this.api.wizard.getTrack(idrecord)).data;
    }

    async syncMetadata(idrecord: string): Promise<ResponseBelindaRecordGet> {
        return (await this.api.wizard.syncMetadata(idrecord)).data;
    }

    async updateRecord(idrecord: string, requestRecordingUpdate: RequestRecordingUpdate): Promise<ResponseRecordingUpdate> {
        return (await this.api.wizard.updateRecord(idrecord, requestRecordingUpdate)).data;
    }

    async getDataFromJwtML(
        analytic: 'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM',
        format: 'JPEG' | 'CSV',
        jwtPayload: string,
        jwtSignature: string,
        jwtHeader: string,
    ): Promise<AxiosPromise<File>> {
        return await this.api.wizard.getDataFromJwtML(analytic, format, jwtPayload, jwtSignature, jwtHeader, { responseType: 'blob' });
    }

    async getAudioData(idRecording: string): Promise<ResponseMlAnalyticsResources> {
        return (await this.api.wizard.getAudioData(idRecording)).data;
    }

    async nextStep(idRecording: string, numberStep: number): Promise<Recording> {
        return (await this.api.wizard.nextStep(idRecording, numberStep)).data;
    }

    async deleteRecording(idRecording: string): Promise<string> {
        return (await this.api.wizard.deleteRecording(idRecording)).data;
    }

    async uploadImage(idRecording: string, file: File): Promise<string> {
        return (await this.api.wizard.uploadImage(idRecording, file)).data;
    }

    async uploadLandscape(idRecording: string, file: File): Promise<string> {
        return (await this.api.wizard.uploadLandscape(idRecording, file)).data;
    }

    async classifyRecording(recordId: string, classifierId: string): Promise<Recording> {
        return (await this.api.wizard.classifyRecording(recordId, classifierId)).data;
    }
}
