import React from 'react';
import { PopupManager } from '../../../../_shared/managers/popup.manager';

interface PopupContainerProps {
    isVisible?: boolean;
}
class Popup extends React.Component<PopupContainerProps> {
    private popupManager: PopupManager;

    constructor(props: any) {
        super(props);
        this.popupManager = PopupManager.getInstance();
    }

    render() {
        return this.props.isVisible ? this.popupManager.get() : null;
    }
}

export default Popup;
