import { Graph } from '@features/_shared/components/Graph/graph.component';
import { Recording } from '@api/entities';
import { useEffect, useState } from 'react';
import { WizardManager } from '@managers/wizard.manager';

export interface IAudioAnalyticsComponentProps {
    identifier: string;
    record?: Recording;
}

export const AudioAnalyticsComponent: React.FC<IAudioAnalyticsComponentProps> = ({ identifier, record }) => {
    const wizardManager = WizardManager.getInstance();

    const [ftt, setFtt] = useState<string | undefined>(undefined);
    const [mel, setMel] = useState<string | undefined>(undefined);
    const [mfcc, setMfcc] = useState<string | undefined>(undefined);
    const [spec, setSpec] = useState<string | undefined>(undefined);
    const [wave, setWave] = useState<string | undefined>(undefined);

    const getGraphImg = async (analytics: 'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM') => {
        switch (analytics) {
            case 'FTT':
                {
                    if (record?.fttJwtUrl) {
                        const [header, payload, signature] = record?.fttJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'JPEG', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        setFtt(href);
                    }
                }
                break;
            case 'MELSPECTROGRAM':
                {
                    if (record?.melspectrogramJwtUrl) {
                        const [header, payload, signature] = record?.melspectrogramJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'JPEG', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        setMel(href);
                    }
                }
                break;
            case 'MFCC':
                {
                    if (record?.mfccJwtUrl) {
                        const [header, payload, signature] = record?.mfccJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'JPEG', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        setMfcc(href);
                    }
                }
                break;
            case 'SPECTROGRAM':
                {
                    if (record?.spectrogramJwtUrl) {
                        const [header, payload, signature] = record?.spectrogramJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'JPEG', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        setSpec(href);
                    }
                }
                break;
            case 'WAVEFORM':
                {
                    if (record?.waveformJwtUrl) {
                        const [header, payload, signature] = record?.waveformJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'JPEG', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        setWave(href);
                    }
                }
                break;
        }
    };

    const handleOnDownloadClick = async (analytics: 'FTT' | 'MELSPECTROGRAM' | 'MFCC' | 'SPECTROGRAM' | 'WAVEFORM') => {
        switch (analytics) {
            case 'FTT':
                {
                    if (record?.fttJwtUrl) {
                        const [header, payload, signature] = record?.fttJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'CSV', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', `ftt-${record.id}.csv`); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    }
                }
                break;
            case 'MELSPECTROGRAM':
                {
                    if (record?.melspectrogramJwtUrl) {
                        const [header, payload, signature] = record?.melspectrogramJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'CSV', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', `melspectrogram-${record.id}.csv`); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    }
                }
                break;
            case 'MFCC':
                {
                    if (record?.mfccJwtUrl) {
                        const [header, payload, signature] = record?.mfccJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'CSV', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', `mfcc-${record.id}.csv`); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    }
                }
                break;
            case 'SPECTROGRAM':
                {
                    if (record?.spectrogramJwtUrl) {
                        const [header, payload, signature] = record?.spectrogramJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'CSV', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', `spectrogram-${record.id}.csv`); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    }
                }
                break;
            case 'WAVEFORM':
                {
                    if (record?.waveformJwtUrl) {
                        const [header, payload, signature] = record?.waveformJwtUrl.split('.');
                        const img = await wizardManager.getDataFromJwtML(analytics, 'CSV', payload, signature, header);
                        const href = URL.createObjectURL(new Blob([img.data]));
                        const link = document.createElement('a');
                        link.href = href;
                        link.setAttribute('download', `waveform-${record.id}.csv`); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    }
                }
                break;
        }
    };

    useEffect(() => {
        getGraphImg('FTT');
        getGraphImg('MELSPECTROGRAM');
        getGraphImg('MFCC');
        getGraphImg('SPECTROGRAM');
        getGraphImg('WAVEFORM');
    }, []);

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-xxl-6 col-xl-12 d-flex justify-content-center'>
                    <Graph img={ftt as string} identifier={`${identifier}.time.fourier.transform`} isDownloadable onDownloadClick={() => handleOnDownloadClick('FTT')} />
                </div>
                <div className='col-xxl-6 col-xl-12 d-flex justify-content-center'>
                    <Graph img={mel as string} identifier={`${identifier}.melspectrogram`} isDownloadable onDownloadClick={() => handleOnDownloadClick('MELSPECTROGRAM')} />
                </div>
            </div>
            <div className='row'>
                <div className='col-xxl-6 col-xl-12 d-flex justify-content-center'>
                    <Graph img={mfcc as string} identifier={`${identifier}.mfcc`} isDownloadable onDownloadClick={() => handleOnDownloadClick('MFCC')} />
                </div>
                <div className='col-xxl-6 col-xl-12 d-flex justify-content-center'>
                    <Graph img={spec as string} identifier={`${identifier}.spectrogram`} isDownloadable onDownloadClick={() => handleOnDownloadClick('SPECTROGRAM')} />
                </div>
            </div>
            <div className='row'>
                <div className='col-xxl-12 col-xl-12 d-flex justify-content-center'>
                    <Graph img={wave as string} identifier={`${identifier}.waveform`} isDownloadable onDownloadClick={() => handleOnDownloadClick('WAVEFORM')} />
                </div>
            </div>
        </div>
    );
};
