import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.component.style.scss';
import { ReactComponent as CalendarIcon } from '@assets/svg/calendar-solid.svg';
import moment from 'moment';

export interface ICalendarProps {
    value?: Date | string;
    identifier: string;
    onChange: (e: any) => void;
    maxDate?: Date;
    minDate?: Date;
}

export const Calendar: React.FC<ICalendarProps> = ({ value, onChange, maxDate, minDate }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <div>
            <DatePicker
                onChange={onChange}
                className='datepicker-input'
                autoComplete='off'
                selected={value ? new Date(value) : new Date()}
                dateFormat='dd/MM/yyyy'
                value={moment.utc(value).local().format('DD/MM/YYYY h:mm A')}
                open={isOpen}
                showTimeSelect
                onFocus={() => setIsOpen(true)}
                maxDate={maxDate ?? undefined}
                minDate={minDate ?? undefined}
                onClickOutside={() => setIsOpen(false)}
            />
            <div className='calendar-icon d-flex justify-content-center align-items-center'>
                <CalendarIcon fill='#009138' width='inherit' height='inherit' onClick={() => setIsOpen(true)} />
            </div>
        </div>
    );
};
