import { LiteRecording, Recording } from '@api/entities';
import moment from 'moment';

export const homeTableHeaderLabels = [
    'home.table.header.scientificName.text',
    'home.table.header.length.text',
    'home.table.header.recordist.text',
    'home.table.header.date.text',
    'home.table.header.country.text',
    'home.table.header.catNr.text',
];

export const homeTableRowBuilder = (item: LiteRecording) => {
    return {
        id: item.id as string,
        values: {
            scientificName: item.scientificName,
            length: moment.utc(Number(item?.length) * 1000).format('HH:mm:ss'),
            recordist: `${item.recordist?.name} ${item.recordist?.surname}`,
            publishedAt: moment.utc(item.createdAt).format('DD/MM/YYYY'),
            country: item.country,
            catNr: item.catalogueNumber,
        },
    };
};
