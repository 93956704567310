import { NavigationManager } from '@managers/navigation.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import { RouteEnum } from '@routes';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';
import { useEffect, useState } from 'react';
import '../styles/linksList.style.scss';

export interface ILinksListProps {
    isIDEOpen: boolean;
    route: string;
}

const LinkList: React.FC<ILinksListProps> = ({ isIDEOpen, route }) => {
    const translator = TranslatorManager.getInstance();
    const navigator = NavigationManager.getInstance();
    const [currentRoute, setCurrentRoute] = useState<string>(NavigationManager.getInstance().getCurrentRoute());

    const handleRouteChange = (path: any) => {
        navigator.navigateTo(path);
        setCurrentRoute(path);
    };

    useEffect(() => {
        setCurrentRoute(navigator.getCurrentRoute());
    }, [route, navigator]);

    return (
        <>
            <div className='container-fluid navigation-container'>
                <div className='container-fluid d-flex'>
                    <div className='row' style={{ lineHeight: '20px' }}>
                        <div className='col-12 link-container'>
                            <a className={`link ${navigator.getCurrentRoute().includes(RouteEnum.home) ? 'active' : ''} `} onClick={() => handleRouteChange(RouteEnum.home)}>
                                {translator.get('tabbar.home.tab.text')}
                            </a>
                        </div>
                        <div className='col-12 link-container'>
                            <a
                                className={`link ${navigator.getCurrentRoute().includes(RouteEnum.catalogue) ? 'active' : ''} `}
                                onClick={() => handleRouteChange(RouteEnum.catalogue)}>
                                {translator.get('tabbar.catalogue.tab.text')}
                            </a>
                        </div>
                        <div className='col-12 link-container'>
                            <a href='https://helpdesk.lifewatchitaly.eu' target='_blank' rel='noreferrer' className='link'>
                                {translator.get('linkbar.helpdesk.link.text')}
                            </a>
                        </div>
                        <div className='col-12 link-container'>
                            <a
                                href={`${
                                    currentRoute.includes('/project') && !isIDEOpen
                                        ? 'https://training.lifewatchitaly.eu/resources/?resource=/course/view.php?id=84'
                                        : currentRoute.includes('/service')
                                        ? 'https://training.lifewatchitaly.eu/resources/?resource=/course/view.php?id=86'
                                        : currentRoute.includes('/project') && isIDEOpen
                                        ? 'https://training.lifewatchitaly.eu/resources/?resource=/course/view.php?id=85'
                                        : 'https://training.lifewatchitaly.eu'
                                }`}
                                target='_blank'
                                rel='noreferrer'
                                className='link'>
                                {translator.get('linkbar.training.link.text')}
                            </a>
                        </div>
                        <div className='col-12 link-container'>
                            <a className={`link ${currentRoute.includes('/api') ? 'active' : ''} `} onClick={() => handleRouteChange('/api')}>
                                {translator.get('tabbar.api.tab.text')}
                            </a>
                        </div>
                        <div className='col-12 link-container'>
                            <a className={`link ${currentRoute.includes('/faqs') ? 'active' : ''} `} onClick={() => handleRouteChange('/faqs')}>
                                {translator.get('tabbar.faqs.tab.text')}
                            </a>
                        </div>
                        {KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) && (
                            <div>
                                <hr />
                                <div className='col-12 link-container'>
                                    <a className={`link ${currentRoute.includes('/administration') ? 'active' : ''} `} onClick={() => handleRouteChange('/administration')}>
                                        {translator.get('tabbar.administration.tab.text')}
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LinkList;
