import { ReactComponent as FacebookLogo } from '@assets/svg/facebook.svg';
import { ReactComponent as Logo } from '@assets/svg/logoLW_ITALY_white.svg';
import { ReactComponent as TwitterLogo } from '@assets/svg/twitter.svg';

import { TranslatorManager } from '@managers/TranslatorManager';
import './styles/footer.component.style.scss';

const Footer: React.FC = () => {
    const translator = TranslatorManager.getInstance();
    return (
        <>
            <div id='footer' className='container-fluid p-3 bg-primary main-footer-list'>
                <div className='col-12 col-lg-2 col-sm-12 col-md-2 d-flex justify-content-footer'>
                    <div className='logo-container'>
                        <Logo width={158} height={78.25} />
                    </div>
                </div>
                <div className='col-12 col-lg-2 col-sm-12 col-md-2 d-flex justify-content-footer'>
                    <div className='d-flex flex-column'>
                        <div className='footer-about footer-element-position'>
                            <h3 className='footer-list-title'>{translator.get('common.footer.label.lifewatch.title')}</h3>
                            <div className='footer-hr'></div>
                            <ul className='footer-list' title='Lifewatch'>
                                <a className='footer-link' rel='noreferrer' href='https://www.lifewatchitaly.eu/en/about-us/' target='_blank'>
                                    <li className='footer-li-style'>{translator.get('common.footer.label.about.us')}</li>
                                </a>
                                <a className='footer-link' rel='noreferrer' href='https://www.lifewatchitaly.eu/en/data-policy-eng/' target='_blank'>
                                    <li className='footer-li-style'>{translator.get('common.footer.paragraph.data.policy')}</li>
                                </a>
                                <a className='footer-link' rel='noreferrer' href='https://www.lifewatchitaly.eu/en/privacy-policy-eng/' target='_blank'>
                                    <li className='footer-li-style'>{translator.get('common.footer.paragraph.privacy.policy')}</li>
                                </a>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-2 col-sm-12 col-md-2 d-flex justify-content-footer'>
                    <div className='footer-contact footer-element-position'>
                        <h3 className='footer-list-title'>{translator.get('common.footer.label.contact.us')}</h3>
                        <div className='footer-hr'></div>
                        <ul className='footer-list' title='Contact us'>
                            <a className='footer-link' rel='noreferrer' href='https://www.lifewatchitaly.eu/en/contacts/' target='_blank'>
                                <li className='footer-li-style'>{translator.get('common.footer.paragraph.contact.us')}</li>
                            </a>
                            <a className='footer-link' rel='noreferrer' href='https://helpdesk.lifewatchitaly.eu/' target='_blank'>
                                <li className='footer-li-style'>{translator.get('common.footer.paragraph.help.desk')}</li>
                            </a>
                        </ul>
                    </div>
                </div>
                <div className='col-12 col-lg-2 col-sm-12 col-md-2 d-flex justify-content-footer'>
                    <div className='footer-social footer-element-position'>
                        <div className='footer-social-wrapper'>
                            <div className='footer-social-circle'>
                                <a className='footer-social-fa footer-link' rel='noreferrer' target='_blank' href='https://twitter.com/LifeWatchIT'>
                                    <TwitterLogo width={30} fill='#009133' />
                                </a>
                            </div>
                            <div className='footer-social-circle'>
                                <a className='footer-social-fa footer-link' rel='noreferrer' target='_blank' href='https://www.facebook.com/lifewatch.Italia/'>
                                    <FacebookLogo width={30} fill='#009133' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Footer;
