/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ResponseMlAnalyticsResources } from '../entities';
// @ts-ignore
import { ResponseMlClassification } from '../entities';
// @ts-ignore
import { ResponseValidationML } from '../entities';
/**
 * MachineLearningControllerApi - axios parameter creator
 * @export
 */
export const MachineLearningControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassificationML: async (idRecording: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRecording' is not null or undefined
            assertParamExists('getClassificationML', 'idRecording', idRecording);
            const localVarPath = `/ml/{idRecording}/classification`.replace(`{${'idRecording'}}`, encodeURIComponent(String(idRecording)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourcesML: async (idRecording: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRecording' is not null or undefined
            assertParamExists('getResourcesML', 'idRecording', idRecording);
            const localVarPath = `/ml/{idRecording}/resources`.replace(`{${'idRecording'}}`, encodeURIComponent(String(idRecording)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidateML: async (idRecording: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idRecording' is not null or undefined
            assertParamExists('getValidateML', 'idRecording', idRecording);
            const localVarPath = `/ml/{idRecording}/validate`.replace(`{${'idRecording'}}`, encodeURIComponent(String(idRecording)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MachineLearningControllerApi - functional programming interface
 * @export
 */
export const MachineLearningControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MachineLearningControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassificationML(
            idRecording: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMlClassification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassificationML(idRecording, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResourcesML(idRecording: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMlAnalyticsResources>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResourcesML(idRecording, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getValidateML(idRecording: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseValidationML>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getValidateML(idRecording, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * MachineLearningControllerApi - factory interface
 * @export
 */
export const MachineLearningControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MachineLearningControllerApiFp(configuration);
    return {
        /**
         *
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassificationML(idRecording: string, options?: any): AxiosPromise<ResponseMlClassification> {
            return localVarFp.getClassificationML(idRecording, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResourcesML(idRecording: string, options?: any): AxiosPromise<ResponseMlAnalyticsResources> {
            return localVarFp.getResourcesML(idRecording, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} idRecording
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValidateML(idRecording: string, options?: any): AxiosPromise<ResponseValidationML> {
            return localVarFp.getValidateML(idRecording, options).then(request => request(axios, basePath));
        },
    };
};

/**
 * MachineLearningControllerApi - interface
 * @export
 * @interface MachineLearningControllerApi
 */
export interface MachineLearningControllerApiInterface {
    /**
     *
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningControllerApiInterface
     */
    getClassificationML(idRecording: string, options?: AxiosRequestConfig): AxiosPromise<ResponseMlClassification>;

    /**
     *
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningControllerApiInterface
     */
    getResourcesML(idRecording: string, options?: AxiosRequestConfig): AxiosPromise<ResponseMlAnalyticsResources>;

    /**
     *
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningControllerApiInterface
     */
    getValidateML(idRecording: string, options?: AxiosRequestConfig): AxiosPromise<ResponseValidationML>;
}

/**
 * MachineLearningControllerApi - object-oriented interface
 * @export
 * @class MachineLearningControllerApi
 * @extends {BaseAPI}
 */
export class MachineLearningControllerApi extends BaseAPI implements MachineLearningControllerApiInterface {
    /**
     *
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningControllerApi
     */
    public getClassificationML(idRecording: string, options?: AxiosRequestConfig) {
        return MachineLearningControllerApiFp(this.configuration)
            .getClassificationML(idRecording, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningControllerApi
     */
    public getResourcesML(idRecording: string, options?: AxiosRequestConfig) {
        return MachineLearningControllerApiFp(this.configuration)
            .getResourcesML(idRecording, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} idRecording
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MachineLearningControllerApi
     */
    public getValidateML(idRecording: string, options?: AxiosRequestConfig) {
        return MachineLearningControllerApiFp(this.configuration)
            .getValidateML(idRecording, options)
            .then(request => request(this.axios, this.basePath));
    }
}
