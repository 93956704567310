// @ts-nocheck
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { validationMetadatasToSchemas } from 'class-validator-jsonschema';
import { SchemaObject } from 'openapi3-ts';
import {
    DeepPartial,
    FieldErrors,
    Mode,
    Resolver,
    SubmitHandler,
    UnpackNestedValue,
    useForm,
    UseFormClearErrors,
    UseFormGetValues,
    UseFormHandleSubmit,
    UseFormRegister,
    UseFormReset,
    UseFormSetError,
    UseFormSetFocus,
    UseFormSetValue,
    UseFormTrigger,
    UseFormWatch,
} from 'react-hook-form';

export type Properties<T> = {
    [propertyName in keyof T]: SchemaObject;
};

export interface IFormProxy<T = any> {
    errors: FieldErrors;
    register: UseFormRegister<T>;
    handleSubmit: ReturnType<UseFormHandleSubmit<T>>;
    control: Object;
    trigger: UseFormTrigger<T>;
    watch: UseFormWatch<T>;
    reset: UseFormReset<T>;
    setValue: UseFormSetValue<T>;
    setFocus: UseFormSetFocus<T>;
    setError: UseFormSetError<T>;
    getValues: UseFormGetValues<T>;
    clearErrors: UseFormClearErrors<T>;
    properties: Properties<T>;
}

export type ClassType<T> = new (...args: any[]) => T;

export class FormManager {
    private static instance: FormManager | null;

    public static getInstance(): FormManager {
        if (FormManager.instance == null) {
            FormManager.instance = new FormManager();
        }
        return FormManager.instance;
    }

    //private constructor() {}

    /* TODO: create default values dynamically and merge them with input default values (to avoid the controlled/uncontrolled React warning)
    private createDefaultValues(defaultValues: any, modelName: string){
        const properties = validationMetadatasToSchemas()[modelName].properties;
        console.log(properties)
        return defaultValues;
    }*/

    //TODO: implement support for manager api errors
    buildFormProxy<T>(
        model: ClassType<T>,
        submit: (data: any) => void,
        defaultValues?: UnpackNestedValue<DeepPartial<T>>,
        mode?: Mode,
        reValidateMode?: 'onBlur' | 'onChange' | 'onSubmit' | undefined,
    ): IFormProxy {
        const {
            register,
            formState: { errors },
            handleSubmit,
            setError,
            control,
            trigger,
            watch,
            setValue,
            setFocus,
            reset,
            getValues,
            clearErrors,
            // eslint-disable-next-line
        } = useForm<T>({
            reValidateMode,
            mode,
            defaultValues,
            resolver: classValidatorResolver<T>(model) as Resolver<T, object>,
        });
        const onSubmit: SubmitHandler<T> = data => submit(data);
        const formProxy = {
            // TODO: augment returned values with properties by schema object (i.e. maxLength)
            register,
            errors: errors || {},
            setError,
            name: model.name,
            handleSubmit: handleSubmit(onSubmit),
            control,
            trigger,
            watch,
            reset,
            getValues,
            setValue,
            setFocus,
            clearErrors,
            properties: (validationMetadatasToSchemas()[model.name]?.properties || {}) as Properties<T>,
        };
        return formProxy;
    }
}
