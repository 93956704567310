import { TranslatorManager } from '@managers/TranslatorManager';

export type ButtonVariant = 'primary' | 'secondary';

export interface IButtonProps {
    identifier: string;
    icon?: JSX.Element;
    onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
    variant: ButtonVariant;
    disabled?: boolean;
}

export const Button: React.FC<IButtonProps> = ({ identifier, icon, variant, onClick, disabled = false }) => {
    const translator = TranslatorManager.getInstance();

    return (
        <button
            disabled={disabled}
            className={`btn ${variant === 'primary' ? 'btn-primary text-white' : 'btn-outline-primary'} text-nowrap d-flex justify-content-center width-button-match`}
            onClick={onClick}>
            {translator.get(`${identifier}.button.text`)}
            {icon && icon}
        </button>
    );
};
