import { Recording } from '@api/entities/recording';
import { AudioAnalyticsComponent } from '@features/catalogue/components/audio.analytics.component';
import { DataSectionComponent } from '@features/catalogue/components/data.section.component';
import { ImageSectionComponent } from '@features/catalogue/components/image.section.component';
import { amplitudeDataTableRowBuilder, audioDataTableRowBuilder } from '@features/catalogue/constats/constats';
import { DisplayModeEnum } from '@features/catalogue/state/catalogueSlice';
import { WizardStepsEnum } from '@features/wizard/constants/constants';
import { setCurrentStep } from '@features/wizard/state/wizard.slice';
import { Accordion } from '@features/_shared/components/Accordion/accordion.component';
import { AudioPlayerComponent } from '@features/_shared/components/AudioPlayer/audio.player';
import { Button } from '@features/_shared/components/Button/button.component';
import Table from '@features/_shared/components/Table/table.component';
import { NavigationManager } from '@managers/navigation.manager';
import { PopupManager } from '@managers/popup.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import { WizardManager } from '@managers/wizard.manager';
import { RootState, store } from '@store/configure';
import { useStoreSelector } from '@store/hook';
import React, { useState } from 'react';
import './wizard.summary.data.style.scss';

export const WizardSummaryView: React.FC = () => {
    const translator = TranslatorManager.getInstance();
    const wizardManager = WizardManager.getInstance();
    const navigationManager = NavigationManager.getInstance();
    const popupManager = PopupManager.getInstance();
    const record: Recording = useStoreSelector((state: RootState) => state.reducer.catalogue.selectedRecording);
    const catalogueDetailTableColumns = [translator.get('catalogue.detail.table.header.title.text'), translator.get('catalogue.detail.table.header.value.text')];
    const isCustomData: boolean = useStoreSelector(state => state.reducer.wizard.isCustomData);
    const [isFormValid, setIsFormalValid] = useState<boolean>(isCustomData ? false : true);

    const amplitudeDataTableRows = amplitudeDataTableRowBuilder(record?.amplitudeML as Partial<Recording>);
    const audioDataTableRows = audioDataTableRowBuilder(record?.metadataML as Partial<Recording>);

    const handleNextClick = async () => {
        // if (KeycloakUtil.hasRole([KeycloakRoleEnum.reviewer]) || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) {
        //     record?.id && (await wizardManager.nextStep(record?.id, 4));
        //     store.dispatch(setCurrentStep(WizardStepsEnum.METADATA));
        // } else {
        //     popupManager.show({
        //         type: PopupType.Info,
        //         title: translator.get('wizard.summary.skip.title'),
        //         description: translator.get('wizard.summary.skip.description'),
        //         maxWidth: 'lg',
        //         stylingProp: 'popup-display',
        //         ctas: {
        //             primary: {
        //                 label: translator.get('popup.menu.ok.title'),
        //                 className: 'popup-accept-button',
        //                 cta: async () => {
        //                     record?.id && (await wizardManager.nextStep(record?.id, 4));
        //                     navigationManager.navigateTo(`/record/${record?.id}` as Paths);
        //                 },
        //             },
        //         },
        //     });
        // }
        record?.id && (await wizardManager.nextStep(record?.id, 4));
        store.dispatch(setCurrentStep(WizardStepsEnum.COMPLETE));
    };

    const validateCustomData = (errors: { [x: string]: any }) => {
        if (Object.keys(errors).length > 0) {
            setIsFormalValid(false);
        } else {
            setIsFormalValid(true);
        }
    };

    return (
        <div className='container-fluid px-4'>
            <div className='row home-title-container mb-5'>
                <h5 className='title-description'>{translator.get('home.view.title.description.part.one')}</h5>
            </div>

            <>
                {!isCustomData ? (
                    <div className='col-12 pe-4 ps-4'>
                        <div
                            className=' d-flex col pe-4 ps-4 mb-4 py-3'
                            //style={{ boxShadow: displayMode === DisplayModeEnum.SIMPLIFIED ? 'none' : '7px 7px 12px 0px rgba(177,177,177,0.87)' }}
                        >
                            {/* <ImageSectionComponent displayMode={displayMode} scientificName={record.scientificName} /> */}
                            <div className='col-xl-12 col-lg-12 align-self-center' style={{ boxShadow: '7px 7px 12px 0px rgba(177,177,177,0.87)' }}>
                                <div className='m-3'>
                                    <h5 style={{ color: '#009138' }}>{record?.scientificName ? record?.scientificName : 'My recording'}</h5>
                                    <AudioPlayerComponent recordId={record?.id as string} autoPlay={false} />
                                </div>
                            </div>
                            {/* {displayMode === DisplayModeEnum.ADVANCED && (
                            <div>
                                <hr className='stepper-divider-confirmed'></hr>
                                <AudioPlayerComponent recordId={record.id as string} autoPlay={false} />
                            </div>
                        )} */}
                        </div>
                    </div>
                ) : (
                    <>
                        <div className='col-12 pe-4 ps-4'>
                            <div
                                className=' d-flex col pe-4 ps-4 mb-4 py-3'
                                //style={{ boxShadow: displayMode === DisplayModeEnum.SIMPLIFIED ? 'none' : '7px 7px 12px 0px rgba(177,177,177,0.87)' }}
                            >
                                {/* <ImageSectionComponent displayMode={displayMode} scientificName={record.scientificName} /> */}
                                <div className='col-12 align-self-center' style={{ boxShadow: '7px 7px 12px 0px rgba(177,177,177,0.87)' }}>
                                    <div className='m-3'>
                                        <h5 style={{ color: '#009138' }}>{record?.scientificName ? record?.scientificName : 'My recording'}</h5>
                                        <AudioPlayerComponent recordId={record?.id as string} autoPlay={false} />
                                    </div>
                                </div>
                                {/* {displayMode === DisplayModeEnum.ADVANCED && (
                        <div>
                            <hr className='stepper-divider-confirmed'></hr>
                            <AudioPlayerComponent recordId={record.id as string} autoPlay={false} />
                        </div>
                    )} */}
                            </div>
                        </div>

                        {/* <div className='col-12 pe-4 ps-4'>
                                <div className='col p-5 mb-4' style={{ boxShadow: '7px 7px 12px 0px rgba(177,177,177,0.87)' }}>
                                    <ImageSectionComponent displayMode={DisplayModeEnum.ADVANCED} scientificName={record?.scientificName ?? 'Not defined'} />
                                </div>
                            </div> */}
                    </>
                )}

                <div className='col-12 pe-4 ps-4'>
                    <div className='accordionContainer'>
                        <DataSectionComponent record={record} isAnimalDataEditable={true} isEditEnabled={isCustomData ? true : false} onFormValid={validateCustomData} />
                    </div>
                </div>

                <div className='col-12 pe-4 ps-4 mb-3'>
                    <div className='col'>
                        <Accordion identifier='wizard.technical.details' id='technicalDetails'>
                            <div className='row'>
                                <div className='col-md-6' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <h6 style={{ color: '#009138' }}>Audio Info</h6>
                                    <Table columns={catalogueDetailTableColumns} rows={audioDataTableRows ?? []} keyLabel='soundData' />
                                </div>
                                <div className='col-md-6' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <h6 style={{ color: '#009138' }}>Amplitude</h6>
                                    <Table columns={catalogueDetailTableColumns} rows={amplitudeDataTableRows ?? []} keyLabel='soundData' />
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </div>
                <div className='col-12 pe-4 ps-4 mb-3'>
                    <div className='col'>
                        <Accordion identifier='wizard.audio.analytics' id='audioAnalytics'>
                            <AudioAnalyticsComponent identifier={'audio.analytics'} record={record} />
                        </Accordion>
                    </div>
                </div>
            </>

            <div className='col d-flex justify-content-end mb-4 mt-5'>
                {/* <Button identifier='wizard.general.data.skip' variant='primary' onClick={() => store.dispatch(setCurrentStep(WizardStepsEnum.METADATA))} /> */}
                <Button identifier='wizard.general.data.next' variant='primary' disabled={!isFormValid} onClick={handleNextClick} />
            </div>
        </div>
    );
};
